// @ts-strict-ignore
import {
  Injectable,
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  DomSanitizer
} from '@angular/platform-browser'

@Pipe({
  name: 'safeHtml',
})
@Injectable()
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
