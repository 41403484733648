// @ts-strict-ignore
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'create-company-plan-dialog',
  templateUrl: './create-company-plan.component.html',
})
export class CreateCompanyPlanDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  public companies: any;
  public userGroups;
  public currentCompanyName;
  public currentUserGroup;
  private selectedCompanies: any = [];
  private selectedGroups: any = [];

  constructor(
    public dialogRef: MatDialogRef<CreateCompanyPlanDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!!this.data && !!this.data.data) {
      this.companies = this.data.data.companies;
      this.userGroups = this.data.data.userGroups;
    }
    // (window.document.querySelector(
    //   '.insig-dialog-panel'
    // ) as HTMLElement).style.minWidth = '40%';
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      planTitle: this.formBuilder.control(null, [Validators.required]),
      companiesSubscribed: this.formBuilder.control(null),
      userGroupsSubscribed: this.formBuilder.control(null),
    });
  }

  addCompanyPlan() {
    if (!this.form.valid) {
      this.snackBar.open('Please complete all fields!', null, {
        duration: 3000,
      });
      return false;
    }
    const data = {
      title: this.form.controls['planTitle'].value,
      coverPlan: true,
    };
    console.log(data);
    this.dialogRef.close(data);
  }

  addItem(val) {
    if (!this.selectedCompanies.includes(val)) {
      this.selectedCompanies.push(val);
    }
    this.currentCompanyName = '';
  }

  removeItem(val) {
    const index = this.selectedCompanies.indexOf(val);
    this.selectedCompanies.splice(index, 1);
  }

  addUserGroupItem(val) {
    if (!this.selectedGroups.includes(val)) {
      this.selectedGroups.push(val);
    }
    this.currentUserGroup = '';
  }

  removeUserGroupItem(val) {
    const index = this.selectedGroups.indexOf(val);
    this.selectedGroups.splice(index, 1);
  }
}
