import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'youtubeEmbedSecurityPipe',
})
@Injectable()
export class YoutubeEmbedPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string): SafeResourceUrl | null {
    if (url.indexOf('https://www.youtube.com/') === 0) {
      if (!(url.indexOf('watch?v=') === -1)) {
        url = url.replace('watch?v=', 'embed/');
      }
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return null;
    }
  }
}
