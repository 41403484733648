// @ts-strict-ignore
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'link-question',
  templateUrl: './link.component.html',
  styleUrls: [
    './link.component.scss'
  ]
})
export class LinkComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  constructor(){}

  ngOnInit(){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  openLink(){
    window.open(this.question.link, "_blank")
  }
} // end component
