<div id="topOfLaunch" class="h-100 overflow-y-auto backgroundBlue">
  <span *ngIf="!surveyError && !questionnaireAlreadyCompleted">
    <div *ngIf="!schedulerLogin && !preview && !quickView">
      <div class="top-nav-left pl-2 pb-2 pt-3 ">
        <span *ngIf="tabletMode == 'true' && companyData?.id">
          <button
            [routerLink]="['/surveys/tablet-mode', companyID]"
            mat-button
            class="pl-0 mt-2 btn-w-md"
          >
            <mat-icon class="material-icons">keyboard_arrow_left</mat-icon>
            Return to Surveys
          </button>
          <img
            class="mr-4 ml-1 m-1 float-right"
            src="{{
              companyData?.branding
                ? companyData.branding
                : 'assets/images/global/logofullnormal.svg'
            }}"
            style="max-height:50px;max-width:250px;height:50px;"
          />
          <!-- <h4 class="d-initial mx-3 my-3 float-right">
            {{ companyData?.brandingName }}
          </h4> -->
        </span>
        <span *ngIf="tabletMode != 'true' && companyData?.id">
          <img
            class="mr-3 ml-1 mb-2"
            src="{{
              companyData?.branding
                ? companyData.branding
                : 'assets/images/global/logofullnormal.svg'
            }}"
            style="max-height:50px;max-width:250px;height:50px;"
          />
          <!-- <h4 class="d-initial my-3">{{ companyData?.brandingName }}</h4> -->
        </span>
      </div>

      <div class="top-nav-right" id="googleTranslateHeader">
        <div id="google_translate_element_main"></div>
      </div>
      <div class="top-nav-right mt-2" *ngIf="!preview">
        <li class="list-inline-item">
          <button
            mat-button
            class="mat-button header-btn"
            [insMenuTriggerFor]="flagMenu"
          >
            <div class="notranslate">
              <!-- <img class="flag" [src]="flag"> -->
              Language
            </div>
          </button>
          <mat-menu
            #flagMenu="matMenu"
            x-position="after"
            y-position="below"
            overlapTrigger="false"
          >
            <button
              mat-menu-item
              *ngFor="let language of languageList"
              (click)="switchLanguage(language)"
            >
              <div class="notranslate">
                <!-- <img class="flag" src="assets/images/language-flags/{{language}}.png"> -->
                <span>&nbsp;{{ language }}</span>
              </div>
            </button>
          </mat-menu>
        </li>
      </div>
    </div>

    <div class="w-100 my-2 text-center h4">
      Your healthcare provider needs more information to help you better. Please complete your medical questionnaire.
    </div>

    <patient-login
      *ngIf="
        paramsLoaded && !quickView && !bypassPatientInfoForm && !formSubmitted && !schedulerLogin
      "
      [(formSubmitted)]="formSubmitted"
      [(tabletMode)]="tabletMode"
      [(surveyLoaded)]="surveyLoaded"
      [(preview)]="preview"
      [patientInfo]="patientInfo"
      (patientInfoChange)="handlePatientInfoChanged($event)"
    ></patient-login>

    <section class="container-fluid with-maxwidth chapter">
      <article class="article" style="padding-bottom:0;">
        <survey-pages
          *ngIf="
            surveyLoaded && ((formSubmitted && !surveySubmitted) || quickView || (bypassPatientInfoForm && !surveySubmitted))
          "
          [(currentPage)]="currentPage"
          (updateCurrentPage)="updateCurrentPage($event)"
          [doctorCompleting]="false"
          [familyId]="familyId"
          [surveyError]="surveyError"
          (submitSurvey)="submitSurvey($event)"
          [patientInfo]="patientInfo"
          [(quickView)]="quickView"
          [(schedulerLogin)]="schedulerLogin"
          [(preview)]="preview"
          [(showAds)]="showAds"
          [(survey)]="survey"
          [(tabletMode)]="tabletMode"
          [companyID]="companyID"
          [(quickPreview)]="quickView"
          [(uploadedPDFIDs)]="uploadedPDFIDs"
        >
        </survey-pages>

        <div *ngIf="surveySubmitted">
          <div *ngIf="!submittingSurvey && !errorSubmitting">
            <h2 class="article-title pl-3 pt-4 mb-4">Thank You!</h2>
            <div class="box box-default mb-0">
              <div class="box-body">
                <h5 class="my-2">
                  The results have been sent to your healthcare provider.
                </h5>
                <div *ngIf="showNavigationButton()" class="d-flex justify-content-center">
                  <button
                    mat-raised-button
                    (click)="navigateToDashboardPage()"
                  >
                    Go to your portal
                  </button>
                </div>
                <div *ngIf="tabletMode == 'true'">
                  <div class="divider"></div>
                  <div class="divider"></div>
                  <h5>Please return the tablet to the staff.</h5>
                </div>
              </div>
            </div>
            <div *ngIf="survey.print" class="text-center">
              <div class="divider"></div>
              <div class="divider"></div>
              <button mat-raised-button (click)="printResults()">
                Print Results
              </button>
            </div>

            <!-- start ad -->
            <!-- <div *ngIf="!preview && showAds">
              <div class="box box-default mb-5">
                <div class="box-body">
                  <div class="text-center">
                    <img
                      style="width:100%;"
                      src="assets/images/aerobiology-app.png"
                      (click)="
                        trackAdClick(
                          'Aerobiology-App',
                          'https://www.pollenexperts.ca/app/'
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <!-- end ad -->

          </div>

          <div *ngIf="!submittingSurvey && errorSubmitting">
            <h2 class="article-title pl-3">Error!</h2>
            <div class="box box-default">
              <div class="box-body">
                <h5 class="my-2">
                  There has been an error submitting the results to your
                  healthcare provider.
                </h5>
                <div *ngIf="tabletMode == 'true'">
                  <div class="divider"></div>
                  <div class="divider"></div>
                  <h5>Please inform your healthcare provider of this error.</h5>
                </div>
                <div class="divider"></div>
                <div>
                  <button
                    mat-raised-button
                    [disabled]="resubmitOnCooldown"
                    (click)="resubmitSurvey()"
                  >
                    Resubmit
                  </button>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </div>
          <div *ngIf="submittingSurvey">
            <insig-app-loader class="mx-auto"></insig-app-loader>
            <h2 class="article-title pl-3">Please wait!</h2>
            <div class="box box-default">
              <div class="box-body text-center">
                <h5 class="my-2">
                  The results are being sent to your healthcare provider.
                </h5>
                <h5>Please do not leave this page!</h5>
                <div class="divider"></div>
                <div>
                  <button
                    mat-raised-button
                    [disabled]="resubmitOnCooldown"
                    (click)="resubmitSurvey()"
                  >
                    Resubmit
                  </button>
                </div>
                <div class="divider"></div>
              </div>
            </div>
          </div>

          <!-- <div *ngIf="!preview && !schedulerLogin && tabletMode != 'true'">
            <hr class="m-0">
            <div class="box box-default mb-3">
              <div class="box-body">
                <h5 class="mt-2">MANAGE YOUR HEALTH, WITHOUT THE HEADACHES</h5>
                <h6>Design your care plan, track progress, monitor results, get feedback & keep everyone in the loop. <a class="d-inline" href="https://go.careclinic.io/tia">Click here to try CareClinic for Free.</a></h6>
                <div class="mt-4">
                  <div class="mx-auto w-50">
                    <a href="https://go.careclinic.io/tia">
                      <img class="w-100" src="assets/images/global/cc-logo.png" />
                    </a>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-6">
                    <img class="w-100" src="assets/images/global/cc-1.png" />
                  </div>
                  <div class="col-md-6">
                    <img class="w-100" src="assets/images/global/cc-2.png" />
                  </div>
                </div>

              </div>
            </div>
          </div> -->

          <!-- <div *ngIf="!preview && !schedulerLogin && showAds && tabletMode != 'true'">
            <hr class="m-0">
            <ads></ads>
          </div> -->
        </div>
      </article>
    </section>
  </span>

  <span *ngIf="questionnaireAlreadyCompleted">
    <h4 class="text-center mt-5 px-4">Intake Form Already Completed</h4>
    <h5 class="text-center px-4">
      Please note this intake form has already been completed.
      <br /><br />
      To edit your responses, please login at
      <a class="d-inline" href="/auth/login">{{ baseURL }}</a>
      and select your appointment on the 'Appointments' tab.
    </h5>
  </span>
  <span *ngIf="surveyError">
    <h5 class="text-center mt-5 px-4">Error loading questionnaire!</h5>
    <h5 class="text-center px-4">
      The questionnaire could not be found, it may have been deleted.
    </h5>
  </span>
</div>
