import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'signature-terms-dialog',
  templateUrl: './signature-terms-dialog.component.html',
})
export class SignatureTermsDialogComponent {
  termsContent = null;

  constructor(public dialogRef: MatDialogRef<SignatureTermsDialogComponent>) {}
}
