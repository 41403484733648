// @ts-strict-ignore
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { LayoutService } from 'insig-app/layout/layout.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { APPCONFIG } from '@insig-health/config/config';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { UserManagerService } from 'insig-app/services/userManager.service';
import { ThemeService } from 'insig-app/services/theme/theme.service';

@Component({
  selector: 'theme-customizer',
  templateUrl: './theme-customizer.component.html',
  styleUrls: ['./theme-customizer.component.scss'],
  providers: [LayoutService, UserManagerService, ThemeService],
})
export class ThemeCustomizerComponent implements OnInit, OnDestroy {
  // graphql queries
  private userDataQuery = gql`
    query User($userID: ID!) {
      getUserData(uid: $userID) {
        uid
        company
      }
    }
  `;

  private companyDataQuery = gql`
    query CompanyData($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        AppConfig {
          colorOption
          theme
        }
        ThemeConfig {
          primary
          secondary
          text
          textSecondary
          textSelected
        }
      }
    }
  `;

  private companyDataMutation = gql`
    mutation CompanyDataMutation(
      $companyID: ID!
      $token: ID!
      $data: CompanyInput!
    ) {
      setCompanyData(cid: $companyID, token: $token, data: $data) {
        AppConfig {
          colorOption
          theme
        }
        ThemeConfig {
          primary
          secondary
          text
          textSecondary
          textSelected
        }
      }
    }
  `;

  //

  // endpoints
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private IDToken = null;
  private unsubscribe = null;
  public AppConfig: any;
  private companyID = null;

  public primaryColor: string;
  public fontColor: string;
  public secondaryColor: string;
  public fontSecondary: string;
  public fontSelected: string;

  constructor(
    private layoutService: LayoutService,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private firebaseAuthService: FirebaseAuthService,
    private userManagerService: UserManagerService,
    private themeService: ThemeService,
    private apollo: Apollo
  ) {
    this.primaryColor = '#009fdf';
    this.fontColor = '#000105';
    this.secondaryColor = '#FFFFFF';
    this.fontSecondary = '#a1a1a1';
    this.fontSelected = '#fff';
  }

  ngOnInit() {
    this.AppConfig = APPCONFIG;
    console.log(this.AppConfig);
    this.unsubscribe = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          this.IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();

          const userDataQuery: any = await this.apollo
            .query({
              query: this.userDataQuery,
              variables: {
                userID: user.uid,
              },
            })
            .toPromise();
          const userData = userDataQuery.data.getUserData;
          if (userData) {
            this.companyID = userData.company;
            this.getCompanyData(this.companyID);
          }
        }
      });
  }

  async getCompanyData(companyID) {
    try {
      const companyDataQuery: any = await this.apollo
        .query({
          query: this.companyDataQuery,
          variables: {
            companyID,
            token: await this.firebaseAuthService.getIdToken(),
          },
        })
        .toPromise();
      const companyData = companyDataQuery.data.getCompanyData;
      console.log(companyData);
      if (!companyData) {
        return false;
      }
      // branding name
      if (companyData.ThemeConfig) {
        this.primaryColor = companyData.ThemeConfig.primary;
        this.fontColor = companyData.ThemeConfig.text;
        this.secondaryColor = companyData.ThemeConfig.secondary;
        this.fontSecondary = companyData.ThemeConfig.textSecondary;
        this.fontSelected = companyData.ThemeConfig.textSelected;
      }
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }

  onLayoutChange = () => {
    this.layoutService.updateEChartsState(true);
  };

  async saveCompanyData(): Promise<void> {
    console.log('Saving App Config');
    if (!this.companyID) {
      console.log('CID not loaded');
      return;
    }
    const newData = {
      ThemeConfig: {
        primary: this.primaryColor,
        secondary: this.secondaryColor,
        text: this.fontColor,
        textSecondary: this.fontSecondary,
        textSelected: this.fontSelected,
      },
    };
    console.log(newData);

    try {
      const mutateCompanyData: any = await this.apollo
        .mutate({
          mutation: this.companyDataMutation,
          variables: {
            companyID: this.companyID,
            token: this.IDToken,
            data: newData,
          },
        })
        .toPromise();
      const companyData = mutateCompanyData.data.setCompanyData;
      console.log(companyData);
      if (companyData.ThemeConfig) {
        this.primaryColor = companyData.ThemeConfig.primary;
        this.secondaryColor = companyData.ThemeConfig.secondary;
        this.fontColor = companyData.ThemeConfig.text;
        this.fontSecondary = companyData.ThemeConfig.textSecondary;
        this.fontSelected = companyData.ThemeConfig.textSelected;
      }
      this.snackBar.open('App Configuration Saved!', null, { duration: 2000 });
    } catch (error) {
      console.log(error);
      this.snackBar.open('Error saving App Configuration!', null, {
        duration: 2000,
      });
    }
  }

  /**
   * Set color from color picker
   * @param {string} type
   * @param {string} color
   */
  public setColor(type: string, color: string) {
    switch (type) {
      case 'primary':
        this.primaryColor = color;
        break;
      case 'font':
        this.fontColor = color;
        break;
      case 'secondary':
        this.secondaryColor = color;
        break;
      case 'fontSecondary':
        this.fontSecondary = color;
        break;
      case 'fontSelected':
        this.fontSelected = color;
        break;
      default:
        break;
    }

    console.log('setting theme', {
      'primary-color': this.primaryColor,
      'secondary-color': this.secondaryColor,
      'text-color': this.fontColor,
    });

    this.themeService.setTheme({
      'primary-color': this.primaryColor,
      'secondary-color': this.secondaryColor,
      'text-color': this.fontColor,
      'text-selected': this.fontSelected,
      'text-secondary': this.fontSecondary,
    });
  }
}
