// @ts-strict-ignore
import type { Page } from 'insig-types/surveys/page';
import { SpringNoteLargeStringKey } from 'insig-types/spring-api/notes';
import type { LargeStringQuerySignature, NoteIdentifiers, PartialNoteToggleableLargeStrings, PickedLargeStrings } from './notes.service.types';

import firebase from 'firebase/compat/app';
import 'firebase/firestore';

export async function getAllPartialNoteLargeStrings<
  ToggledLargeStrings extends PartialNoteToggleableLargeStrings
>(
  identifiers: NoteIdentifiers,
  toggledLargeStrings: ToggledLargeStrings,
  getLargeStringFunction: LargeStringQuerySignature,
): Promise<PickedLargeStrings<ToggledLargeStrings>> {

  const pickedLargeStringKeys = sanitizeLargeStrings(toggledLargeStrings);

  const largeStringPromises = pickedLargeStringKeys.map(async (largeStringKey) => {
    if (largeStringKey === 'pages') {
      // Pages should be parsed as a JSON so we handle it separately
      const pages = await getPartialNotePages(identifiers, getLargeStringFunction);
      return { pages };
    } else {
      const largeString = await getPartialNoteLargeString(identifiers, largeStringKey, getLargeStringFunction);
      return { [largeStringKey]: largeString };
    }
  });

  const largeStringArray = await Promise.all(largeStringPromises);
  const largeStrings = Object.assign({}, ...largeStringArray) as PickedLargeStrings<ToggledLargeStrings>;

  return largeStrings;
}

export function sanitizeLargeStrings(toggledLargeStrings: PartialNoteToggleableLargeStrings): string[] {
  return Object.entries(toggledLargeStrings)
    .filter(filterEntriesByValue(true))
    .map(mapEntriesToKeys)
    .filter(filterByAllowedLargeStringKeys);
}

export function filterEntriesByValue(filterValue: any): ([key, value]: [string, any]) => boolean {
  return ([_key, value]: [string, any]) => value === filterValue;
}

export function mapEntriesToKeys([key, _value]: [string, any]): string {
  return key;
}

export function filterByAllowedLargeStringKeys(key: string): boolean {
  const allowedLargeStringKeys = getAllowedLargeStringKeys();
  return allowedLargeStringKeys.includes(key);
}

export function getAllowedLargeStringKeys(): string[] {
  return Object.values(SpringNoteLargeStringKey);
}

export async function getPartialNotePages(
  identifiers: NoteIdentifiers,
  getLargeStringFunction: LargeStringQuerySignature,
): Promise<Page[]> {
  try {
    return JSON.parse(await getLargeStringFunction(identifiers, SpringNoteLargeStringKey.PAGES));
  } catch (error) {
    if (error.status === 404) {
      return []; // default to an empty JSON array
    }
    // Unhandled errors are reported and thrown
    console.error(error);
    throw error;
  }
}

export async function getPartialNoteLargeString(
  identifiers: NoteIdentifiers,
  largeStringKey: string,
  getLargeStringFunction: LargeStringQuerySignature,
): Promise<string> {
  try {
    return await getLargeStringFunction(identifiers, largeStringKey);
  } catch (error) {
    // If the document does not exist, handle it gracefully
    if (error.status === 404) {
      return '';
    }
    // Unhandled errors are reported and thrown
    console.error(error);
    throw error;
  }
}
