// @ts-strict-ignore
import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';

import { MenuPositionX, MenuPositionY } from './insig-menu.position';

@Component({
  selector: 'ins-menu',
  templateUrl: 'insig-menu.component.html',
  styleUrls: ['../insig-material.shared.scss'],
  exportAs: 'insMenu',
})
export class InsigMenuComponent {
  @Input() insMenu: CdkOverlayOrigin;

  @Input('class')
  set panelClass(classes: string) {
    if (classes && classes.length) {
      this._classList = classes
        .split(' ')
        .reduce((obj: any, className: string) => {
          obj[className] = true;
          return obj;
        }, {});

      this._elementRef.nativeElement.className = '';
      this.setPositionClasses();
    }
  }

  private _xPosition: MenuPositionX = 'before';
  private _yPosition: MenuPositionY = 'below';
  private _overlapTrigger = false;
  _classList: { [key: string]: boolean } = {};

  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  constructor(private _elementRef: ElementRef) {}

  @Input()
  get overlapTrigger(): boolean {
    return this._overlapTrigger;
  }
  set overlapTrigger(value: boolean) {
    this._overlapTrigger = coerceBooleanProperty(value);
  }

  /** Position of the menu in the X axis. */
  @Input()
  get xPosition(): MenuPositionX {
    return this._xPosition;
  }
  set xPosition(value: MenuPositionX) {
    this._xPosition = value;
    this.setPositionClasses();
  }

  /** Position of the menu in the Y axis. */
  @Input()
  get yPosition(): MenuPositionY {
    return this._yPosition;
  }
  set yPosition(value: MenuPositionY) {
    this._yPosition = value;
    this.setPositionClasses();
  }

  setPositionClasses(
    posX: MenuPositionX = this.xPosition,
    posY: MenuPositionY = this.yPosition,
  ) {
    this._classList['ins-menu-before'] = posX === 'before';
    this._classList['ins-menu-after'] = posX === 'after';
    this._classList['ins-menu-above'] = posY === 'above';
    this._classList['ins-menu-below'] = posY === 'below';
  }
}
