// @ts-strict-ignore
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { MatDialog } from '@angular/material/dialog';
import { SignatureTermsDialogComponent } from '../../../dialogs/signature-terms-dialog.component';
import { SignatureDialogComponent } from './signature-dialog.component';

import { Question } from 'insig-types/surveys';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'signature-question',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.style.css'],
})
export class SignatureComponent implements OnInit, OnChanges, OnDestroy {
  // graphql queries
  private userDataQuery = gql`
    query UserDataQuery($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        signature
      }
    }
  `;
  //

  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() doctorCompleting = false;
  @ViewChild('container') public container: ElementRef;
  public savedSignature = null;
  public maxWidth = 500;
  public unsubscribe = null;
  public noSavedSignatureMessage = null;

  // endpoints

  constructor(
    public dialog: MatDialog,
    public firebaseAuthService: FirebaseAuthService,
    private apollo: Apollo
  ) {}

  ngOnInit() {
    if (this.container) {
      this.maxWidth = this.container.nativeElement.offsetWidth;
    }

    // load doctors saved signature
    if (this.doctorCompleting) {
      this.unsubscribe = this.firebaseAuthService
        .onIdTokenChanged()
        .subscribe(async (user) => {
          if (user) {
            try {
              const userDataQuery: any = await this.apollo
                .query({
                  query: this.userDataQuery,
                  variables: {
                    userID: user.uid,
                    token: await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken(),
                  },
                })
                .toPromise();
              const userData = userDataQuery.data.getUserData;
              if (userData && userData.signature) {
                this.savedSignature = userData.signature;
                if (!this.question.response.answer) {
                  this.question.response.answer = userData.signature;
                }
              }
            } catch (error) {
              console.log(error);
            }
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  }

  startSignature() {
    const dialogRef = this.dialog.open(SignatureDialogComponent);
    dialogRef.componentInstance.question = this.question;
    dialogRef
      .afterClosed()
      .toPromise()
      .then((_result) => {
        this.question.response.answer = this.question.canvas.toDataURL();
        this.question.canvas = null;
      });
  } // end func

  addSavedSignature() {
    if (this.savedSignature) {
      this.question.response.answer = this.savedSignature;
    } else {
      this.noSavedSignatureMessage =
        'You do not currently have a saved signature. Visit the \'Profile\' tab to create one.';
    }
  }

  openTerms() {
    const dialogRef = this.dialog.open(SignatureTermsDialogComponent);
    dialogRef.componentInstance.termsContent = this.question.termsContent;
    dialogRef.afterClosed().subscribe((result) => {
      this.question.response.responseTerms = !!result;
    });
  } // end terms func
} // end component
