// @ts-strict-ignore
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { DoctorSettingsService, ExceptionSchedule, TimeIncrement, TimeSegment } from 'insig-app/services/doctorSettings.service';
import { MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from 'insig-app/services/doctorSettings.service.utilities';

import { TimeSegmentDurationWarningDialogComponent } from './time-segment-duration-warning-dialog/time-segment-duration-warning-dialog.component';

@Component({
  selector: 'daily-schedule',
  templateUrl: 'daily-schedule.component.html',
  styleUrls: ['./daily-schedule.component.scss'],
})
export class DailyScheduleComponent implements OnInit {
  @Input() schedule: ExceptionSchedule;
  @Input() selectedWeekday: any;
  @Input() exceptionDate: any;
  @Input() locations: any[];
  @Input() defaultAvailability: 'virtual' | 'clinic' | 'both';
  @Output() scheduleChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() backClicked: EventEmitter<any> = new EventEmitter<any>();

  public overlappingSegments: number[] = [];

  readonly MINIMUM_START_TIME = 0;
  readonly MAXIMUM_END_TIME = 23 * MILLISECONDS_PER_HOUR + 59 * MILLISECONDS_PER_MINUTE;

  readonly DEFAULT_START_TIME = 9 * MILLISECONDS_PER_HOUR;
  readonly DEFAULT_END_TIME = 10 * MILLISECONDS_PER_HOUR;

  readonly LONG_TIME_SEGMENT_WARNING_DURATION = 10 * MILLISECONDS_PER_HOUR + 1;
  readonly SHORT_TIME_SEGMENT_WARNING_DURATION = 1 * MILLISECONDS_PER_HOUR;

  constructor(
    public doctorSettingsService: DoctorSettingsService,
    public dateAndTimeService: DateAndTimeService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    if (!this.schedule.timeSegments) {
      this.schedule.timeSegments = [];
    }
    if (!this.schedule.timeIncrement || parseInt(this.schedule.timeIncrement, 10) < 4) {
      this.schedule.timeIncrement = '4';
      this.scheduleChange.emit(this.schedule);
    }
    if (!this.schedule.operatingTimeZone) {
      this.schedule.operatingTimeZone = this.dateAndTimeService.getLocalTimeZone();
    }
    this.overlappingSegments = this.checkOverlap();
  }

  getIanaTimeZones(): string[] {
    return this.dateAndTimeService.getTimeZoneNames();
  }

  backToCalendar(event) {
    this.backClicked.emit(event);
  }

  toggleException(event) {
    this.schedule.exception = event;
    this.scheduleChange.emit(this.schedule);
  }

  changeTimeIncrement(selectedTimeIncrement: TimeIncrement): void {
    this.schedule.timeIncrement = selectedTimeIncrement;
    // Emit the new schedule object back to the output
    this.scheduleChange.emit(this.schedule);
  }

  generateTimeSlots(
    operatingStartTime = this.MINIMUM_START_TIME,
    operatingEndTime = this.MAXIMUM_END_TIME,
    timeIncrement: TimeIncrement,
  ): number[] {
    const timeIncrementMs = parseInt(timeIncrement, 10) * MILLISECONDS_PER_MINUTE;
    let currentTime = operatingStartTime;
    const timeSlots: number[] = [];
    while (currentTime < operatingEndTime) {
      timeSlots.push(currentTime);
      currentTime += timeIncrementMs;
    }
    return timeSlots;
  }

  filterOut(array: string[], value: string): string[] {
    return array.filter((arrayVal) => arrayVal !== value);
  }

  addTimeSegment() {
    // Enable exceptions if a time segment is added
    if (!this.selectedWeekday && !this.schedule.exception) {
      this.schedule.exception = true;
    }

    if (!this.schedule.timeSegments) {
      this.schedule.timeSegments = [];
    }

    const defaultTimeSegment: TimeSegment = {
      operatingStartTime: this.DEFAULT_START_TIME,
      operatingEndTime: this.DEFAULT_END_TIME,
      availability: 'default',
      location: this.locations?.[0]?.id ?? null,
    }
    this.schedule.timeSegments.push(defaultTimeSegment);
    console.log(this.schedule);
    this.scheduleChange.emit(this.schedule);
    this.overlappingSegments = this.checkOverlap();
  }

  removeTimeSegment(index: number) {
    this.schedule.timeSegments.splice(index, 1);
    this.scheduleChange.emit(this.schedule);
    this.overlappingSegments = this.checkOverlap();
  }

  checkOverlap(): number[] {
    // Returns an array of segment indices that have overlapping time periods
    // We have to compare each time segment with each other time segment
    const segments = this.schedule.timeSegments;
    if (!segments) {
      return [];
    }
    const overlappingSegments = [];
    for (let indexA = 0; indexA < segments.length - 1; indexA++) {
      for (let indexB = indexA + 1; indexB < segments.length; indexB++) {
        if (
          overlappingSegments.indexOf(indexA) !== -1 &&
          overlappingSegments.indexOf(indexB) !== -1
        ) {
          // We already know these two periods are overlapping so we do not have to check them
          continue;
        }
        let isOverlap = false;
        const segmentA = segments[indexA];
        const segmentB = segments[indexB];
        if (segmentA.operatingStartTime === segmentB.operatingStartTime) {
          isOverlap = true;
        } else if (
          this.minutesBetween(segmentA.operatingStartTime, segmentB.operatingStartTime) > 0
        ) {
          // segmentA starts earlier
          // there is overlap if segmentA ends after segmentB starts
          isOverlap = this.minutesBetween(segmentA.operatingEndTime, segmentB.operatingStartTime) < 0;
        } else {
          // segmentB starts earlier
          // there is overlap if segmentB ends after segmentA starts
          isOverlap = this.minutesBetween(segmentB.operatingEndTime, segmentA.operatingStartTime) < 0;
        }

        if (isOverlap) {
          // Add the indices to the array if they are not already in there
          if (overlappingSegments.indexOf(indexA) === -1) {
            overlappingSegments.push(indexA);
          }
          if (overlappingSegments.indexOf(indexB) === -1) {
            overlappingSegments.push(indexB);
          }
        }
      }
    }
    return overlappingSegments;
  }

  minutesBetween(msPastMidnightA: number, msPastMidnightB: number): number {
    // Returns the number of minutes from timeA to timeB, negative if timeB is earlier than timeA
    return (msPastMidnightB - msPastMidnightA) / MILLISECONDS_PER_MINUTE;
  }

  async changeSegmentStartTime(segment: TimeSegment, changeEvent: MatSelectChange): Promise<void> {
    const matSelect = changeEvent.source;
    const newOperatingStartTime = changeEvent.value;
    
    const isChangeConfirmed = await this.isTimeSegmentOperatingTimeChangeConfirmed(newOperatingStartTime, segment.operatingEndTime);
    if (!isChangeConfirmed) {
      matSelect.value = segment.operatingStartTime;
      return;
    }

    segment.operatingStartTime = newOperatingStartTime;
    this.scheduleChange.emit(this.schedule);
  }

  async changeSegmentEndTime(segment: TimeSegment, changeEvent: MatSelectChange): Promise<void> {
    const matSelect = changeEvent.source;
    const newOperatingEndTime = changeEvent.value;

    const isChangeConfirmed = await this.isTimeSegmentOperatingTimeChangeConfirmed(segment.operatingStartTime, newOperatingEndTime);
    if (!isChangeConfirmed) {
      matSelect.value = segment.operatingEndTime;
      return;
    }

    segment.operatingEndTime = newOperatingEndTime;
    this.scheduleChange.emit(this.schedule);
  }

  changeSegmentAvailability(segment: any, newAvailability: string) {
    segment.availability = newAvailability;
    if (newAvailability === 'virtual') {
      segment.location = 'virtual';
    } else if (segment.location === 'virtual') {
      segment.location =
        this.locations && this.locations[0] ? this.locations[0].id : null;
    }
    this.scheduleChange.emit(this.schedule);
  }

  changeSegmentLocation(segment: any, newLocationId: string) {
    segment.location = newLocationId;
    this.scheduleChange.emit(this.schedule);
  }

  isTimeMissingFromGeneratedTimeSlots(timeToCheck: number, startTime: number, endTime: number, timeIncrement: TimeIncrement): boolean {
    const generatedTimeSlots = this.generateTimeSlots(startTime, endTime, timeIncrement);
    return generatedTimeSlots.indexOf(timeToCheck) === -1;
  }

  async isTimeSegmentOperatingTimeChangeConfirmed(newOperatingStartTime: number, newOperatingEndTime: number): Promise<boolean> {
    const newSegmentDuration = newOperatingEndTime - newOperatingStartTime;
    if (this.isTimeSegmentDurationInWarningRange(newSegmentDuration)) {
      const isChangeConfirmed = await this.dialog.open(TimeSegmentDurationWarningDialogComponent, { data: {
        operatingStartTime: newOperatingStartTime,
        operatingEndTime: newOperatingEndTime,
      }}).afterClosed().toPromise();
      return !!isChangeConfirmed;
    } else {
      return true;
    }
  }

  isTimeSegmentDurationInWarningRange(duration: number): boolean {
    const isTooLong = duration >= this.LONG_TIME_SEGMENT_WARNING_DURATION;
    const isTooShort = duration <= this.SHORT_TIME_SEGMENT_WARNING_DURATION;
    return isTooLong || isTooShort;
  }
}
