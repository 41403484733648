// @ts-strict-ignore
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { UserManagementService } from '@insig-health/services/user-management/user-management.service';
import { firstValueFrom } from 'rxjs';

enum EmailUpdateState {
  LOADING,
  LOGIN,
  ENTER_NEW_EMAIL,
}

@Component({
  selector: 'insig-health-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
})
export class ChangeEmailDialogComponent {
  public readonly NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME = 'newEmailAddress';
  public readonly CONFIRM_NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME = 'confirmNewEmailAddress';
  public readonly EmailUpdateState = EmailUpdateState;
  public emailUpdateState = EmailUpdateState.LOGIN;
  public email = this.gcpIpAuthService.getCurrentUser()?.email ?? undefined;

  public static readonly DEFAULT_ERROR_SNACKBAR_STRING = 'An error occurred while updating the email';

  public readonly changeEmailForm = this.formBuilder.group({
    [this.NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME]: ['', [Validators.required, Validators.email]],
    [this.CONFIRM_NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME]: ['', [Validators.required, Validators.email]],
  }, { validators: [this.formValidatorsService.controlValuesMatchingValidatorFactory(this.NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME, this.CONFIRM_NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME)] });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: string },
    private dialogRef: MatDialogRef<ChangeEmailDialogComponent>,
    private formBuilder: FormBuilder,
    private formValidatorsService: FormValidatorsService,
    private userManagementService: UserManagementService,
    private snackBar: MatSnackBar,
    private gcpIpAuthService: GcpIpAuthService,
  ) {
    firstValueFrom(this.dialogRef.beforeClosed()).then(async () => {
      const customToken = await this.gcpIpAuthService.getFirebaseCustomToken();
      await this.gcpIpAuthService.signInWithCustomToken(customToken);
    });
  }

  handleLoginSuccess(): void {
    this.emailUpdateState = EmailUpdateState.ENTER_NEW_EMAIL;
  }

  async handleSaveButtonClick(): Promise<void> {
    try {
      const response = await this.userManagementService.updateEmail(this.data.userId, { email: this.changeEmailForm.get(this.NEW_EMAIL_ADDRESS_FORM_CONTROL_NAME)?.value });
      this.snackBar.open(response.successMessage, undefined, {
        duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
      });
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.snackBar.open(
        (error as HttpErrorResponse).error?.errorMessage ?? ChangeEmailDialogComponent.DEFAULT_ERROR_SNACKBAR_STRING,
        undefined,
        { duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS },
      );
    }
  }
}
