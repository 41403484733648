// @ts-strict-ignore
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@insig-health/services/auth/auth.service';
import { firstValueFrom } from 'rxjs';
import firebase from 'firebase/compat/app';
import { RegexService } from '@insig-health/services/regex/regex.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'insig-health-launch-survey-login',
  templateUrl: './launch-survey-login.component.html',
})
export class LaunchSurveyLoginComponent implements OnInit {

  public showLoginForm = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private firebaseAuthService: FirebaseAuthService,
    private regexService: RegexService,
    private router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    const paramMap = this.activatedRoute.snapshot.paramMap;

    const apptId = this.activatedRoute.snapshot.queryParamMap.get('apptID');
    const isUserLoggedIn = await firstValueFrom(this.authService.isLoggedIntoSpring());
    const user = await firstValueFrom(this.firebaseAuthService.onIdTokenChanged());
    const bypassPatientInfoForm = this.isManualAppointmentId(apptId) && await this.isUserAPatient(user);
    const isManualAppointmentParam = paramMap.get('manualAppt') === 'true';
    const isPreviewSurvey = paramMap.get('moreOptions') === 'preview';

    const surveyPatientUid = paramMap.get('uid') ?? this.activatedRoute.snapshot.queryParamMap.get('uid');
    const isUidValidForSurvey = surveyPatientUid && this.isLoggedInWithUid(user, surveyPatientUid);
    const isOnlyLogin = paramMap.get('onlyLogin');

    if (!isOnlyLogin || isPreviewSurvey || bypassPatientInfoForm || isManualAppointmentParam || (isUserLoggedIn && isUidValidForSurvey)) {
      await this.navigateToSurvey();
    } else {
      this.showLoginForm = true;
    }
  }

  async handleLogin(): Promise<void> {
    this.showLoginForm = false;
    await this.navigateToSurvey();
  }

  async isUserAPatient(user: firebase.User | null): Promise<boolean> {
    if (user === null) {
      return false;
    } else {
      return !await this.firebaseAuthService.checkIfUserIsDoctor(user);
    }
  }

  isManualAppointmentId(apptId: string): boolean {
    return this.regexService.getManualAppointmentLinkRegex().test(apptId);
  }

  isLoggedInWithUid(user: firebase.User | null, uid: string): boolean {
    return user?.uid === uid;
  }

  async navigateToSurvey(): Promise<void> {
    await this.router.navigate(['./loggedIn'], { relativeTo: this.activatedRoute, queryParamsHandling: 'merge' });
  }
}
