// @ts-strict-ignore
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import { APPCONFIG } from '@insig-health/config/config';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@insig-health/services/translate/translate.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { MailchimpService } from 'insig-app/services/mailchimp.service';
import { AppConfig } from 'insig-types/company-data/app-config';
import { UntypedFormGroup } from '@angular/forms';
import { CompanyData } from 'insig-types/company-data';
import { PatientRegistration, PatientRegistrationService } from '@insig-health/api/patient-registration-api';
import { take } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AwsWafCaptchaDialogComponent } from '@insig-health/services/aws-waf-captcha/aws-waf-captcha-dialog.component';
import { PatientProfile } from '@insig-health/insig-types/user-data';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Component({
  selector: 'patient-login-sign-up',
  templateUrl: './patient-login-sign-up.component.html',
  styleUrls: ['./patient-login-sign-up.component.scss'],
})
export class PatientLoginSignUpComponent implements OnInit {
  public static readonly CAPTCHA_INCOMPLETE_ERROR_MESSAGE = 'Please complete the CAPTCHA to continue.';

  // graphql queries
  private companyDataQuery = gql`
    query CompanyDataQuery($companyID: ID!, $token: ID) {
      getCompanyData(cid: $companyID, token: $token) {
        id
        AppConfig {
          colorOption
          theme
        }
        ThemeConfig {
          primary
          secondary
          text
          textSecondary
          textSelected
        }
        branding
        brandingName
        website
        name
        hidePharmacy
        discountCodes
        email
        locations {
          formatted_address
          id
          lat
          lng
        }
        country {
          name
          code
          currency
        }
      }
    }
  `;

  public AppConfig: AppConfig | undefined;
  public loadingLogo = false;
  public companyData: CompanyData | undefined;
  public cid: string | undefined;
  public tiaHealth = false;
  public languageList: string[] = [];
  public loading = false;
  public userID: string | undefined;
  public selectedLanguage = 'English';
  public physioAccount = false; // ID of the physio salesperson who referred us
  public specialLogo = '';

  @Input() partialPatient: PatientProfile;

  @Output() backToLoginClicked = new EventEmitter<void>();
  @Output() registrationComplete = new EventEmitter<void>();
  @Output() registrationFailed = new EventEmitter<Error>();

  constructor(
    private cookieService: CookieService,
    private patientUserDataService: PatientUserDataService,
    private translateService: TranslateService,
    private mailchimpService: MailchimpService,
    private patientRegistrationService: PatientRegistrationService,
    private gcpIpAuthService: GcpIpAuthService,
    private apollo: Apollo,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,

  ) { }

  async ngOnInit(): Promise<void> {
    this.AppConfig = APPCONFIG;
    if (
      window.location.origin.includes('app.well.company') ||
      window.location.origin.includes('app.wellclinics.ca')
    ) {
      this.specialLogo = 'assets/images/global/well/well-logo-normal.svg';
    } else if (window.location.origin.includes('app.jacknathanhealth.com')) {
      this.specialLogo = 'assets/images/global/jnh/jnh-logo.png';
    } else if (window.location.origin.includes('app.thevirtualdoctor.org')) {
      this.specialLogo = 'assets/images/global/tvd/tvd.jpeg';
    } else if (window.location.origin.includes('virtual.highmark.tech')) {
      this.specialLogo = 'assets/images/global/eq/eq-logo.png';
    }

    this.route.queryParams.subscribe((params) => {
      if (params.phy) {
        this.physioAccount = params.phy;
        console.log('! physio account');
      }
    });

    this.route.params.subscribe((params) => {
      if (params.phy) {
        this.physioAccount = params.phy;
        console.log('! physio account');
      }
    });

    this.route.params.subscribe((params) => {
      if (params.cid || params.companyID) {
        this.cid = params.cid || params.companyID;
        this.tiaHealth = this.cid === 'tiaHealth';
        this.loadingLogo = true;
        this.loadCompanyData(this.cid);
        this.cookieService.set(
          'brandingCID',
          this.cid,
          undefined,
          undefined,
          undefined,
          true,
          'None',
        );
      } else {
        setTimeout(() => {
          this.cookieService.delete('brandingCID');
          // reset app config
          this.AppConfig.colorOption = '22';
          this.AppConfig.theme = 'light';
          this.AppConfig.brand = '';
          delete this.AppConfig.website;
          delete this.AppConfig.cid;
          delete this.AppConfig.branding;
        }, 2000);
      }
    });

    this.languageList = await this.translateService.getLanguageList();
  } // end func

  async switchLanguage(language: string): Promise<void> {
    this.translateService.changeLanguage(language);
  }

  async loadCompanyData(cid: string): Promise<void> {
    try {
      const companyDataQuery = await this.apollo
        .query<{ getCompanyData: CompanyData }>({
          query: this.companyDataQuery,
          variables: {
            companyID: cid,
            token: undefined,
          },
        })
        .toPromise();
      this.companyData = companyDataQuery.data.getCompanyData;
      this.loadingLogo = false;
      console.log(this.companyData);
    } catch (error) {
      console.log(error);
    }
  }

  async onSignUp(signUpForm: UntypedFormGroup): Promise<void> {
    console.log(signUpForm);
    this.loading = true;

    let patientRegistration: PatientRegistration;
    try {
      patientRegistration = this.patientUserDataService.getPatientRegistrationFromForm(signUpForm);
    } catch (error) {
      this.snackbar.open('Error: ' + error.message, undefined, { duration: 4000 });
      this.loading = false;
      return;
    }

    try {
      await this.patientRegistrationService.createPatient(patientRegistration).pipe(take(1)).toPromise();
    } catch (error) {
      this.handlePatientRegistrationErrorResponse(error, signUpForm);
      this.loading = false;
      return;
    }

    this.snackbar.open('Your account has been created!', undefined, {
      duration: 4000,
    });
    this.mailchimpService.mailChimpSubscribe(signUpForm, this.tiaHealth);

    this.registrationComplete.emit();

    // TODO reimplement this serverside
    // cusotm account settings for physio accounts
    // if (this.physioAccount) {
    //   signed = await this.patientUserDataService.createPhysioPatientAccount(
    //     signUpForm,
    //     this.physioAccount
    //   );
    // } else {
    //   signed = await this.patientUserDataService.createPatientAccountFromForm(
    //     signUpForm
    //   );
    // }
  }

  onProfileComplete(): void {
    this.registrationComplete.emit();
  }

  async backToLogin(): Promise<void> {
    await this.gcpIpAuthService.signOut();
    this.backToLoginClicked.emit();
  }

  handlePatientRegistrationErrorResponse(error: HttpErrorResponse | Error, signUpForm: UntypedFormGroup): void {
    if (error instanceof HttpErrorResponse && error.status === 405) {
      const dialogRef = this.dialog.open(AwsWafCaptchaDialogComponent, AwsWafCaptchaDialogComponent.DEFAULT_CONFIG);
      dialogRef.afterClosed().subscribe((isCaptchaComplete) => {
        if (isCaptchaComplete) {
          this.onSignUp(signUpForm);
        }
      });
      return;
    }

    const errorMessage = error instanceof Error ?
      error.message :
      error.error.errorMessage;

    this.snackbar.open(`${errorMessage}`, undefined, { duration: 8000 });

    console.error(error);

    Sentry.captureException(error, {
      tags: {
        signupError: 'Failed to create patient',
      },
      contexts: {
        onSignUp: {
          userId: this.userID,
          responseMessage: errorMessage,
          responseError: error,
        },
      },
    });
  }
}
