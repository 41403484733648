// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { Notification } from 'insig-types/firestore-collections/notifications';
import firebase from 'firebase/compat/app';

import { of as observableOf, Observable } from 'rxjs';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';
import { take } from 'rxjs/operators';
import { DoctorScheduleReindexService } from '@insig-health/services/doctor-schedule-reindex/doctor-schedule-reindex.service';
import { FirebaseAuthService } from '../firebase-auth/firebase-auth.service';
import { NewService } from 'insig-app/virtual-care/settings/components/services/new/new-virtual-service.component';

interface UserGroup {
  companyID: string;
  doctorID: string;
  email: string;
  enabled: boolean;
  first: string;
  last: string;
  userGroupID: string;
}

@Injectable({
  providedIn: 'root',
})
export class VirtualService {
  private applyDiscountCodeURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/applyDiscountCode';
  private deleteCompanyVirtualServiceURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/deleteCompanyVirtualService';
  private updateCompanyVirtualServiceURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/updateCompanyVirtualService';
  private doctorEmailToUserDataURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/doctorEmailToUserData';

  private deleteUserVirtualServiceUrl =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/deleteUserVirtualService';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private firestoreService: FirestoreService,
    private firebaseAuthService: FirebaseAuthService,
    private doctorScheduleReindexService: DoctorScheduleReindexService,
  ) {}

  /**
   * Check the Tia Health application status of a user
   * @param  {string}          uid Doctor's uid
   * @return {Observable<string>}  Observable containing either "not applied", "applied", or "approved"
   */
  checkTiaHealthApplicationStatus(uid: string): Observable<string> {
    if (!uid) {
      return observableOf(null);
    } else {
      return Observable.create((observer) => {
        return firebase
          .firestore()
          .collection('tiaHealthStatus')
          .doc(uid)
          .onSnapshot({
            next: async (document) => {
              if (!document.exists) {
                observer.next('not applied');
              } else {
                const data = await document.data();
                if (data.approved) {
                  observer.next('approved');
                } else if (data.applied) {
                  observer.next('applied');
                } else {
                  observer.next('not applied');
                }
              }
            },
            error: (error) => {
              observer.error(error);
            },
          });
      });
    }
  }

  /**
   * Apply for Tia Health
   * @param  {string}        uid Doctor's uid
   * @return {Promise<void>}     Promise that resolves upon completion
   */
  async applyFortiaHealth(uid: string): Promise<void> {
    // Check if user already has a pending application or has already been approved
    const document = await firebase
      .firestore()
      .collection('tiaHealthStatus')
      .doc(uid)
      .get();
    if (document.exists) {
      const data = await document.data();
      if (data.applied || data.approved) {
        return;
      } else {
        return firebase
          .firestore()
          .collection('tiaHealthStatus')
          .doc(uid)
          .update({ applied: true });
      }
    } else {
      return firebase
        .firestore()
        .collection('tiaHealthStatus')
        .doc(uid)
        .set({ applied: true }, { merge: true });
    }
  }

  getDoctorNotifications(doctorID: string): Observable<Notification[]> {
    return this.firestoreService.getQueryAsObservable<Notification>(
      firebase.firestore().collection('notifications')
        .where('doctorID', '==', doctorID)
    )
  }

  removeApptNotification(linkID) {
    firebase
      .firestore()
      .collection('notifications')
      .where('linkID', '==', linkID)
      .get()
      .then((snapshot) => {
        if (snapshot && snapshot.docs && snapshot.docs.length > 0) {
          snapshot.docs.forEach((doc) => {
            console.log(doc.id);
            console.log(doc.data());
            firebase
              .firestore()
              .collection('notifications')
              .doc(doc.id)
              .delete()
              .then(() => {
                console.log('notif deleted!');
              })
              .catch((error) => {
                console.error('Error deleting notif: ', error);
              });
          });
        }
      });
  }

  getCompanyVirtualCategories(cid) {
    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('companyVirtualCategories')
        .where('companyID', '==', cid)
    );
  }

  setCompanyCategories(cid, data) {
    firebase
      .database()
      .ref('company/virtual/categories/' + cid)
      .set(data);
  }

  async addCompanyCategory(cid, catID, data) {
    await firebase
      .firestore()
      .collection('companyVirtualCategories')
      .add({
        companyID: cid,
        catID,
        data,
      });
  }

  async deleteCompanyCategory(catID): Promise<any> {
    console.log('Delete company cat', catID);
    return new Promise<void>((resolve, reject) => {
      firebase
        .firestore()
        .collection('companyVirtualCategories')
        .where('catID', '==', catID)
        .get()
        .then((snapshot) => {
          const docID = snapshot.docs[0].id;
          firebase
            .firestore()
            .collection('companyVirtualCategories')
            .doc(docID)
            .delete()
            .then(() => {
              console.log('Category deleted!');
              resolve();
            })
            .catch((error) => {
              console.error('Error deleting category: ', error);
              reject(error);
            });
        });
    });
  }

  addCompanyVirtualService(data: NewService & { id?: string }): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
    data = Object.assign({}, data);
    delete data.id;
    if (typeof data.data.buffer === 'string') {
      data.data.buffer = parseInt(data.data.buffer);
    }
    if (typeof data.data.duration === 'string') {
      data.data.duration = parseInt(data.data.duration);
    }
    if (typeof data.data.diagnosticCode === 'number') {
      data.data.diagnosticCode = (data.data.diagnosticCode as number).toString();
    }

    return firebase
      .firestore()
      .collection('companyVirtualServices')
      .add(data);
  }

  deleteCompanyVirtualService(IDToken, apptID) {
    // needs to be updated to delete all doctor opted in
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.deleteCompanyVirtualServiceURL,
          JSON.stringify({
            IDToken,
            apptID,
          }),
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  updateCompanyVirtualService(
    IDToken: string,
    apptID: string,
    appointmentData: NewService & { id?: string },
  ): Promise<any> {
    appointmentData = Object.assign({}, appointmentData);
    delete appointmentData.id;
    if (typeof appointmentData.data.buffer === 'string') {
      appointmentData.data.buffer = parseInt(appointmentData.data.buffer);
    }
    if (typeof appointmentData.data.duration === 'string') {
      appointmentData.data.duration = parseInt(appointmentData.data.duration);
    }
    if (typeof appointmentData.data.diagnosticCode === 'number') {
      appointmentData.data.diagnosticCode = (appointmentData.data.diagnosticCode as number).toString();
    }

    // check if service exists
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.updateCompanyVirtualServiceURL,
          JSON.stringify({
            IDToken,
            apptID,
            appointmentData,
          }),
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  addCompanyPlanVirtualService(data) {
    firebase
      .firestore()
      .collection('companyPlanVirtualServices')
      .add(data);
  }

  async updateCompanyPlanVirtualService(iden: string, data: any) {
    await firebase
      .firestore()
      .collection('companyPlanVirtualServices')
      .doc(iden)
      .set(data, { merge: true });
  }

  getCompanyPlanVirtualServices(cid) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('companyPlanVirtualService')
        .where('companyID', '==', cid)
    );
  }

  getCompanyPlanVirtualServicesByPlanID(pid) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('companyPlanVirtualServices')
        .where('planID', '==', pid)
    );
  }

  async removeCompanyPlanVirtualService(iden: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      firebase
        .firestore()
        .collection('companyPlanVirtualServices')
        .doc(iden)
        .delete()
        .then(() => {
          console.log('Document successfully deleted!');
          resolve();
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
          reject();
        });
    });
  }

  // add company Plans

  addCompanyPlan(data) {
    firebase
      .firestore()
      .collection('companyPlans')
      .add(data);
  }

  getCompanyPlans(cid) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('companyPlans')
        .where('companyID', '==', cid)
    );
  }

  async getCompanyPlanByID(id) {
    return (
      await firebase
        .firestore()
        .collection('companyPlans')
        .doc(id)
        .get()
    ).data();
  }

  updateCompanyPlans(iden: string, data: any) {
    console.log(iden);
    console.log(data);
    firebase
      .firestore()
      .collection('companyPlans')
      .doc(iden)
      .set(data, { merge: true });
  }

  removeCompanyPlan(iden: string) {
    firebase
      .firestore()
      .collection('companyPlans')
      .doc(iden)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
  }

  // subscribed company Plans
  addSubscribedCompanyPlan(data) {
    firebase
      .firestore()
      .collection('companyPlansSubscribed')
      .add(data);
  }

  getSubscribedCompanyPlansByUserGroupID(userGroupID) {
    console.log('usergoup id', userGroupID);

    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('companyPlansSubscribed')
        .where('subscribedUserGroupID', '==', userGroupID)
    );
  }

  getSubscribedUserGroupsByUID(uid) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userGroupSubscribed')
        .where('uid', '==', uid)
    );
  }

  getSubscribedCompanyPlansByPlanIDByType(planID, type) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('companyPlansSubscribed')
        .where('planID', '==', planID)
        .where('type', '==', type)
    );
  }

  removeSubscribedCompanyPlan(iden: string) {
    firebase
      .firestore()
      .collection('companyPlansSubscribed')
      .doc(iden)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
  }

  // user groups

  async addUserGroup(data) {
    await firebase
      .firestore()
      .collection('userGroups')
      .add(data);
  }

  getUserGroupsByCompany(cid) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userGroups')
        .where('companyID', '==', cid)
    );
  }

  getUserGroupsByCompanyByPlan(cid, planID) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userGroups')
        .where('companyID', '==', cid)
        .where('planID', '==', planID)
    );
  }

  removeUserGroup(iden: string) {
    firebase
      .firestore()
      .collection('userGroups')
      .doc(iden)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
  }

  doctorEmailToUserData(email, idToken): any {
    return this.http
      .post(
        this.doctorEmailToUserDataURL,
        {
          email,
          idToken,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  } // end sendEmail func

  // user group subscribed
  async addUserGroupSubscribed(data, idToken) {
    const doctorData = await this.doctorEmailToUserData(data.email, idToken);
    if (!doctorData.body && doctorData.body.uid) {
      return false;
    }
    data = Object.assign(data, doctorData.body);
    data.doctorID = data.uid;
    console.log(data);
    delete data.email;
    await firebase
      .firestore()
      .collection('userGroupSubscribed')
      .add(data);

    if (doctorData?.body?.company && doctorData?.body?.uid) {
      await this.doctorScheduleReindexService.reindexSchedule(doctorData.body.uid, doctorData.body.company);
    }
  }

  getUserGroupSubscribedByGroupID(groupID) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userGroupSubscribed')
        .where('userGroupID', '==', groupID),
    );
  }

  getUserGroupSubscribedByGroupIdData(groupId: string): Observable<UserGroup[]> {
    return this.firestoreService.getQueryAsObservable<UserGroup>(
      firebase.firestore().collection('userGroupSubscribed')
        .where('userGroupID', '==', groupId),
    );
  }

  getUserGroupSubscribedByUserID(userID) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userGroupSubscribed')
        .where('uid', '==', userID)
    );
  }

  async removeUserGroupSubscribed(iden: string) {
    return new Promise<void>((resolve, reject) => {
      firebase
        .firestore()
        .collection('userGroupSubscribed')
        .doc(iden)
        .delete()
        .then(() => {
          console.log('Document successfully deleted!');
          resolve();
        })
        .catch((error) => {
          console.error('Error removing document: ', error);
          reject(error);
        });
    });
  }

  updateUserGroupSubscribed(iden: string, data: any) {
    delete data.email;
    firebase
      .firestore()
      .collection('userGroupSubscribed')
      .doc(iden)
      .set(data, { merge: true });
  }
  // end

  addUserVirtualService(data: NewService & { id?: string }): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
    data = Object.assign({}, data);
    delete data.id;
    if (typeof data.data.buffer === 'string') {
      data.data.buffer = parseInt(data.data.buffer);
    }
    if (typeof data.data.duration === 'string') {
      data.data.duration = parseInt(data.data.duration);
    }
    if (typeof data.data.diagnosticCode === 'number') {
      data.data.diagnosticCode = (data.data.diagnosticCode as number).toString();
    }

    return firebase
      .firestore()
      .collection('userVirtualServices')
      .add(data);
  }

  getCompanyVirtualServices(cid) {
    return this.firestoreService.getQueryAsObservable(
      firebase.firestore().collection('companyVirtualServices')
        .where('companyID', '==', cid)
    );
  }

  getUserVirtualServicesByDoctor(doctorID) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userVirtualServices')
        .where('doctorID', '==', doctorID)
    );
  }

  getUserVirtualServicesByDoctorByCompany(doctorID, companyID) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userVirtualServices')
        .where('doctorID', '==', doctorID)
        .where('companyID', '==', companyID)
    );
  }

  getUserVirtualServicesByDoctorByPlanID(doctorID, planID) {
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('userVirtualServices')
        .where('doctorID', '==', doctorID)
        .where('planID', '==', planID)
    );
  }

  updateUserVirtualService(
    data: NewService & { id?: string },
    doctorID: string,
    apptID: string
  ): Promise<void> {
    data = Object.assign({}, data);
    delete data.id;
    if (typeof data.data.buffer === 'string') {
      data.data.buffer = parseInt(data.data.buffer);
    }
    if (typeof data.data.duration === 'string') {
      data.data.duration = parseInt(data.data.duration);
    }
    if (typeof data.data.diagnosticCode === 'number') {
      data.data.diagnosticCode = (data.data.diagnosticCode as number).toString();
    }

    // check if service exists
    console.log('saving service');
    return new Promise(async (resolve, reject) => {
      try {
        const querySnapshot = await firebase
          .firestore()
          .collection('userVirtualServices')
          .where('doctorID', '==', doctorID)
          .where('apptID', '==', apptID)
          .get();

        if (querySnapshot.docs.length > 0) {
          for (const doc of querySnapshot.docs) {
            await doc.ref.set(data, { merge: true });
          }
        } else {
          reject();
        }

        resolve();
      } catch (err) {
        console.log('error saving service: ', err);
        reject();
      }
    });
  }

  async deleteUserVirtualService(documentId: string): Promise<void> {
    await this.http.post(
      this.deleteUserVirtualServiceUrl,
      {
        idToken: await this.firebaseAuthService.idToken$.pipe(take(1)).toPromise(),
        documentId,
      }
    ).toPromise();
  }

  applyDiscountCode(IDToken, discountCode, companyID) {
    return this.http
      .post(
        this.applyDiscountCodeURL,
        JSON.stringify({
          IDToken,
          discountCode,
          companyID,
        }),
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }
} // end service
