import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'patient-terms-dialog',
  templateUrl: './patient-terms-dialog.component.html',
  styleUrls: ['./patient-terms-dialog.component.scss'],
})
export class PatientTermsDialogComponent {
  constructor(public dialogRef: MatDialogRef<PatientTermsDialogComponent>) {}
}
