// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';

import { take } from 'rxjs/operators';

@Injectable()
export class SurveyApiService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

  async getSessionExchangeUrl(appId: string): Promise<string> {
    const sessionExchangeUrl = await firebase
      .firestore()
      .collection('surveyApiApplications')
      .doc(appId)
      .get()
      .then((document) =>
        document.exists ? document.data().sessionExchangeUrl : undefined
      );
    if (!sessionExchangeUrl) {
      throw new Error('No session exchange url');
    } else {
      return sessionExchangeUrl;
    }
  }

  async getSaveSurveyUrl(appId: string): Promise<string> {
    const saveSurveyUrl = await firebase
      .firestore()
      .collection('surveyApiApplications')
      .doc(appId)
      .get()
      .then((document) =>
        document.exists ? document.data().saveSurveyUrl : undefined
      );
    if (!saveSurveyUrl) {
      throw new Error('No save survey url');
    } else {
      return saveSurveyUrl;
    }
  }

  async getSessionId(appId: string, token: string): Promise<string> {
    const sessionExchangeUrl = await this.getSessionExchangeUrl(appId);
    return await this.http
      .post<{ sessionId: string }>(sessionExchangeUrl, { token })
      .pipe(take(1))
      .toPromise()
      .then((response) => response.sessionId);
  }

  async saveSurvey(
    appId: string,
    sessionId: string,
    surveyTemplateData: any
  ): Promise<any> {
    // TODO add strict typing
    const saveSurveyUrl = await this.getSaveSurveyUrl(appId);
    return await this.http
      .post(
        saveSurveyUrl,
        { sessionId, surveyTemplateData },
        { headers: this.headers }
      )
      .toPromise();
  }

  async getSubmitSurveyUrl(appId: string): Promise<string> {
    const submitSurveyUrl = await firebase
      .firestore()
      .collection('surveyApiApplications')
      .doc(appId)
      .get()
      .then((document) =>
        document.exists ? document.data().submitSurveyUrl : undefined
      );
    if (!submitSurveyUrl) {
      throw new Error('No submit survey url');
    } else {
      return submitSurveyUrl;
    }
  }

  async submitSurvey(
    appId: string,
    sessionId: string,
    surveyData: any
  ): Promise<any> {
    // TODO add strict typing
    const submitSurveyUrl = await this.getSubmitSurveyUrl(appId);
    return await this.http
      .post(
        submitSurveyUrl,
        { sessionId, surveyData },
        { headers: this.headers }
      )
      .toPromise();
  }
}
