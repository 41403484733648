// @ts-strict-ignore
import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';
import { of as observableOf } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyLocationService {
  constructor(private firestoreService: FirestoreService) {}

  getLocations(cid: string) {
    if (cid === null) {
      return observableOf([]);
    }
    return this.firestoreService.getQueryWithIdAsObservable(
      firebase.firestore().collection('companyLocations')
        .where('companyID', '==', cid)
    );
  }

  getLocationsByID(locationID): Promise<firebase.firestore.DocumentSnapshot> {
    return firebase
      .firestore()
      .collection('companyLocations/')
      .doc(locationID)
      .get();
  }

  queryLocationByID(
    locationID
  ): Promise<
    firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
  > {
    return firebase
      .firestore()
      .collection('companyLocations/')
      .where('locationID', '==', locationID)
      .get();
  }

  addLocation(data) {
    console.log(data)

    if(!!data.locationObj){
      for (let i in data.locationObj){
        if(data.locationObj[i] === undefined){
          delete data.locationObj[i];
        }
      }
    }

    firebase
      .firestore()
      .collection('companyLocations/')
      .add(data);
  }

  updateLocation(iden: string, data: any) {
    firebase
      .firestore()
      .collection('companyLocations')
      .doc(iden)
      .set(data, { merge: true });
  }

  removeLocation(iden: string) {
    firebase
      .firestore()
      .collection('companyLocations')
      .doc(iden)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
  }
}
