import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { INACTIVITY_TIMEOUT_MS } from '@insig-health/config/config';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { ReauthenticateDialogComponent } from 'insig-app/dialogs/reauthenticate/reauthenticate-dialog.component';
import jwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { firstValueFrom, Subscription } from 'rxjs';
import { MILLISECONDS_PER_MINUTE } from '../doctorSettings.service.utilities';

const BOOKING_FLOW_REGEX = new RegExp(/^\/virtual\/book-appointment\/.*$/);
const LOGIN_PAGE_REGEX = new RegExp(/^\/auth\/.*$/);
enum EventType {
  POINTER_MOVE = 'pointermove',
  POINTER_DOWN = 'pointerdown',
}

@Injectable({
  providedIn: 'root'
})
export class InactivityService implements OnDestroy {
  private static readonly INACTIVITY_COOKIE_NAME = 'insig_last_activity_time';
  private readonly POINTER_MOVE_LISTENER = this.setLastActivityTime();
  private readonly POINTER_DOWN_LISTENER = this.setLastActivityTime();
  private static inactivityCheckInterval: number | undefined;
  private isLoggedInRecentlySubscription: Subscription;

  constructor(
    private gcpIpAuthService: GcpIpAuthService,
    private cookieService: CookieService,
    private dialog: MatDialog,
  ) {
    window.addEventListener(EventType.POINTER_MOVE, this.POINTER_MOVE_LISTENER);
    window.addEventListener(EventType.POINTER_DOWN, this.POINTER_DOWN_LISTENER);
    this.isLoggedInRecentlySubscription = this.gcpIpAuthService.getAuthStateChanged().subscribe(async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        const decodedToken = jwtDecode(idToken) as { iat: number };
        const now = new Date().getTime();
        const iat = decodedToken?.iat ?? 0;
        if (now - (iat * 1000) < INACTIVITY_TIMEOUT_MS) {
          this.setLastActivityTime()();
        }
      }
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener(EventType.POINTER_MOVE, this.POINTER_MOVE_LISTENER);
    window.removeEventListener(EventType.POINTER_DOWN, this.POINTER_DOWN_LISTENER);
    this.stop();
    this.isLoggedInRecentlySubscription.unsubscribe();
  }

  async start(): Promise<void> {
    if (InactivityService.inactivityCheckInterval) {
      window.clearInterval(InactivityService.inactivityCheckInterval);
    }
    InactivityService.inactivityCheckInterval = window.setInterval(async () => {
      if (await this.isInactive()) {
        this.handleInactivity();
      }
    }, MILLISECONDS_PER_MINUTE);

    if (await this.isInactive()) {
      this.handleInactivity();
    }
  }

  stop(): void {
    if (InactivityService.inactivityCheckInterval) {
      window.clearInterval(InactivityService.inactivityCheckInterval);
    }
  }

  private setLastActivityTime(): () => void {
    return () => {
      const date = new Date();
      this.cookieService.set(InactivityService.INACTIVITY_COOKIE_NAME, date.getTime().toString(), new Date('Jan 1 2038'), '/');
    };
  }

  private getLastActivityTime(): number {
    const cookieValue = this.cookieService.get(InactivityService.INACTIVITY_COOKIE_NAME);
    return parseInt(cookieValue) ?? 0;
  }

  private async openReauthenticateDialog(): Promise<void> {
    const reauthenticateDialog = this.dialog.getDialogById(ReauthenticateDialogComponent.DEFAULT_DIALOG_ID) ??
      this.dialog.open(ReauthenticateDialogComponent, ReauthenticateDialogComponent.DEFAULT_DIALOG_CONFIG);
    await firstValueFrom(reauthenticateDialog.afterClosed());
    window.location.reload();
  }

  private async isInactive(): Promise<boolean> {
    const isLoggedIn = await firstValueFrom(this.gcpIpAuthService.isLoggedIn())
    if (!isLoggedIn) {
      return false;
    }

    const lastActivityTime = this.getLastActivityTime();
    const timeoutMs = INACTIVITY_TIMEOUT_MS;
    const now = new Date().getTime();
    return now - lastActivityTime > timeoutMs;
  }

  private async handleInactivity(): Promise<void> {
    await this.gcpIpAuthService.signOut();

    if (!this.isBlacklistedPathname()) {
      await this.openReauthenticateDialog();
    }
  }

  private isBlacklistedPathname(): boolean {
    const pathname = window.location.pathname;
    return BOOKING_FLOW_REGEX.test(pathname) || LOGIN_PAGE_REGEX.test(pathname);
  }
}
