// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

@Injectable({
  providedIn: 'root',
})
export class EMRService {
  private checkIfPatientRosteredAllConnectedEMRsURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'emr/checkIfPatientRosteredAllConnectedEMRs';
  private checkIfPatientIsRosteredAtLocationURL = AWSCLOUDFUNCTIONSENDPOINT + 'emr/checkIfPatientIsRosteredAtLocation'

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) {}

    checkIfPatientIsRosteredAtLocation(
        companyID,
        locationID,
        patientData: any = false,
        patientID: any = false
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(
                    this.checkIfPatientIsRosteredAtLocationURL,
                    {
                        companyID,
                        patientData,
                        patientID,
                        locationID
                    },
                    { headers: this.headers, responseType: 'text' }
                )
                .toPromise()
                .then((response) => {
                    console.log(response);
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    reject(error);
                });
        });
    } // end func
  // TO DO function coming soon
  checkIfPatientRosteredAllConnectedEMRs(
    companyID,
    doctorID,
    patientData: any = false,
    patientID: any = false
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.checkIfPatientRosteredAllConnectedEMRsURL,
          {
            companyID,
            doctorID,
            patientData,
            patientID,
          },
          { headers: this.headers, responseType: 'text' }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  } // end func
} // end service1
