// @ts-strict-ignore
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { MatDialog } from '@angular/material/dialog';
import { FlagColorsService } from '../../../services/flagColors.service';
import { IntegrationsDialogComponent } from '../../../dialogs/integrations-dialog.component';

@Component({
  selector: 'scoring-edit',
  templateUrl: './scoring-edit.component.html',
  styleUrls: ['./scoring-edit.component.scss'],
  providers: [FlagColorsService],
})
export class ScoringEditComponent implements OnInit {
  @Input() question: any;
  @Output() questionChange: EventEmitter<any>;
  public flagColors = null;

  constructor(
    private flagColorsService: FlagColorsService,
    public dialog: MatDialog
  ) {
    this.questionChange = new EventEmitter<any>();
  }

  ngOnInit() {
    this.flagColors = this.flagColorsService.getFlagColors();
    if (!this.question.offeredAnswers) {
      this.question.offeredAnswers = [];
      this.addOption(0);
      this.addOption(0);
    }
  }

  openIntegrationsDialog(answer) {
    const dialogRef = this.dialog.open(IntegrationsDialogComponent);
    dialogRef.componentInstance.answer = answer;
    dialogRef.componentInstance.startingText = answer.value;
  }

  addOption(index: number) {
    const id = this.generateRandomID(32);
    this.question.offeredAnswers.splice(index, 0, {
      id,
      orderNo: index + 1,
      score: '',
      value: '',
    });
    this.updateQuestion();
  }
  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  drop(event: CdkDragDrop<void>) {
    moveItemInArray(
      this.question.offeredAnswers,
      event.previousIndex,
      event.currentIndex
    );
    this.updateQuestion();
  }

  onAnswerChange(index, newValue) {
    this.question.offeredAnswers[index].value = newValue;
    this.updateQuestion();
  }

  onScoreChange(index, newValue) {
    this.question.offeredAnswers[index].score = newValue;
    this.updateQuestion();
  }

  updateQuestion() {
    this.question.offeredAnswers.forEach((item, index) => {
      item.orderNo = index;
    });
    this.questionChange.emit(this.question);
  }
}
