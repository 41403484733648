// @ts-strict-ignore
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'download-survey-data-dialog',
  templateUrl: './download-survey-data-dialog.component.html',
  styleUrls: ['./download-survey-data-dialog.component.scss'],
})
export class DownloadSurveyDataDialogComponent implements OnInit {
  public form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<DownloadSurveyDataDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // (window.document.querySelector(
    //   '.insig-dialog-panel'
    // ) as HTMLElement).style.minWidth = '40%';
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      withPatientData: this.formBuilder.control(null, [Validators.required]),
    });
  }

  downloadSurveyData() {
    if (!this.form.controls['withPatientData'].value) {
      this.snackBar.open(
        'Please select if you want to include patient data!',
        null,
        { duration: 3000 }
      );
      this.dialogRef.close(false);
    }
    this.dialogRef.close(this.form.controls['withPatientData'].value);
  }
}
