// @ts-strict-ignore
import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PDFTextFieldDialogComponent } from './pdf-textfield-dialog.component';

import * as PDFJS from 'pdfjs-dist';

import { fromEvent as observableFromEvent } from 'rxjs';
import { takeUntil, switchMap, pairwise } from 'rxjs/operators';

const DrawingOrText = {
  DRAWING: false,
  TEXT: true,
} as const;

@Component({
  selector: 'pdf-dialog-question',
  templateUrl: './pdf-dialog.component.html',
  styles: [],
})
export class PDFDialogComponent implements OnInit, AfterViewInit {
  @Input() question;
  @ViewChild('pdfContainer') _pdfContainer: ElementRef;
  private pdfContainer: HTMLDivElement;

  public error = false;
  public drawingOrText: boolean = DrawingOrText.DRAWING;
  public colors = [
    '#000',
    '#9CB199',
    '#ef5350',
    '#485247',
    '#E77547',
    '#D38E47',
    '#0A6A74',
    '#153974',
  ];
  public lineColor = '#153974';
  public fontSizes = [
    '1em Arial',
    '1.5em Arial',
    '2em Arial',
    '2.5em Arial',
    '3em Arial',
  ];
  private font = '1.5em Arial';
  private lineWidth = 4;
  private savedCanvases: Array<{
    id: string;
    unset: boolean;
    original: boolean;
    canvasImg?: string;
  }> = [];

  constructor(
    public dialogRef: MatDialogRef<PDFDialogComponent>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.question.canvasOptions = [];
    // using PDF editor in edit-note as a doctor
    if (this.question.type === 'pdfEditor') {
      this.question.id = 'pdfEditor';
      this.lineColor = '#000';
    }
  }

  ngAfterViewInit(): void {
    this.pdfContainer = this._pdfContainer.nativeElement;
    if (!!this.question.response && !!this.question.response[0]) {
      this.clearPdfContainer();
      this.pdfContainer.append(...this.getCanvases(this.question.response));
    } else {
      this.renderWholePdf();
    }
  }

  private clearPdfContainer(): void {
    while (this.pdfContainer.hasChildNodes()) {
      this.pdfContainer.removeChild(this.pdfContainer.lastChild);
    }
  }

  async renderWholePdf(): Promise<void> {
    this.clearPdfContainer();
    this.pdfContainer.append(
      ...(await this.getCanvasesFromPdf(this.question.pdfURL))
    );
  }

  getCanvases(originalImages: string[]): HTMLCanvasElement[] {
    return originalImages.map((savedCanvas) => {
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d');
      this.pdfContainer.appendChild(canvas);

      // setup image
      const background = new Image();
      background.crossOrigin = 'Anonymous';
      // Make sure the image is loaded first otherwise nothing will draw.
      // and scaling image size properly
      background.src = savedCanvas;
      background.onload = () => {
        canvas.width = background.width;
        canvas.height = background.height;
        canvas
          .getContext('2d')
          .drawImage(background, 0, 0, background.width, background.height);
        this.setupContext(canvas.getContext('2d'));
      };

      this.captureEvents(canvas, context);
      this.question.canvasOptions.push(context);
      // save a copy of each original canvas for undo
      canvas.id = this.savedCanvases.length.toString();
      this.savedCanvases.push({
        id: canvas.id,
        unset: true,
        original: true,
      });

      return canvas;
    });
  }

  async getCanvasesFromPdf(pdfUrl: string): Promise<HTMLCanvasElement[]> {
    this.question.response = [];
    this.savedCanvases = [];
    this.question.canvasOptions = [];

    const width = this.pdfContainer.offsetWidth;
    const pdf = await PDFJS.getDocument(pdfUrl).promise;
    const canvases: HTMLCanvasElement[] = [];
    for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const canvas = document.createElement('canvas') as HTMLCanvasElement;
      const context = canvas.getContext('2d');
      this.pdfContainer.appendChild(canvas);

      // Render the page
      let viewport = page.getViewport({ scale: 1 });
      viewport = page.getViewport({ scale: width / viewport.width });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      page.render({
        canvasContext: context,
        viewport,
      });

      this.captureEvents(canvas, context);
      this.setupContext(context);
      this.question.canvasOptions.push(context);
      // save a copy of each original canvas for undo
      canvas.id = this.savedCanvases.length.toString();
      this.savedCanvases.push({
        id: canvas.id,
        unset: true,
        original: true,
      });
      canvases.push(canvas);
    }
    return canvases;
  }

  setFont(font: string): void {
    this.question.canvasOptions.forEach((options) => (options.font = font));
  }

  setDrawingColor(color: string): void {
    this.question.canvasOptions.forEach(
      (options) => (options.strokeStyle = color)
    );
    this.lineColor = color;
  }

  changeDrawingOrText(bool: boolean): void {
    this.drawingOrText = bool;
    if (bool === DrawingOrText.TEXT) {
      this.pdfContainer.style.cursor = 'copy';
      this.snackBar.open('Click/tap to create text field', null, {
        duration: 2000,
      });
    } else {
      this.pdfContainer.style.cursor = 'auto';
    }
  }

  // setup drawing defaults
  setupContext(context: CanvasRenderingContext2D): void {
    context.lineWidth = this.lineWidth;
    context.lineCap = 'round';
    context.strokeStyle = this.lineColor;
    context.font = this.font;
  }

  private captureEvents(
    canvasEl: HTMLCanvasElement,
    cx: CanvasRenderingContext2D
  ): void {
    // mouse
    observableFromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((_e) => {
          return observableFromEvent(canvasEl, 'mousemove').pipe(
            takeUntil(observableFromEvent(canvasEl, 'mouseup')),
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top,
        };
        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top,
        };
        this.checkIfFirstCanvasEdit(cx.canvas.id);
        this.drawOnCanvas(cx, prevPos, currentPos);
        this.saveCanvas(cx.canvas);
      });
    // touch display
    observableFromEvent(canvasEl, 'touchstart')
      .pipe(
        switchMap((_e) => {
          return observableFromEvent(canvasEl, 'touchmove').pipe(
            takeUntil(observableFromEvent(canvasEl, 'touchend')),
            pairwise()
          );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].touches[0].clientX - rect.left,
          y: res[0].touches[0].clientY - rect.top,
        };
        const currentPos = {
          x: res[1].touches[0].clientX - rect.left,
          y: res[1].touches[0].clientY - rect.top,
        };
        this.checkIfFirstCanvasEdit(cx.canvas.id);
        this.drawOnCanvas(cx, prevPos, currentPos);
        this.saveCanvas(cx.canvas);
      });
  } // end capture events

  clickCanvas(event: MouseEvent): void {
    const canvas = event.target as HTMLCanvasElement;
    // dont add text if drawing
    if (this.drawingOrText === DrawingOrText.DRAWING) {
      return;
    }
    const rect = canvas.getBoundingClientRect();
    const pos = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };
    const cx = canvas.getContext('2d');
    const dialogRef = this.dialog.open(PDFTextFieldDialogComponent);
    dialogRef.componentInstance.signature = this.question.signature
      ? true
      : false;
    dialogRef
      .afterClosed()
      .toPromise()
      .then((result) => {
        if (result === 'signature') {
          this.checkIfFirstCanvasEdit(cx.canvas.id);
          const img = new Image();
          img.crossOrigin = 'Anonymous';
          img.src = this.question.signature;
          img.onload = () => {
            cx.drawImage(img, pos.x - 105, pos.y - 30, 210, 60);
          };
          this.saveCanvas(cx.canvas);
        } else if (result) {
          this.checkIfFirstCanvasEdit(cx.canvas.id);
          cx.fillText(result, pos.x, pos.y + 8);
          this.saveCanvas(cx.canvas);
        }
      });
  } // end draw on canvas

  checkIfFirstCanvasEdit(id: string): void {
    if (this.savedCanvases[id].unset) {
      console.log('Saving original');
      this.savedCanvases[id].unset = false;
      this.savedCanvases[id].canvasImg = this.question.canvasOptions[
        id
      ].canvas.toDataURL();
    }
  }

  saveCanvas(canvas: HTMLCanvasElement): void {
    this.savedCanvases.push({
      id: canvas.id,
      unset: false,
      original: false,
      canvasImg: canvas.toDataURL(),
    });
  }

  undo(): void {
    // if all original canvases then stop
    if (this.savedCanvases[this.savedCanvases.length - 1].original) {
      console.log('No changes to undo!');
      return;
    }
    const canvasImg = this.savedCanvases.pop();
    // get id of canvas currently being undone
    const canvasID = canvasImg.id;
    let index = this.savedCanvases.length;
    // find previous version of that canvas
    while (this.savedCanvases[index - 1].id !== canvasID) {
      index -= 1;
    }
    const canvasContext = this.question.canvasOptions[canvasID];
    const canvasElement = canvasContext.canvas;
    // load the previous version of that canvas
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = this.savedCanvases[index - 1].canvasImg;
    img.onload = () => {
      canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
      canvasContext.drawImage(
        img,
        0,
        0,
        canvasElement.width,
        canvasElement.height,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );
    };
  }

  private drawOnCanvas(
    cx: CanvasRenderingContext2D,
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ): void {
    // dont draw if putting text
    if (this.drawingOrText === DrawingOrText.TEXT) {
      return;
    }
    // incase the context is not set
    if (!cx) {
      return;
    }
    // start our drawing path
    cx.beginPath();
    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      cx.moveTo(prevPos.x, prevPos.y); // from
      // draws a line from the start pos until the current position
      cx.lineTo(currentPos.x, currentPos.y);
      // strokes the current path with the styles we set earlier
      cx.stroke();
    }
  } // end draw on canvas
} // end component
