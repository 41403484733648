// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import firebase from 'firebase/compat/app';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { 
  ClinicNetworkSubscribed, 
  ClinicNetwork, 
  ClinicNetworkPricingPlan,
  ClinicNetworkPricingPlanSubscribed,
} from 'insig-types/network/index';
import { OldBookingFlowService, PublicClinicNetworkSubscription } from '../old-booking-flow/old-booking-flow.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  private validateNetworkPlansURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/validateNetworkPlans';
  private networkPricingPlanSubscribeURL = AWSCLOUDFUNCTIONSENDPOINT + 'networks/networkPricingPlanSubscribe';
  private createCustomerNetworkURL = AWSCLOUDFUNCTIONSENDPOINT + 'networks/createCustomerNetwork';

  constructor(
    private http: HttpClient,
    private oldBookingFlowService: OldBookingFlowService,
  ) { }

  async validateNetworkPlans(IDToken): Promise<any> {
    return this.http
      .post(
        this.validateNetworkPlansURL,
        {
          IDToken
        },
        { headers: this.headers, responseType: 'text' }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  } // end func

  async getSubscribedNetwork(networkID: string): Promise<ClinicNetworkSubscribed | false> {
    // Check if user already has a pending application or has already been approved
    const document = await firebase
      .firestore()
      .collection('clinicNetworksSubscribed')
      .doc(networkID)
      .get();
    if (document.exists) {
      const data = {
        ...await document.data(),
        id: document.id
      } as ClinicNetworkSubscribed;
      return data;
    } else {
      return false;
    }
  }

  async getPublicClinicNetworkSubscription(networkId: string): Promise<PublicClinicNetworkSubscription> {
    return this.oldBookingFlowService.getPublicClinicNetworkSubscriptionById(networkId);
  }

  async getSubscribedNetworkByCompanyID(companyID: string): Promise<ClinicNetworkSubscribed | false> {
    // Check if user already has a pending application or has already been approved
    const query = await firebase
      .firestore()
      .collection('clinicNetworksSubscribed')
      .where("companyID", "==", companyID)
      .get();

    if (!query.empty) {
      const data = {
         ...await query.docs[0].data(),
        id: query.docs[0].id
      } as ClinicNetworkSubscribed;
      return data;
    } else {
      return false;
    }
  }

  async getClinicNetworkByCompanyID(companyID: string): Promise<ClinicNetworkSubscribed | false> {
    // Check if user already has a pending application or has already been approved
    const query = await firebase
      .firestore()
      .collection('clinicNetworks')
      .where("companies", "array-contains-any", [companyID])
      .get();

    if (!query.empty) {
      const data = {
         ...await query.docs[0].data(),
        id: query.docs[0].id
      } as ClinicNetworkSubscribed;
      return data;
    } else {
      return false;
    }
  }


  async getClinicNetwork(networkID: string): Promise<ClinicNetwork | false> {
    // Check if user already has a pending application or has already been approved
    const document = await firebase
      .firestore()
      .collection('clinicNetworks')
      .doc(networkID)
      .get();
    if (document.exists) {
      const data = await document.data() as ClinicNetwork;
      return data;
    } else {
      return false;
    }
  }

  async getNetworkPricingPlansByNetworkID(networkID: string): Promise<ClinicNetworkPricingPlan[]> {
    // Check if user already has a pending application or has already been approved
    const query = await firebase
      .firestore()
      .collection('clinicNetworksPricingPlans')
      .where("networkID", "==", networkID)
      .get();
    if (!query.empty) {
      let plans = query.docs.map((doc)=>({
        ...doc.data(),
        id: doc.id
      })) as ClinicNetworkPricingPlan[];
      return plans;
    } else {
      return [];
    }
  }

  async getNetworkPricingPlansSubscribedByCompanyID(companyID: string): Promise<ClinicNetworkPricingPlanSubscribed[]> {
    // Check if user already has a pending application or has already been approved
    const query = await firebase
      .firestore()
      .collection('clinicNetworksPricingPlansSubscribed')
      .where("companyID", "==", companyID)
      .get();
    if (!query.empty) {
      let plans = query.docs.map((doc)=>({
        ...doc.data(),
        id: doc.id
      })) as ClinicNetworkPricingPlanSubscribed[];
      return plans;
    } else {
      return [];
    }
  }

  async getNetworkPricingPlansSubscribedByUserID(userID: string): Promise<ClinicNetworkPricingPlanSubscribed[]> {
    // Check if user already has a pending application or has already been approved
    const query = await firebase
      .firestore()
      .collection('clinicNetworksPricingPlansSubscribed')
      .where("userID", "==", userID)
      .get();
    if (!query.empty) {
      let plans = query.docs.map((doc)=>({
        ...doc.data(),
        id: doc.id
      })) as ClinicNetworkPricingPlanSubscribed[];
      return plans;
    } else {
      return [];
    }
  }

  networkPricingPlanSubscribe(IDToken:string, planID:string): Promise<any[]> {
    return this.http
      .post(
        this.networkPricingPlanSubscribeURL,
        JSON.stringify({
          IDToken,
          planID
        }),
        { 
          headers: this.headers, 
          responseType: 'text' 
        }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch(this.handleError);
  }

  createCustomerNetwork(
    IDToken: string,
    stripeToken: any,
    name: string,
    email: string
  ): Promise<any[]> {
    return this.http
      .post(
        this.createCustomerNetworkURL,
        JSON.stringify({
          IDToken,
          stripeToken: stripeToken.id,
          name,
          email
        }),
        { 
          headers: this.headers, 
          responseType: 'text' 
        }
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    // return Promise.reject(error.message || error);
    return Promise.reject();
  }

} // end service
