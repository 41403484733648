// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';

import * as LZString from 'lz-string';
import { NoteAndPatientResponse, NotePostBody, NotePostBodyWithPatientInfo, SpringNote } from 'insig-types/spring-api/notes';
import { take } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root',
})
// this loads the list of surveys
export class SubmitSurveyService {
  public static readonly MAXIMUM_AUTOMATIC_SURVEY_SUBMISSION_RETRIES = 5;

  constructor(
    private http: HttpClient,
  ) {}

  /**
   * Post a survey to the Java Spring API as an anonymous user.
   * @param companyId - The id of the company to submit the survey under.
   * @param survey - The survey data.
   * @returns The resulting note.
   */
  async postUnauthenticatedSurveyToSpring(companyId: string, survey: NotePostBodyWithPatientInfo): Promise<SpringNote> {
    const requestUrl = `${JAVA_BACKEND_ENDPOINT}company/${companyId}/unauthorized/createPatientAndNote`;

    try {
      if (!!survey.patientInfo?.uid && !!survey.patientUid) {
        if (survey.patientInfo.uid !== survey.patientUid) {
          throw new Error('patientInfo and patientUid UID mismatch when posting note');
        }
      }
      return await this.http.post<SpringNote>(requestUrl, survey).pipe(take(1)).toPromise();
    } catch (error) {
      await this.handlePostSurveyToSpringError(requestUrl, error, survey);
      throw error;
    }
  }

  /**
   * Post a survey to the Java Spring API.
   * @param companyId - The id of the company to submit the survey under.
   * @param survey - The survey data.
   * @param authenticated - Whether or not to use the authenticated endpoint.
   * @returns The resulting note and admitted patient data.
   */
  async postAuthenticatedSurveyToSpring(companyId: string, survey: NotePostBody): Promise<NoteAndPatientResponse> {
    const requestUrl = `${JAVA_BACKEND_ENDPOINT}company/${companyId}/createPatientAndNote`;

    try {
      if (!!survey.patientInfo?.uid && !!survey.patientUid) {
        if (survey.patientInfo.uid !== survey.patientUid) {
          throw new Error('patientInfo and patientUid UID mismatch when posting note');
        }
      }
      return await this.http.post<NoteAndPatientResponse>(requestUrl, survey).pipe(take(1)).toPromise();
    } catch (error) {
      await this.handlePostSurveyToSpringError(requestUrl, error, survey);
      throw error;
    }
  }

  private async handlePostSurveyToSpringError(requestUrl: string, error: HttpErrorResponse, survey: NotePostBody): Promise<void> {
    const timestamp = Date.now();
    const customContext = {
      requestUrl,
      companyId: survey.companyId ?? 'undefined',
      patientSid: survey.patientSid ?? 'undefined',
      patientUid: survey.patientUid ?? 'undefined',
      surveyId: survey.surveyId ?? 'undefined',
      type: survey.type ?? 'undefined',
      responseMessage: error.message ?? 'undefined',
      responseError: error.error ?? 'undefined',
      timestamp,
    };

    Sentry.captureException(error, {
      contexts: {
        createPatientAndNote: customContext,
      },
    });
  }

  /**
   * Post a note as a doctor to the Java Spring API.
   * @param companyId - The id of the company to submit the note under.
   * @param note - The note data.
   * @returns The posted note data - notably includes the note id assigned server-side.
   */
  async postNoteToSpring(companyId: string, note: NotePostBody): Promise<SpringNote> {
    if (!!note.patientInfo?.uid && !!note.patientUid) {
      if (note.patientInfo.uid !== note.patientUid) {
        throw new Error('patientInfo and patientUid UID mismatch when posting note');
      }
    }

    return await this.http.post<SpringNote>(`${JAVA_BACKEND_ENDPOINT}company/${companyId}/note/`, note)
      .pipe(take(1))
      .toPromise();
  }

  removeUnneededFields(survey): any {
    // clean up uneeded fields
    delete survey.PDFQuestionsLoaded;
    delete survey.PDFQuestions;
    return survey;
  }

  generatePatientID(patientInfo): string {
    if (!patientInfo) {
      return 'Error-no-patient-info';
    }

    if (patientInfo.gender === undefined) {
      throw new Error("Insufficient patientInfo to generate patient ID: gender is undefined");
    } else if (patientInfo.year === undefined) {
      throw new Error("Insufficient patientInfo to generate patient ID: year is undefined");
    } else if (patientInfo.month === undefined) {
      throw new Error("Insufficient patientInfo to generate patient ID: month is undefined");
    } else if (patientInfo.day === undefined) {
      throw new Error("Insufficient patientInfo to generate patient ID: day is undefined");
    }

    const genderValue = patientInfo.gender === 'Male' ? 0 : 1;
    const patientID =
      parseInt(patientInfo.year, 10) +
      '/' +
      parseInt(patientInfo.month, 10) +
      '/' +
      parseInt(patientInfo.day, 10) +
      '-' +
      patientInfo.phone?.replace(/\D/g, '') +
      '-' +
      genderValue;
    return LZString.compressToEncodedURIComponent(patientID);
  }

  setupTriggersDictionary(survey) {
    // setup triggering dictionary
    const triggersDictionary = (survey.triggersDictionary = {});
    survey.pages.forEach((page) => {
      if (!page.elements || !page.elements.length || page.elements.length < 1) {
        return false;
      }
      page.elements.forEach((element) => {
        if (element.pageFlow && element.pageFlow.parents) {
          triggersDictionary[element.id] = {};
          element.pageFlow.parents.forEach((parent) => {
            triggersDictionary[element.id][parent] = false;
          });
        }
        /////////////////////////////////////// Multiple scoring tags
        if (element.question && element.question.type === 'scoring') {
          element.originalSurvey = survey.name;
        }
      });
    }); // end survey pages loop
    return survey;
  }

  generateRandomID(length): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
} // end service
