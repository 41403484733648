import { environment } from './environment';

export interface AppConfig {
  brand: string;
  user: string;
  layoutBoxed: boolean;
  navCollapsed: boolean;
  navShadow: boolean;
  secondToolbar: boolean;
  secondToolbarType: boolean;
  fixedHeader: boolean;
  sidebarWidth: 'small' | 'middle' | 'large';
  theme: 'light' | 'gray' | 'dark';
  colorOption: '11' | '12' | '13' | '14' | '15' | '16' | '21' | '22' | '23' | '24' | '25' | '26' | '31' | '32' | '33' | '34' | '35' | '36';
  AutoCloseMobileNav: boolean;
}

export const enum InsigBookingTheme {
  DEFAULT,
  BC,
}

export const enum InsigBookingSite {
  TIA = 'tia',
  BC = 'bc',
}

function makeAppConfig(): AppConfig {
  const AppConfig = {
    brand: '',
    user: 'Tia',
    layoutBoxed: false, // true, false
    navCollapsed: true, // true, false
    navBehind: false, // true, false
    navShadow: true,
    secondToolbar: false,
    secondToolbarType: false,
    fixedHeader: true, // true, false
    sidebarWidth: 'middle', // small, middle, large
    theme: 'light', // light, gray, dark
    colorOption: '22', // 11,12,13,14,15,16; 21,22,23,24,25,26; 31,32,33,34,35,36
    AutoCloseMobileNav: true, // true, false. Automatically close sidenav on route change (Mobile only)
  } as const;

  return AppConfig;
}

export interface VirtualCareCategory {
  catID: number;
  data: {
    name: string;
  };
  standard: boolean;
  appts: VirtualCareService[];
}

export interface VirtualCareService {
  iden: string;
  label: string;
  price: string;
  duration: number;
  serviceCode: string;
  diagnosticCode: string;
}

function makeVirtualCareServices(): VirtualCareCategory[] {
  return [
    {
      catID: 1,
      data: { name: 'Symptom-Based' },
      standard: true,
      appts: [
        {
          iden: 'acneSymp',
          label: 'Acne Symptoms',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '706',
          // survey: {
          //   name: 'Acne (First Visit)', surveyID: 'qyW3GnJ6uNZXgMFRKMZz9ojKTLkWK0qV', userID: 'library'
          // }
        },
        {
          iden: 'allergySymptoms',
          label: 'Allergy Symptoms - Nose/Eyes',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '477',
          // survey: {
          //   name: 'Aeroallergen History', surveyID: '5tUkWjXzPTJEerf7yVr5yT0yWEfruqW9', userID: 'library'
          // }
        },
        {
          iden: 'asthmaSymptom',
          label: 'Asthma Flare Up',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '493',
          // survey: {
          //   name: 'Asthma', surveyID: 'bnm6Bqd1zmww9ezB13WjpVRNTrobwn8I', userID: 'library'
          // }
        },
        {
          iden: 'bites',
          label: 'Bug Bites, Impetigo, Ringworm',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '691',
        },
        {
          iden: 'cankers',
          label: 'Cankers in Mouth',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '528',
          // survey: {
          //   name: 'Cankers', surveyID: '5tUkWjXzPTJEerf7yVr5yT0yWEfruqW9', userID: 'library'
          // }
        },
        {
          iden: 'comeIn',
          label: 'Do I need to come in?',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '799',
        },
        {
          iden: 'coldSore',
          label: 'Cold Sore(s)',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '54',
        },
        {
          iden: 'coughingSymptom',
          label: 'Coughing',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '466',
          // survey: {
          //   name: 'Cough, Cold, Fever, Sore Throat', surveyID: 'AbI2V6nRL7vQN0spe9xf', userID: 'library'
          // }
        },
        {
          iden: 'earAche',
          label: 'Ear Ache',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '381',
        },
        {
          iden: 'eyeInfection',
          label: 'Eye infection, Pink Eye or Styes',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '372',
        },
        {
          iden: 'goutFlare',
          label: 'Gout Flare Up',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '274',
        },
        {
          iden: 'headLice',
          label: 'Head Lice',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '132',
        },
        {
          iden: 'hemorrhoids',
          label: 'Hemorrhoids',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '455',
        },
        {
          iden: 'herpesRecurrence',
          label: 'Herpes (genital) Recurrence',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '99',
        },
        {
          iden: 'poisonIvy',
          label: 'Poison Ivy',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '692',
        },
        {
          iden: 'rashSkin',
          label: 'Rash or Skin Condition',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '691',
          // survey: {
          //   name: 'Skin Rash', surveyID: '7O4ydq6nQsbZYbxUwcsg', userID: 'library'
          // }
        },
        {
          iden: 'shingles',
          label: 'Shingles',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '53',
        },
        {
          iden: 'sinusInfection',
          label: 'Sinus Infection',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '461',
        },
        {
          iden: 'soreThroat',
          label: 'Sore Throat',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '460',
          // survey: {
          //   name: 'Cough, Cold, Fever, Sore Throat', surveyID: 'AbI2V6nRL7vQN0spe9xf', userID: 'library'
          // }
        },
        {
          iden: 'stiSymptoms',
          label: 'STI Symptoms',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '99',
          // survey: {
          //   name: 'STI Screening', surveyID: 'o3yf1z0su2ytF2ctm55q', userID: 'library'
          // }
        },
        {
          iden: 'toenailInfection',
          label: 'Toenail or Fingernail Infection',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '681',
        },
        {
          iden: 'utiSymptoms',
          label: 'Urinary Tract Infection (UTI) Symptoms',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '595',
          // survey: {
          //   name: 'UTI Symptoms', surveyID: 'Ud5uZXnJbRSQLD3TUIzp', userID: 'library'
          // }
        },
      ],
    },
    {
      catID: 2,
      data: { name: 'Services' },
      standard: true,
      appts: [
        {
          iden: 'birthControl',
          label: 'Birth Control or Plan B',
          price: '30.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '895',
          // survey: {
          //   name: 'Female Birth Control Visit', surveyID: 'UFMU8SE9ztFIvupdPaiO', userID: 'library'
          // }
        },
        {
          iden: 'labResults',
          label: 'Review My Recent Lab Results',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '790',
        },
        {
          iden: 'prescriptionRenew',
          label: 'Prescription Renewal (no controlled substances)',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '799',
          // survey: {
          //   name: 'Prescription Renewal', surveyID: 'M4VPDNAnKzMbjeiwLnX4BIOGKspmf0jA', userID: 'library'
          // }
        },
        {
          iden: 'referralSpecialist',
          label: 'Referral to a Specialist',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '799',
        },
        {
          iden: 'referralPhysio',
          label: 'Referral for Physiotherapy/Massage',
          price: '30.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '781',
        },
        {
          iden: 'referralPsych',
          label: 'Referral to a Psychologist',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '300',
        },
        {
          iden: 'requisition',
          label: 'Requisition for Blood Test, X-Ray, Ultrasound',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '799',
        },
        {
          iden: 'sickNote',
          label: 'Sick Note',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '799',
          // survey: {
          //   name: 'Medical Note Request', surveyID: 'UyDl54iedpCEnDX9d9dE', userID: 'library'
          // }
        },
        {
          iden: 'stiTesting',
          label: 'Routine STI testing',
          price: '40.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '99',
          // survey: {
          //   name: 'STI Screening', surveyID: 'o3yf1z0su2ytF2ctm55q', userID: 'library'
          // }
        },
        {
          iden: 'workplaceInjury',
          label: 'WSIB Form 8 (Initial Report) for a Minor Workplace Injury',
          price: '50.00',
          duration: 5,
          serviceCode: 'A007',
          diagnosticCode: '781',
          // survey: {
          //   name: 'WSIB Form 8', surveyID: 'WyoSLcujyEIqw4H8JHGp', userID: 'library'
          // }
        },
      ],
    },
    {
      catID: 3,
      data: { name: 'Ongoing Conditions' },
      standard: true,
      appts:
        // dave
        [
          {
            iden: 'acidReflux',
            label: 'Acid Reflux',
            price: '50.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '787',
          },
          {
            iden: 'acneOngoing',
            label: 'Acne Ongoing',
            price: '40.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '706',
            // survey: {
            //   name: 'Acne (Follow Up Visit)', surveyID: 'R6zws6TxFOxTfI23NVt86n3kuPT7cPXk', userID: 'library'
            // }
          },
          {
            iden: 'asthmaOngoing',
            label: 'Asthma/COPD/Emphysema Ongoing',
            price: '50.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '493',
            // survey: {
            //   name: 'COPD', surveyID: '92CKZx4EOG2Z6qJwb9hEZI70YqU6TXkH', userID: 'library'
            // }
          },

          // {
          // iden: 'chfOngoing', label: 'CHF (Congestive Heart Failure)', price: '60.00', duration: '5', serviceCode: 'A007', diagnosticCode: 785,
          // survey: {
          //   name: 'CHF (Congestive Heart Failure)', surveyID: 'tDijsKQGyWsSS0jRLmAJDS0L2gZfktgY', userID: 'library'
          // }
          // },
          {
            iden: 'cholesterolOngoing',
            label: 'Cholesterol Visit',
            price: '50.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '401',
            // survey: {
            //   name: 'Cholesterol Visit', surveyID: 'uHFVHOpGotSK4WEv2x0AWRwmd2lxCv3H', userID: 'library'
            // }
          },
          {
            iden: 'diabetesOngoing',
            label: 'Diabetes',
            price: '50.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '250',
            // survey: {
            //   name: 'Diabetes Visit', surveyID: 'a0f65LVVkvKl0wfMpVT37gMVLXwN3h0N', userID: 'library'
            // }
          },
          {
            iden: 'hypertensionOngoing',
            label: 'Hypertension (High Blood Pressure)',
            price: '50.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '401',
            // survey: {
            //   name: 'Hypertension Visit (Follow Up)', surveyID: 'JrzmqOOfH3Bg6DSB8eN4jfQDIMYkd7vf', userID: 'library'
            // }
          },
          {
            iden: 'quesOnCond',
            label: 'Questions about my ongoing condition',
            price: '50.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '799',
          },
          {
            iden: 'skinIssue',
            label: 'Skin Issues',
            price: '50.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '691',
            // survey: {
            //   name: 'Skin Rash', surveyID: '7O4ydq6nQsbZYbxUwcsg', userID: 'library'
            // }
          },
          {
            iden: 'weightLoss',
            label: 'Weight Loss Monitoring',
            price: '40.00',
            duration: 5,
            serviceCode: 'A007',
            diagnosticCode: '278',
          },
        ],
    },
  ];
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export function firebaseINIT(): { production: boolean, firebase: FirebaseConfig } {
  return {
    production: environment.production!,
    firebase: environment.firebaseConfig as unknown as FirebaseConfig,
  };
}

function cloudFunctionsEndpoint(): string {
  return environment.cloudFunctionsEndpoint!;
}

function isUsingBetaSite(): boolean {
  return window.location.origin.includes('beta1.insighealth.com') ||
      window.location.origin.includes('beta.tiahealth.com') ||
      window.location.origin.includes('book-beta.insighealth.com') ||
      window.location.origin.includes('book-beta.tiahealth.com');
}

function AWSCloudFunctionsEndpoint(): string {
  const map = environment.awsCloudFunctionsEndpoint.map ?? [];
  const { awsCloudFunctionsEndpoint } = map.find(({ origin }) => {
    return window.location.origin.includes(origin);
  }) ?? { awsCloudFunctionsEndpoint: undefined };
  return awsCloudFunctionsEndpoint ?? environment.awsCloudFunctionsEndpoint.default!;
}

function AppURL(): string {
  const map = environment.appUrl.map ?? [];
  const { appUrl } = map.find(({ origin }) => {
    return window.location.origin.includes(origin);
  }) ?? { appUrl: undefined };
  return appUrl ?? environment.appUrl.default!;
}

function javaBackendEndpoint(): string {
  const map = environment.javaBackendEndpoint.map ?? [];
  const { javaBackendEndpoint } = map.find(({ origin }) => {
    return window.location.origin.includes(origin);
  }) ?? { javaBackendEndpoint: undefined };
  return javaBackendEndpoint ?? environment.javaBackendEndpoint.default ?? 'https://mock.java.endpoint/';
}

function javaBackendEndpointMirror(): string {
  const map = environment.javaBackendEndpoint.map ?? [];
  const { javaBackendEndpoint } = map.find(({ origin }) => {
    return window.location.origin.includes(origin);
  }) ?? { javaBackendEndpoint: undefined };

  let javaBackendEndpointMirror: string | undefined;
  if (javaBackendEndpoint !== undefined) {
    javaBackendEndpointMirror = environment.javaBackendEndpoint.default;
  } else if (map?.[0]) {
    javaBackendEndpointMirror = (map[0] as unknown as { javaBackendEndpoint: string }).javaBackendEndpoint;
  }
  
  return javaBackendEndpointMirror ?? 'https://mock.java-mirror.endpoint/';
}

function getNewBookingFlowUrl(): string {
  const map = environment.newBookingFlowUrl.map ?? [];
  const { newBookingFlowUrl } = map.find(({ origin }) => {
    return window.location.origin.includes(origin);
  }) ?? { newBookingFlowUrl: undefined };
  return newBookingFlowUrl ?? environment.newBookingFlowUrl.default!;
}

function getStripeClientId(): string {
  return environment.stripeClientId!;
}

function getInsigBookingTheme(): InsigBookingTheme {
  const map = environment.insigBookingTheme.map ?? [];
  const { theme } = map.find(({ origin }) => {
    return window.location.origin.includes(origin);
  }) ?? { theme: undefined };
  return theme ?? InsigBookingTheme.DEFAULT;
}

function getInsigBookingSite(): InsigBookingSite {
  const map = environment.insigBookingSite.map ?? [];
  const { site } = map.find(({ origin }) => {
    return window.location.origin.includes(origin);
  }) ?? { site: undefined };
  return site ?? environment.insigBookingSite.default!;
}

export const DEFAULT_THEME = {
  'primary-color': '#009fdf',
  'secondary-color': '#FFFFFF',
  'text-color': '#000105',
  'text-selected': '#fff',
  'text-secondary': '#a1a1a1',
};

export const PRODUCTION = !!environment.production;
export const INACTIVITY_TIMEOUT_MS = environment.inactivityTimeoutMs ?? 0;
export const IS_USING_BETA_SITE = isUsingBetaSite();
export const BRANCH = environment.branch;
export const STRIPE_KEY = environment.stripeKey;
export const APPCONFIG = makeAppConfig();
export const VIRTUALCARECONFIG = makeVirtualCareServices();
export const FIREBASEINITFUNCTION = firebaseINIT();
export const APPURL = AppURL();

export const CLOUDFUNCTIONSENDPOINT = cloudFunctionsEndpoint();
export const AWSCLOUDFUNCTIONSENDPOINT = AWSCloudFunctionsEndpoint();

export const JAVA_BACKEND_ENDPOINT = javaBackendEndpoint();
export const JAVA_BACKEND_ENDPOINT_MIRROR = javaBackendEndpointMirror();
export const NEW_BOOKING_FLOW_URL = getNewBookingFlowUrl();
export const STRIPE_CLIENT_ID = getStripeClientId();
export const AWS_WAF_CAPTCHA_API_KEY = environment.awsWafCaptchaApiKey;
export const AWS_WAF_CAPTCHA_SCRIPT_URL = environment.awsWafCaptchaScriptUrl;
export const INSIG_BOOKING_THEME = getInsigBookingTheme();
export const INSIG_BOOKING_SITE = getInsigBookingSite();
export const TALI_AI_SCRIPT_URL = environment.taliAiScriptUrl;

export const SNACK_BAR_AUTO_DISMISS_MILLISECONDS = 10 * 1000;

export const PDF_BASE_PATH = environment.pdfBasePath;

export interface GraphqlResponse<T = unknown> {
  data: { [key: string]: T };
}

export interface LambdaResponse {
  body: unknown;
}
