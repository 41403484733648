import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'select-doctor-dialog',
  templateUrl: './select-doctor-dialog.component.html',
})
export class SelectDoctorDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SelectDoctorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { doctorList: Array<{ fullName: string }> }
  ) {}
}
