// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';

@Injectable({
  providedIn: 'root',
})
export class MailchimpService {
  private signupMailchimpURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'communications/signupMailchimp';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private mailchimpAllInsigAccounts = '6a070fe142';
  private mailchimpTiaPromo = 'a1134e84aa';
  private mailchimpTia = '1f1ec0fce3';
  public mailchimpInsigPatients = '617143129c';
  private mailchimpNewCompany = 'dbed44f83b';

  constructor(private http: HttpClient) {}

  mailChimpSubscribe(signUpForm, tia?) {
    try {
      // all insig accounts (just Virtual Care)
      this.subscribeMailChimp(this.mailchimpAllInsigAccounts, signUpForm);
      // all insig patients (opt-in, virtual care, accounts, clinic check-in, etc.)
      this.subscribeMailChimp(this.mailchimpInsigPatients, signUpForm);

      // all tia accounts
      if( !window.location.origin.includes('app.well.company')
        &&  !window.location.origin.includes('app.wellclinics.ca')
        &&  !window.location.origin.includes('app.jacknathanhealth.com')
        &&  !window.location.origin.includes('virtual.highmark.tech')
        &&  !window.location.origin.includes('app.thevirtualdoctor.org')
        && tia) {
          this.subscribeMailChimp(this.mailchimpTia, signUpForm);
          // tia promo accounts
          if (signUpForm.controls.promo.value === true) {
            this.subscribeMailChimp(this.mailchimpTiaPromo, signUpForm);
          }
      }

    } catch (error) {
      console.log(error);
    }
  }

  subscribeNewCompany(newUser) {
    const userData = {
      email_address: newUser.email,
      status: 'subscribed',
      merge_fields: {
        FNAME: newUser.first,
        LNAME: newUser.last,
        ADDRESS: newUser.address,
        PHONE: newUser.phone,
      },
    };
    return this.http
      .post(
        this.signupMailchimpURL,
        {
          userData,
          listID: this.mailchimpNewCompany,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  } // end func

  subscribeMailChimpUserData(listID, userDataRaw): any {
    const userData = {
      email_address: userDataRaw.email,
      status: 'subscribed',
      merge_fields: {
        FNAME: userDataRaw.first,
        LNAME: userDataRaw.last,
        ADDRESS: userDataRaw.address,
        PHONE: userDataRaw.phone,
      },
    };
    return this.http
      .post(
        this.signupMailchimpURL,
        {
          userData,
          listID,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  } // end sendEmail func

  subscribeMailChimp(listID, signUpForm): any {
    const userData = {
      email_address: signUpForm.controls.email.value,
      status: 'subscribed',
      merge_fields: {
        FNAME: signUpForm.controls.first.value,
        LNAME: signUpForm.controls.last.value,
        ADDRESS: signUpForm.controls.address.value,
        PHONE: signUpForm.controls.phone.value,
      },
    };
    return this.http
      .post(
        this.signupMailchimpURL,
        {
          userData,
          listID,
        },
        { headers: this.headers }
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  } // end sendEmail func
} // end service1
