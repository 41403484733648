import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { UserData } from 'insig-types/user-data';
import firebase from 'firebase/compat/app';
import { catchError, EMPTY, firstValueFrom, lastValueFrom, map, Observable, shareReplay, switchMap, take } from 'rxjs';
import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

@Injectable({
  providedIn: 'root',
})
export class FirebaseAuthService {
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private checkIfUserExistsByEmailURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'users/checkIfUserExistsByEmail';
  private sendPasswordResetEmailUrl = `${AWSCLOUDFUNCTIONSENDPOINT}communications/sendPasswordResetEmail`;

  // graphql queries
  private userDataQuery = gql`
    query User($userID: ID!) {
      getUserData(uid: $userID) {
        uid
      }
    }
  `;

  private insigSuperAdminUserDataQuery = gql`
    query User($userID: ID!, $idToken: ID) {
      getUserData(uid: $userID, token: $idToken) {
        type {
          insigSuperAdmin
        }
      }
    }
  `;

  private _idTokenObservable = new Observable<firebase.User>(
    (observer) => {
      return firebase.auth().onIdTokenChanged((user) => {
        if (user) {
          user.getIdToken().then((token) => console.log(token));
        }
        observer.next(user as firebase.User);
      });
    },
  ).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  public idToken$ = this._idTokenObservable.pipe(
    switchMap((firebaseUser) => {
      if (firebaseUser) {
        return firebaseUser.getIdToken();
      } else {
        return Promise.resolve<undefined>(undefined);
      }
    }),
  );

  constructor(
    private apollo: Apollo,
    private router: Router,
    private http: HttpClient,
    private gcpIpAuthService: GcpIpAuthService,
  ) {
  }

  async checkIfUserIsDoctor(user: firebase.User): Promise<boolean> {
    if (!user) {
      return false;
    }

    const userDataQuery$ = this.apollo
      .query<{ getUserData: UserData }>({
        query: this.userDataQuery,
        variables: {
          userID: user.uid,
        },
      });
    const userDataQuery = await lastValueFrom(userDataQuery$);
    return !!userDataQuery.data.getUserData;
  }

  private isUserInsigSuperAdminCache = new Map<string, boolean>();
  async isUserInsigSuperAdmin(user: firebase.User): Promise<boolean> {
    const uid = user.uid;

    if (!this.isUserInsigSuperAdminCache.has(uid)) {
      const userDataQuery$ = this.apollo
        .query<{ getUserData: UserData }>({
          query: this.insigSuperAdminUserDataQuery,
          variables: {
            userID: user.uid,
            idToken: await user.getIdToken(),
          },
        });
      const userDataQuery = await lastValueFrom(userDataQuery$);
      this.isUserInsigSuperAdminCache.set(uid, userDataQuery.data.getUserData.type?.insigSuperAdmin ?? false);
    }

    return this.isUserInsigSuperAdminCache.get(uid) ?? false;
  }

  getIdToken(): Promise<string | undefined> {
    return lastValueFrom(this.idToken$.pipe(take(1)));
  }

  /**
   * A shared observable exposing changes to firebase onIdTokenChanged()
   * @return shared observable for firebase onIdTokenChanged()
   */
  onIdTokenChanged(): Observable<firebase.User> {
    return this._idTokenObservable;
  }

  async getLogInStatus(): Promise<boolean> {
    return !!(await this.onIdTokenChanged()
      .pipe(take(1))
      .toPromise());
  }

  async signInWithCustomToken(firebaseCustomToken: string): Promise<void> {
    await firebase.auth().signInWithCustomToken(firebaseCustomToken);
  }

  getFirebaseCurrentUser(): firebase.User | null {
    if ((firebase.apps ?? []).length !== 0) {
      return firebase.auth().currentUser;
    } else {
      return null;
    }
  }

  async signOutThenReturnToLoginPage(): Promise<void> {
    await this.router.navigate(['/auth/deactivate-guard'], { skipLocationChange: true }); // Trigger deactivate guards for auto-save, etc.
    await this.gcpIpAuthService.signOut();
    await this.router.navigate(['/auth/login']);
  }

  pdfDocumentAuth(
    url: string | { changingThisBreaksApplicationSecurity: string },
    authScheme?: string,
    authToken?: string,
  ): Observable<SafeResourceUrl> {
    if (!url) {
      console.warn('url is void');
      return EMPTY;
    }
    const urlString: string =
      typeof url !== 'string' ? url.changingThisBreaksApplicationSecurity : url; // Convert SafeResourceUrlImpl to string, if necessary
    if (!urlString) {
      console.warn('url is void');
      return EMPTY;
    }
    const headers =
      !!authScheme && !!authToken
        ? { Authorization: `${authScheme} ${authToken}` }
        : undefined; // Construct Authorization headers
    return this.http
      .get(urlString, {
        headers,
        observe: 'response',
        responseType: 'arraybuffer',
      })
      .pipe(
        catchError((error) => {
          return this.http.get(error.url, {
            observe: 'response',
            responseType: 'arraybuffer',
          });
        }),
        map((res: any) => {
          return URL.createObjectURL(
            new Blob([new Uint8Array(res.body as ArrayBufferLike)], {
              type: res.headers.get('Content-Type') ?? undefined,
            }),
          );
        }),
        catchError((error) => {
          const enc = new TextDecoder('utf-8');
          if (error.error) {
            error.error = enc.decode(error.error);
          }
          console.error('URL ERROR', error);
          return EMPTY;
        }),
      );
  }

  async checkIfUserExistsByEmail(email: string): Promise<boolean> {
    const response$ = this.http
      .post<boolean>(this.checkIfUserExistsByEmailURL, email, {
        headers: this.headers,
      });
    return lastValueFrom(response$);
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    return firstValueFrom(this.http.post<void>(this.sendPasswordResetEmailUrl, { email }).pipe(take(1)));
  }
}
