// @ts-strict-ignore
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'number-question',
  templateUrl: './number.component.html',
})
export class NumberComponent implements OnChanges, OnInit {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  numberForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildForm();
    if (!this.question.response.answer) {
      this.question.response.answer = null;
    } else {
      this.numberForm.controls['number'].setValue(
        this.question.response.answer
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  buildForm() {
    this.numberForm = this.formBuilder.group({
      number: this.formBuilder.control(null, [
        Validators.min(this.question.min),
        Validators.max(this.question.max),
      ]),
    });

    this.numberForm.valueChanges.subscribe((data) => {
      this.question.response.answer = data.number;
    });
  }
} // end component
