// @ts-strict-ignore
import { Component, Input, ViewChild, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { MedicationsListService } from '../../../services/medications-list.service';

import { map, startWith, take } from 'rxjs/operators';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'medications-question',
  templateUrl: './medications.component.html',
  providers: [MedicationsListService],
})
export class MedicationsComponent implements OnChanges, OnInit {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  @ViewChild('itemsSelect') itemsSelect;
  itemsCtrl: UntypedFormControl;
  filteredItems: any;
  items = [];

  constructor(private medicationsListService: MedicationsListService) {
    this.itemsCtrl = new UntypedFormControl();
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      map((name) => this.filterItems(name))
    );
  }

  async ngOnInit() {
    if (!this.question.response.selectedAnswers) {
      this.question.response.selectedAnswers = [];
    }
    try {
      this.items = await this.medicationsListService
        .getMedications()
        .pipe(take(1))
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  handleNoMedicationsButtonClicked(isNoMedicationsSelected: boolean): void {
    if (isNoMedicationsSelected) {
      this.itemsCtrl.disable();
      this.itemsCtrl.setValue(null);
    } else {
      this.itemsCtrl.enable();
    }
  }

  filterItems(val: string) {
    return val
      ? this.items
          .filter((s) => s.toLowerCase().indexOf(val.toLowerCase()) === 0)
          .slice(0, 50)
      : this.items.slice(0, 50);
  }

  removeItem(val) {
    const index = this.question.response.selectedAnswers.indexOf(val);
    this.question.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.question.allowMultiple) {
      if (!this.question.response.selectedAnswers.includes(val)) {
        this.question.response.selectedAnswers.push(val);
      }
      this.itemsCtrl.setValue(null);
    }
    // this.itemsSelect.nativeElement.blur();
  } // end add item
} // end component
