// @ts-strict-ignore
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ReferralNetworkService } from '@insig-health/services/referrals/referral-network.service';

import { MAPSTYLE } from './map.style';

import { GoogleMapService } from 'insig-app/services/google/map.service';

@Component({
  selector: 'insig-referral-patient',
  templateUrl: './insig-referral-patient.component.html',
  providers: [ReferralNetworkService, GoogleMapService],
  styleUrls: ['./insig-referral-patient.component.scss'],
})
export class InsigReferralPatientComponent implements OnInit, OnDestroy, OnChanges {
  @Input() geolocatePharmacy = null;
  @Input() pharmacySelect = false;
  @Input() referralType: string = null;
  @Input() patientInfo: any;
  @Input() editMode: any = false;
  @Input() patientAddress: any;
  @Input() doctorMode: any = false;
  @Output() referralSent = new EventEmitter();

  public referralMessage = '';
  // referralOptions
  private _referralOptions: any = [];
  @Output() referralOptionsChange = new EventEmitter();
  set referralOptions(val) {
    this._referralOptions = val;
    this.referralOptionsChange.emit(this._referralOptions);
  }

  @Input() get referralOptions(): any {
    // this.parseVirtualArray()
    return this._referralOptions;
  }

  public referralTypes = {
    physio: 'Physio/Chiro/Massage Therapy',
  }; // end obj
  private referralOptionsSubscription;

  public mapSettings = {
    lat: 43.6532,
    lng: -79.3832,
    zoom: 13,
    mapStyle: MAPSTYLE,
  };

  @ViewChild('searchAddress')
  public addressElementRef: ElementRef;

  public referralSelected: any = null;
  public referralConfirmed = false;

  constructor(
    private referralNetworkService: ReferralNetworkService,
    private googleMapService: GoogleMapService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  clearPatientAddress(): void {
    this.patientAddress = null;
  }

  sortListByAddress(): any {
    console.log(this.patientAddress);

    let tempReferralOptions;

    const patientLat = this.patientAddress.lat;
    const patientLng = this.patientAddress.lng;
    tempReferralOptions = this.referralOptions.map((option: any) => {
      const optionLat = parseFloat(option.lat);
      const optionLng = parseFloat(option.lng);
      const radlat1 = (Math.PI * patientLat) / 180;
      const radlat2 = (Math.PI * optionLat) / 180;
      const theta = patientLng - optionLng;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      dist = parseFloat((dist * 1.609344).toFixed(1));

      return { ...option, dist };
    });

    tempReferralOptions = tempReferralOptions.filter((referral) => {
      if (referral.dist > 6) {
        return false;
      }
      return true;
    });

    tempReferralOptions = tempReferralOptions.sort((a, b) => {
      //favor locations with a logo (paying)
      if (a.logo && !b.logo) {
        return a.dist - b.dist - 1;
      } else if (b.logo && !a.logo) {
        return a.dist - b.dist + 1;
      }
      return a.dist - b.dist;
    });

    this.referralOptions = tempReferralOptions.slice(0, 15);

    // this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    console.log('sorted array', this.patientAddress, this.referralOptions);
  }

  async attemptToSetPatientAddressEntered(address): Promise<void> {
    try {
      const results: any = await this.googleMapService.geocodeAddress(address);
      console.log(results);
      if (results && results.length > 0) {
        const result = results[0];
        if (!result.geometry) return;
        const lat = result.geometry.location.lat;
        const lng = result.geometry.location.lng;
        const address = result.formatted_address;
        this.patientAddress = {
          lat,
          lng,
          address,
        };
        this.updateAddresses();
      }
    } catch (err) {
      console.log(err);
    }
  }

  async attemptToSetPatientAddress(patientInfo): Promise<void> {
    console.log(patientInfo);
    const address =
      patientInfo.address +
      (patientInfo.city ? ', ' + patientInfo.city : '') +
      (patientInfo.province ? ', ' + patientInfo.province : '') +
      (patientInfo.postalCode ? ', ' + patientInfo.postalCode : '');
    try {
      const results: any = await this.googleMapService.geocodeAddress(address);
      console.log(results);
      if (results && results.length > 0) {
        const result = results[0];
        if (!result.geometry) return;
        const lat = result.geometry.location.lat;
        const lng = result.geometry.location.lng;
        const address = result.formatted_address;
        this.patientAddress = {
          lat,
          lng,
          address,
        };
        this.updateAddresses();
      }
    } catch (err) {
      console.log(err);
    }
  }

  ngOnInit(): void {
    console.log('patient\'s address: ', this.patientAddress);
    console.log('Referral type: ', this.referralType);
    console.log(this.geolocatePharmacy);
  } // end func

  loadReferralOptions(): void {
    // if referred by a specific pharmacy, but don't affect doctor
    if (this.geolocatePharmacy && !this.doctorMode) {
      this.referralOptionsSubscription = this.referralNetworkService
        .loadReferralNetworkByPharmacyBanner(this.geolocatePharmacy)
        .subscribe(async (referralOptions) => {
          console.log('** referral options: ', referralOptions);
          this.referralOptions = referralOptions;
          // this.updateAddresses();
          this.sortListByAddress();
        });
    } else if (this.patientAddress.lng) {
      this.referralOptionsSubscription = this.referralNetworkService
        .loadReferralNetworkByTypeDistance(
          this.referralType,
          this.patientAddress.lng,
        )
        .subscribe(async (referralOptions) => {
          console.log('* referral options: ', referralOptions);
          this.referralOptions = referralOptions;
          // this.updateAddresses();
          this.sortListByAddress();
        });
    } // end if else
  }

  updateAddresses(): void {
    if (this.patientAddress) {
      console.log('patient address: ', this.patientAddress);
      if (this.patientAddress.lat && this.patientAddress.lng) {
        this.mapSettings = {
          ...this.mapSettings,
          lat: this.patientAddress.lat,
          lng: this.patientAddress.lng,
        };
      } else if (this.patientAddress.address) {
        this.attemptToSetPatientAddressEntered(this.patientAddress.address);
      }
      this.loadReferralOptions();
      this.sortListByAddress();
    }
  } // end func

  ngOnChanges(changes): void {
    console.log('changes: ', changes);
    // if they tpye in an address but don't click google automcplete
    if (
      this.patientAddress &&
      this.patientAddress.address &&
      (!this.patientAddress.lat || !this.patientAddress.lng)
    ) {
      this.attemptToSetPatientAddressEntered(this.patientAddress.address);
    }
    // if they click google autocomplete
    if (changes.patientAddress) {
      this.updateAddresses();
    }
    // if we feed in a patient address
    if (changes.patientInfo) {
      if (!!this.patientInfo && !!this.patientInfo.address) {
        this.attemptToSetPatientAddress(this.patientInfo);
      }
    } //end if
  } //end func

  blankReferralClicked(): void {
    this.referralSent.emit(undefined);
  }

  confirmReferral(): void {
    this.referralConfirmed = true;
    const referralOption = this.referralType;

    if (
      referralOption &&
      this.patientInfo &&
      !this.editMode &&
      !this.doctorMode &&
      //pharmacy still needs to be faxed
      this.referralSelected.type !== 'pharmacy' &&
      this.referralSelected.type !== 'wellCovid'
    ) {
      this.referralNetworkService.sendReferral(
        this.referralSelected,
        this.patientInfo,
        false,
        'Patient self-referred',
        true,
      );
      this.referralSent.emit();
    } else if (
      !this.doctorMode &&
      //pharmacy still needs to be faxed
      this.referralSelected.type === 'pharmacy'
    ) {
      this.referralSent.emit(this.referralSelected);
    } else if (this.doctorMode) {
      this.referralSent.emit(this.referralSelected);
    } //end if-else
  } // end func

  ngOnDestroy(): void {
    if (this.referralOptionsSubscription) {
      this.referralOptionsSubscription.unsubscribe();
    }
  }
}
