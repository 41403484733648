// @ts-strict-ignore
import { Injectable } from '@angular/core';

import { Apollo, gql } from 'apollo-angular';

import firebase from 'firebase/compat/app';

import { firstValueFrom, Observable, ReplaySubject, switchMap, timer } from 'rxjs';

import { addMinutes } from 'date-fns';
import {
  MILLISECONDS_PER_DAY,
  MILLISECONDS_PER_MINUTE,
  MINUTES_PER_HOUR,
} from './doctorSettings.service.utilities';

import { DoctorScheduleReindexService } from '@insig-health/services/doctor-schedule-reindex/doctor-schedule-reindex.service';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { DoctorSettingsService as DoctorSettingsApiService, LegacyDoctorScheduleException, TimeSegment as ServerTimeSegment } from '@insig-health/api/doctor-api';

export type DayOfTheWeek =
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday';
export type Availability = 'virtual' | 'clinic' | 'both';
export type TimeIncrement = string;

interface BaseWeeklySchedule {
  defaultAvailability: Availability;
}

export type WeeklySchedule = BaseWeeklySchedule & {
  sunday?: WeekdaySchedule;
  monday?: WeekdaySchedule;
  tuesday?: WeekdaySchedule;
  wednesday?: WeekdaySchedule;
  thursday?: WeekdaySchedule;
  friday?: WeekdaySchedule;
  saturday?: WeekdaySchedule;
}

export type LocalWeeklySchedule = BaseWeeklySchedule & {
  sunday?: LocalWeekdaySchedule;
  monday?: LocalWeekdaySchedule;
  tuesday?: LocalWeekdaySchedule;
  wednesday?: LocalWeekdaySchedule;
  thursday?: LocalWeekdaySchedule;
  friday?: LocalWeekdaySchedule;
  saturday?: LocalWeekdaySchedule;
}

interface BaseWeekdaySchedule {
  operatingTimeZone: string;
  timeIncrement: TimeIncrement;
}

export type WeekdaySchedule = BaseWeekdaySchedule & {
  timeSegments: TimeSegment[];
}

export type LocalWeekdaySchedule = BaseWeekdaySchedule & {
  timeSegments: LocalTimeSegment[];
}

interface BaseExceptionSchedule {
  day: number; // @deprecated number of milliseconds since epoch day
  epochDay: number; // number of days since epoch day
  doctorCompany: string;
  doctorID: string;
  exception?: boolean; // Whether the exception is active or not
};

export type ExceptionSchedule = WeekdaySchedule & BaseExceptionSchedule;

export type LocalExceptionSchedule = LocalWeekdaySchedule & BaseExceptionSchedule;
export interface TimeSegment {
  availability: Availability | 'default';
  location: string; // LocationID
  operatingStartTime: number;
  operatingEndTime: number;
  startTime?: number; // deprecated
  endTime?: number; //deprecated
}

export type LocalTimeSegment = TimeSegment & {
  localStartTime: number;
  localEndTime: number;
}

export interface EMRSchedule {
  [time: string]: {
    [key: string]: any;
  };
}

export interface EMRScheduleConverted {
  start: number;
  end: number;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class DoctorSettingsService {
  // this query gets a little more info than basid uid and company on a user
  private mediumUserDataQuery = gql`
    query UserDataQuery($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        company
        email
        acceptVirtual
        first
        last
        title
        uid
        type {
          admin
        }
        defaultVirtualSurvey {
          name
          surveyID
          userID
        }
      }
    }
  `;

  // this query gets a little more info than basid uid and company on a user
  getMediumDoctorUserData(user: firebase.User, idToken: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const userDataQuery: any = await this.apollo
          .query({
            query: this.mediumUserDataQuery,
            variables: {
              userID: user.uid,
              token: idToken,
            },
          })
          .toPromise();
        const userData = userDataQuery.data.getUserData;
        if (userData) {
          resolve(userData);
        } else {
          resolve(null);
        }
      } catch (error) {
        switch (error.code) {
          default:
            console.error(error);
            break;
          case 'PERMISSION_DENIED':
            console.error(
              'Permission Denied: Wrong uid. User may have logged out.'
            );
            break;
        }
        reject(error);
      }
    });
  }

  constructor(
    private apollo: Apollo,
    private doctorScheduleReindexService: DoctorScheduleReindexService,
    private dateAndTimeService: DateAndTimeService,
    private doctorSettingsApiService: DoctorSettingsApiService,
  ) { }

  /**
   * Names of the days of the week from 'sunday' to 'saturday'
   */
  private readonly _daysOfTheWeek: DayOfTheWeek[] = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];
  public get daysOfTheWeek(): DayOfTheWeek[] {
    return this._daysOfTheWeek;
  }

  public get dayOfTheWeek(): DayOfTheWeek {
    return this._daysOfTheWeek[new Date().getDay()];
  }

  getDayOfWeek(date) {
    const index = date.getDay();
    if (index === 0) {
      return 'sunday';
    } else if (index === 1) {
      return 'monday';
    } else if (index === 2) {
      return 'tuesday';
    } else if (index === 3) {
      return 'wednesday';
    } else if (index === 4) {
      return 'thursday';
    } else if (index === 5) {
      return 'friday';
    } else {
      return 'saturday';
    }
  }

  getWeeklyScheduleByCompany(
    companyId: string,
  ): Observable<WeeklySchedule | null> {
    return Observable.create((observer) => {
      firebase.database().ref(`doctorSettings/${companyId}`).on('value', (snapshot) => {
        if (snapshot !== undefined) {
          observer.next(snapshot.val());
        }
      });
    });
  }

  /**
   * Converts a server side time to a local time zone
   * @param  serverTime        HH:MM string in the operatingTimeZone
   * @param  operatingTimeZone A IANA timezone string, the timezone to convert serverTime from
   * @param  localTimeZone     A IANA timezone string, the timezone to convert serverTime to, defaults to system timezone
   * @param  date              The date to use for the conversion (important for DST calculations), defaults to today
   * @return                   The converted timeString in HH:MM
   */
  convertServerTimeToLocal(
    serverTime: string,
    operatingTimeZone: string,
    localTimeZone?: string,
    date?: Date
  ): string | null {
    if (!serverTime || !operatingTimeZone) {
      return null;
    }
    if (operatingTimeZone === localTimeZone) {
      return serverTime;
    } // No conversion necessary
    if (!localTimeZone) {
      localTimeZone = this.dateAndTimeService.getLocalTimeZone();
    }
    if (!date) {
      date = new Date();
    }

    // Convert to minutes in operatingTimeZone
    const [otzHours, otzMinutes] = serverTime
      .split(':')
      .map((val) => parseInt(val, 10));
    const otzMinCount = otzHours * 60 + otzMinutes;

    // Convert to UTC
    const otzOffset = this.dateAndTimeService.getUtcOffsetMinutes(date, operatingTimeZone);
    const utcMinCount = otzMinCount - otzOffset;

    // Convert to localTimeZone
    const ltzOffset = this.dateAndTimeService.getUtcOffsetMinutes(date, localTimeZone);
    const ltzMinCount = utcMinCount + ltzOffset;

    // Convert to HH:MM in localTimeZone
    const ltzMinutes = ltzMinCount % 60;
    const ltzHours = Math.floor((ltzMinCount - ltzMinutes) / 60);

    return `${ltzHours}:${ltzMinutes < 10 ? '0' + ltzMinutes : ltzMinutes}`;
  }

  /**
   * An empty weekday schedule
   * @param  operatingTimeZone The operatingTimeZone to use, defaults to local
   * @param  timeIncrement     The time increment to use, defaults to '5'
   * @return                   An empty weekday schedule
   */
  emptyWeekdaySchedule(
    operatingTimeZone: string = this.dateAndTimeService.getLocalTimeZone(),
    timeIncrement: TimeIncrement = '5'
  ): WeekdaySchedule {
    return {
      operatingTimeZone,
      timeIncrement,
      timeSegments: [],
    };
  }

  emptyWeeklySchedule(
    defaultAvailability: Availability = 'both'
  ): WeeklySchedule {
    return {
      defaultAvailability,
      sunday: this.emptyWeekdaySchedule(),
      monday: this.emptyWeekdaySchedule(),
      tuesday: this.emptyWeekdaySchedule(),
      wednesday: this.emptyWeekdaySchedule(),
      thursday: this.emptyWeekdaySchedule(),
      friday: this.emptyWeekdaySchedule(),
      saturday: this.emptyWeekdaySchedule(),
    };
  }

  /**
   * Returns exceptions for a specific doctor
   * @param doctorId       The doctor uid to query
   * @param timeZone       The IANA timezone to convert to; defaults to operatingTimeZone, then to local
   * @param startTime      The UTC millis start time to limit the query to
   * @param endTime        The UTC millis end time to limit the query to
   * @return               An Observable containing an array of exception schedules
   */
  getExceptionScheduleByDoctor(
    doctorId: string,
    timeZone?: string,
    startTime?: number,
    endTime?: number,
    includeDisabledExceptions: boolean = false
  ): Observable<LocalExceptionSchedule[]> {
    let query = firebase.firestore().collection('doctorScheduleExceptions')
      .where('doctorID', '==', doctorId);

    if (!includeDisabledExceptions) {
      query = query.where('exception', '==', true);
    }

    if (startTime) {
      query = query.where('day', '>=', startTime);
    }

    if (endTime) {
      query = query.where('day', '<=', endTime);
    }

    return Observable.create((observer) => {
      return query.onSnapshot({
        next: (querySnapshot) => {
          const exceptionArray = querySnapshot.docs.map((document) => document.data() as ExceptionSchedule);
          const standardizedExceptions = exceptionArray.filter((exceptionSchedule) => exceptionSchedule.day === exceptionSchedule.epochDay * MILLISECONDS_PER_DAY);
          const localExceptions = standardizedExceptions.map((exceptionSchedule) => this.convertExceptionScheduleToLocalTime(exceptionSchedule, timeZone));
          observer.next(localExceptions);
        },
        error: (error) => {
          console.error(error);
          observer.error(error);
        }
      });
    });
  }

  getPublicExceptionScheduleByDoctor(
    companyId: string,
    doctorId: string,
    startTime: number,
    endTime: number,
    timeZone?: string,
  ): Observable<LocalExceptionSchedule[]> {
    return timer(0, 60000).pipe(
      switchMap(async () => {
        const startDateTime = new Date(startTime).toISOString().split('T')[0];
        const endDateTime = new Date(endTime).toISOString().split('T')[0];
        const serverExceptions = await firstValueFrom(this.doctorSettingsApiService
          .getDoctorScheduleExceptionsInDateRange(companyId, doctorId, startDateTime, endDateTime)) ?? [];
        return serverExceptions.map((exception) => this.getLocalExceptionFromServerException(exception, companyId, doctorId, timeZone));
      }),
    );
  }

  private getLocalExceptionFromServerException(serverException: LegacyDoctorScheduleException, companyId: string, doctorId: string, localTimeZone: string): LocalExceptionSchedule {
    const exceptionSchedule: ExceptionSchedule = {
      exception: true,
      doctorID: doctorId,
      doctorCompany: companyId,
      epochDay: serverException.epochDay,
      day: serverException.epochDay * MILLISECONDS_PER_DAY,
      operatingTimeZone: serverException.operatingTimeZone,
      timeIncrement: `${serverException.timeSlotDuration}`,
      timeSegments: serverException.timeSegments.map((timeSegment) => ({
        operatingStartTime: timeSegment.operatingStartTime,
        operatingEndTime: timeSegment.operatingEndTime,
        location: timeSegment.locationId,
        availability: this.getAvailabilityFromServerAvailability(timeSegment.availability),
      })),
    };

    return this.convertExceptionScheduleToLocalTime(exceptionSchedule, localTimeZone);
  }

  private getAvailabilityFromServerAvailability(serverAvailability: ServerTimeSegment.AvailabilityEnum): Availability | 'default' {
    switch (serverAvailability) {
      case ServerTimeSegment.AvailabilityEnum.Virtual:
        return 'virtual';
      case ServerTimeSegment.AvailabilityEnum.Clinic:
        return 'clinic';
      case ServerTimeSegment.AvailabilityEnum.Both:
        return 'both';
      case ServerTimeSegment.AvailabilityEnum.Default:
        return 'default';
    }
  }

  twentyFourTimetoAMPM(time) {
    // add padding zero to front if less than 10AM
    if (time.split(':')[0].length < 2) {
      time = '0' + time;
    }
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  convertEMRScheduleToInsig(emrSchedule: EMRSchedule): EMRScheduleConverted[] {
    let emrDoctorScheduleConverted: EMRScheduleConverted[] = [];
    if (!!emrSchedule) {
      Object.keys(emrSchedule).forEach((time) => {
        const scheduleObj = emrSchedule[time];
        if (!!scheduleObj.apptID && !!scheduleObj.patientID) {
          let hour = time.split(':')[0];
          if (parseInt(hour, 10) < 8) {
            hour = (parseInt(hour, 10) + 12).toString();
          }
          const minute = time.split(':')[1];
          let start = new Date();

          start = new Date(
            start.setHours(parseInt(hour, 10), parseInt(minute, 10))
          );
          const span = 14;
          // if(!!scheduleObj.span && parseInt(scheduleObj.span) > 1){
          //   span = (15*parseInt(scheduleObj.span) - 1)+14;
          // }
          const end = addMinutes(new Date(start), span);

          emrDoctorScheduleConverted.push({
            start: start.getTime(),
            end: end.getTime(),
            ...scheduleObj,
          });
        }
      });
    }

    return emrDoctorScheduleConverted;
  }

  /**
   * Returns only exceptions past (new Date()).getTime()
   * @param  {string}            doctorID The doctor whose exceptions to query
   * @return {Observable<any[]>}          An Observable containing an array of exception schedules
   */
  getFutureExceptionScheduleByDoctor(
    doctorId: string,
    timeZone?: string
  ): Observable<LocalExceptionSchedule[]> {
    return this.getExceptionScheduleByDoctor(doctorId, timeZone, new Date().getTime());
  }

  convertWeeklyScheduleToLocalTime(weeklySchedule: WeeklySchedule, date: Date, localTimeZone: string): LocalWeeklySchedule {
    const startOfWeek = this.dateAndTimeService.setHour(this.dateAndTimeService.getStartOfWeek(date, localTimeZone), localTimeZone, 12);
    const sundayDate = startOfWeek;

    const mondayDate = this.dateAndTimeService.addDaysToDate(sundayDate, 1);
    const tuesdayDate = this.dateAndTimeService.addDaysToDate(sundayDate, 2);
    const wednesdayDate = this.dateAndTimeService.addDaysToDate(sundayDate, 3);
    const thursdayDate = this.dateAndTimeService.addDaysToDate(sundayDate, 4);
    const fridayDate = this.dateAndTimeService.addDaysToDate(sundayDate, 5);
    const saturdayDate = this.dateAndTimeService.addDaysToDate(sundayDate, 6);

    return {
      defaultAvailability: weeklySchedule.defaultAvailability,
      sunday: weeklySchedule.sunday ? this.convertDailyScheduleToLocalTime(weeklySchedule.sunday, sundayDate, localTimeZone) : undefined,
      monday: weeklySchedule.monday? this.convertDailyScheduleToLocalTime(weeklySchedule.monday, mondayDate, localTimeZone) : undefined,
      tuesday: weeklySchedule.tuesday ? this.convertDailyScheduleToLocalTime(weeklySchedule.tuesday, tuesdayDate, localTimeZone) : undefined,
      wednesday: weeklySchedule.wednesday ? this.convertDailyScheduleToLocalTime(weeklySchedule.wednesday, wednesdayDate, localTimeZone) : undefined,
      thursday: weeklySchedule.thursday ? this.convertDailyScheduleToLocalTime(weeklySchedule.thursday, thursdayDate, localTimeZone) : undefined,
      friday: weeklySchedule.friday ? this.convertDailyScheduleToLocalTime(weeklySchedule.friday, fridayDate, localTimeZone) : undefined,
      saturday: weeklySchedule.saturday ? this.convertDailyScheduleToLocalTime(weeklySchedule.saturday, saturdayDate, localTimeZone) : undefined,
    };
  }

  convertWeeklyScheduleToOperatingTime(localWeeklySchedule: LocalWeeklySchedule): WeeklySchedule {
    return {
      ...localWeeklySchedule,
      sunday: this.convertDailyScheduleToOperatingTime(localWeeklySchedule.sunday),
      monday: this.convertDailyScheduleToOperatingTime(localWeeklySchedule.monday),
      tuesday: this.convertDailyScheduleToOperatingTime(localWeeklySchedule.tuesday),
      wednesday: this.convertDailyScheduleToOperatingTime(localWeeklySchedule.wednesday),
      thursday: this.convertDailyScheduleToOperatingTime(localWeeklySchedule.thursday),
      friday: this.convertDailyScheduleToOperatingTime(localWeeklySchedule.friday),
      saturday: this.convertDailyScheduleToOperatingTime(localWeeklySchedule.saturday),
    };
  }

  convertDailyScheduleToLocalTime(dailySchedule: WeekdaySchedule, date: Date, localTimeZone: string): LocalWeekdaySchedule {
    if (!dailySchedule.timeSegments) {
      dailySchedule.timeSegments = [];
    }

    const localTimeSegments = dailySchedule.timeSegments.map((timeSegment) => {
      return this.convertTimeSegmentToLocalTime(timeSegment, date, dailySchedule.operatingTimeZone, localTimeZone);
    });
    return {
      ...dailySchedule,
      timeSegments: localTimeSegments,
    };
  }

  convertDailyScheduleToOperatingTime(localDailySchedule: LocalWeekdaySchedule): WeekdaySchedule {
    const localTimeSegments = localDailySchedule.timeSegments ?? [];
    return {
      ...localDailySchedule,
      timeSegments: this.convertTimeSegmentsToOperatingTime(localTimeSegments),
    };
  }

  convertTimeSegmentsToLocalTime(timeSegments: TimeSegment[], date: Date, operatingTimeZone: string, localTimeZone: string): LocalTimeSegment[] {
    return timeSegments.map((timeSegment) => this.convertTimeSegmentToLocalTime(timeSegment, date, operatingTimeZone, localTimeZone));
  }

  convertTimeSegmentsToOperatingTime(localTimeSegments: LocalTimeSegment[]): TimeSegment[] {
    return localTimeSegments.map((localTimeSegment) => this.convertTimeSegmentToOperatingTime(localTimeSegment));
  }

  convertTimeSegmentToLocalTime(timeSegment: TimeSegment, date: Date, operatingTimeZone: string, localTimeZone: string): LocalTimeSegment {
    if (
      timeSegment.operatingStartTime === undefined || timeSegment.operatingEndTime === null ||
      timeSegment.operatingEndTime === undefined || timeSegment.operatingEndTime === null
    ) {
      timeSegment = this.initializeTimeSegmentOperatingTime(timeSegment, operatingTimeZone);
    }

    const operatingStartTime = timeSegment.operatingStartTime;
    const operatingEndTime = timeSegment.operatingEndTime;
    return {
      ...timeSegment,
      localStartTime: this.convertTimeBetweenTimeZones(date, operatingStartTime, operatingTimeZone, localTimeZone),
      localEndTime: this.convertTimeBetweenTimeZones(date, operatingEndTime, operatingTimeZone, localTimeZone),
    };
  }

  convertTimeSegmentToOperatingTime(timeSegment: LocalTimeSegment): TimeSegment {
    delete timeSegment.localStartTime;
    delete timeSegment.localEndTime;
    return timeSegment;
  }

  convertTimeBetweenTimeZones(date: Date, time: number, fromTimeZone: string, toTimeZone: string): number {
    const fromOffset = this.dateAndTimeService.getUtcOffsetMinutes(date, fromTimeZone);
    const toOffset = this.dateAndTimeService.getUtcOffsetMinutes(date, toTimeZone);

    // fromTime - fromOffset = toTime - toOffset
    // => toTime = fromTime - fromOffset + toOffset
    return time + (-fromOffset + toOffset) * 60 * 1000;
  }

  getUserWeeklySchedule(companyId: string, userId: string): Observable<WeeklySchedule> {
    const weeklyScheduleSubject = new ReplaySubject<WeeklySchedule>(1);
    const weeklyScheduleReference = firebase.database().ref(`doctorSettings/${companyId}/${userId}/weeklySchedule`);
    weeklyScheduleReference.on('value', (snapshot) => {
      if (snapshot !== undefined) {
        let weeklySchedule = snapshot.val();
        if (!weeklySchedule) {
          weeklySchedule = this.emptyWeeklySchedule();
        }
  
        for (let dayOfTheWeek of this.daysOfTheWeek) {
          if (!weeklySchedule[dayOfTheWeek]) {
            weeklySchedule[dayOfTheWeek] = this.emptyWeekdaySchedule();
          }
        }
  
        weeklyScheduleSubject.next(weeklySchedule);
      }
    });
    
    return weeklyScheduleSubject.asObservable();
  }


  async setUserWeeklySchedule(companyId: string, userId: string, weeklySchedule: LocalWeeklySchedule): Promise<void> {
    const operatingWeeklySchedule = this.convertWeeklyScheduleToOperatingTime(weeklySchedule);

    const reference = firebase.database().ref(`doctorSettings/${companyId}/${userId}/weeklySchedule`);
    await reference.set(operatingWeeklySchedule);
    await this.doctorScheduleReindexService.reindexSchedule(userId, companyId);
  }

  convertExceptionScheduleToLocalTime(exceptionSchedule: ExceptionSchedule, localTimeZone: string): LocalExceptionSchedule {
    const dailySchedule = this.convertDailyScheduleToLocalTime(exceptionSchedule, this.getDateFromEpochDay(exceptionSchedule.epochDay), localTimeZone);
    return {
      ...exceptionSchedule,
      timeSegments: dailySchedule.timeSegments,
    };
  }

  getDateFromEpochDay(epochDay: number): Date {
    const utcDate = new Date(epochDay * MILLISECONDS_PER_DAY);

    const localDate = new Date();
    localDate.setFullYear(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    localDate.setHours(0, 0, 0, 0);

    const localMs = localDate.getTime();
    return new Date(localMs);
  }

  getUserExceptionSchedule(userId: string, epochDay: number): Observable<ExceptionSchedule> {
    const exceptionScheduleSubject = new ReplaySubject<ExceptionSchedule>(1);
    const firestoreUnsubscribe = firebase.firestore().collection('doctorScheduleExceptions')
      .where('doctorID', '==', userId)
      .where('epochDay', '==', epochDay)
      .limit(1)
      .onSnapshot((querySnapshot) => {
        exceptionScheduleSubject.next(querySnapshot.docs[0]?.data() as ExceptionSchedule);
      });

    exceptionScheduleSubject.unsubscribe = firestoreUnsubscribe;

    return exceptionScheduleSubject.asObservable();
  }

  async setUserExceptionSchedule(userId: string, companyId: string, epochDay: number, exceptionSchedule: ExceptionSchedule): Promise<void> {
    const utcDay = epochDay * MILLISECONDS_PER_DAY;
    exceptionSchedule.day = utcDay;
    const documentKey = `${userId}${utcDay}`;
    await firebase.firestore().collection('doctorScheduleExceptions').doc(documentKey).set(exceptionSchedule);

    await this.doctorScheduleReindexService.reindexSchedule(userId, companyId);
  }

  convertMsPastMidnightToTimeString(msPastMidnight: number): string {
    const minutesPastMidnight = Math.floor(msPastMidnight / MILLISECONDS_PER_MINUTE);
    const hoursPastMidnight = Math.floor(minutesPastMidnight / MINUTES_PER_HOUR);
    const remainderMinutes = minutesPastMidnight - hoursPastMidnight * MINUTES_PER_HOUR;
    
    let timeStringHour = hoursPastMidnight < 13 ? `${hoursPastMidnight}` : `${hoursPastMidnight - 12}`;
    if (hoursPastMidnight === 0) {
      timeStringHour = '12';
    }
    const timeStringMinutes = remainderMinutes < 10 ? `0${remainderMinutes}` : `${remainderMinutes}`;
    const amOrPm = hoursPastMidnight < 12 ? "AM" : "PM";

    return `${timeStringHour}:${timeStringMinutes} ${amOrPm}`;
  }

  convertMsPastMidnightTo24HourTimeString(msPastMidnight: number): string {
    const minutesPastMidnight = Math.floor(msPastMidnight / MILLISECONDS_PER_MINUTE);
    const hoursPastMidnight = Math.floor(minutesPastMidnight / MINUTES_PER_HOUR);
    const remainderMinutes = minutesPastMidnight - hoursPastMidnight * MINUTES_PER_HOUR;

    const timeStringHour = `${hoursPastMidnight}`;
    const timeStringMinutes = remainderMinutes < 10 ? `0${remainderMinutes}` : `${remainderMinutes}`;

    return `${timeStringHour}:${timeStringMinutes}`;
  }

  /**
   * Fills in new fields when they are missing. This may be inaccurate as the older fields are missing DST information.
   * @param timeSegment The time segment with the missing fields
   * @param operatingTimeZone The operating time zone the time segment is for
   * @returns The time segment with new fields
   */
  initializeTimeSegmentOperatingTime(timeSegment: TimeSegment, operatingTimeZone: string): TimeSegment {
    const startTime = timeSegment.startTime;
    const endTime = timeSegment.endTime;
    return {
      ...timeSegment,
      operatingStartTime: this.convertTimeBetweenTimeZones(new Date(), startTime, operatingTimeZone, "UTC"),
      operatingEndTime: this.convertTimeBetweenTimeZones(new Date(), endTime, operatingTimeZone, "UTC"),
    };
  }

}
