// @ts-strict-ignore
import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

// services
import { FileUploadService } from 'insig-app/services/fileUpload.service';

// Peripherals
import firebase from 'firebase/compat/app';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'change-profile-image-dialog',
  templateUrl: 'change-profile-image-dialog.component.html',
  providers: [FileUploadService],
})
export class ChangeProfileImageDialogComponent {
  public userData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { uid: string; imageUrl?: string },
    public dialogRef: MatDialogRef<
      ChangeProfileImageDialogComponent,
      { remove: boolean; imageUrl?: string }
    >,
    private snackBar: MatSnackBar,
    private fileUploadService: FileUploadService
  ) {}

  saveUserData(): void {
    this.dialogRef.close(this.userData);
  }

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const extension = fileName.split('.').pop();

    const uploadTask = this.fileUploadService.uploadFile(
      `user/decompressed/${this.data.uid}/${this.data.uid}${extension}`,
      fileData
    );

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      () => {
        /** Do nothing on success */
      },
      (error) => {
        this.snackBar.open(
          `An error occurred: ${error.message || error}`,
          null,
          { duration: 4000 }
        );
      },
      async () => {
        this.data.imageUrl = await uploadTask.snapshot.ref.getDownloadURL();
      }
    );
  }

  removeUserImage(): void {
    this.data.imageUrl = null;
  }

  done(): void {
    if (!this.data.imageUrl) {
      this.dialogRef.close({ remove: true, imageUrl: null });
    } else {
      this.dialogRef.close({ remove: false, imageUrl: this.data.imageUrl });
    }
  }
}
