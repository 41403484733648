import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/compat/app';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Apollo, gql } from 'apollo-angular';

import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';

import { firstValueFrom, from as observableFrom, Observable } from 'rxjs';
import { take, filter, shareReplay, map, switchMap, tap } from 'rxjs/operators';
import { UserData } from 'insig-types/user-data';

@Component({
  selector: 'auth-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [PatientUserDataService],
})
export class ResetPasswordComponent implements OnInit {
  // graphql queries
  private userDataQuery = gql`
    query User($userID: ID!, $token: ID!) {
      getUserData(uid: $userID, token: $token) {
        uid
        first
        last
        phone
        clinicPhone
        email
        company
        address
        province
        city
        signature
        fax
        providerNumber
        title
        qualifications
        languages
        licenseCode
        specialty
        type {
          admin
        }
        image
        apiToken
      }
    }
  `;

  private userResetPasswordMutation = gql`
    mutation UserResetPasswordMutation(
      $userID: ID!
      $token: ID!
      $data: UserInput!
    ) {
      setUserData(uid: $userID, token: $token, data: $data) {
        resetPassword
      }
    }
  `;

  public userObservable = this.firebaseAuthService.onIdTokenChanged().pipe(
    map((user) => {
      if (!user) {
        this.router.navigate(['/auth/login']);
      }
      return user;
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    filter((user) => !!user),
    shareReplay({ refCount: true, bufferSize: 1 })
    // tap((x) => console.log(x))
  );

  public isDoctorObservable = this.userObservable.pipe(
    switchMap((user) => {
      return observableFrom(this.firebaseAuthService.checkIfUserIsDoctor(user));
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
    // tap((x) => console.log(x))
  );

  public userDataObservable = this.isDoctorObservable.pipe(
    switchMap((isDoctor) => {
      return this.userObservable.pipe(
        switchMap((user) =>
          isDoctor
            ? this.getDoctorData(user)
            : observableFrom(this.getPatientData(user))
        )
      );
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    tap((x) => console.log(x))
  );

  public form: UntypedFormGroup;
  public errorMessage: string | undefined;
  public specialLogo = '';
  constructor(
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private apollo: Apollo,
    private firebaseAuthService: FirebaseAuthService,
    private patientUserDataService: PatientUserDataService
  ) {
    this.form = this.formBuilder.group({
      oldPassword: this.formBuilder.control(null, [Validators.required]),
      newPassword: this.formBuilder.control(null, [Validators.required]),
      newPasswordConfirm: this.formBuilder.control(null, [Validators.required]),
    });
  }

  ngOnInit() {
    if (
      window.location.origin.includes('app.well.company') ||
      window.location.origin.includes('app.wellclinics.ca')
    ) {
      this.specialLogo = 'assets/images/global/well/well-logo-normal.svg';
    } else if (window.location.origin.includes('app.jacknathanhealth.com')) {
      this.specialLogo = 'assets/images/global/jnh/jnh-logo.png';
    } else if (window.location.origin.includes('app.thevirtualdoctor.org')) {
      this.specialLogo = 'assets/images/global/tvd/tvd.jpeg';
    } else if (window.location.origin.includes('virtual.highmark.tech')) {
      this.specialLogo = 'assets/images/global/eq/eq-logo.png';
    }
  }

  getDoctorData(user: firebase.User): Observable<any> {
    return observableFrom(user.getIdToken()).pipe(
      switchMap((idToken) =>
        this.apollo.query<{ getUserData: UserData }>({
          fetchPolicy: 'no-cache',
          query: this.userDataQuery,
          variables: {
            userID: user.uid,
            token: idToken,
          },
        })
      ),
      map((result) => result.data.getUserData)
    );
  }

  async getPatientData(user: firebase.User): Promise<any> {
    return this.patientUserDataService
      .getPatientData(user.uid)
      .pipe(take(1))
      .toPromise();
  }

  async updatePassword() {
    if (this.form.valid) {
      const user = this.firebaseAuthService.getFirebaseCurrentUser();
      if (!user) {
        this.errorMessage = 'You are not logged in. PLease log in and try again.';
        return;
      }

      try {
        // First reauthenticate the user in case their session is expired
        await user.reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            user.email ?? '',
            this.form.controls.oldPassword.value
          )
        );
        // If reauthentication is successful update the password
        await user.updatePassword(this.form.controls.newPassword.value);

        const userID = (await firstValueFrom(this.userObservable)).uid;
        const token = await user.getIdToken();
        const data = {
          resetPassword: false,
        };
        const userResetPasswordMutation: any = await this.apollo
          .mutate({
            mutation: this.userResetPasswordMutation,
            variables: {
              userID,
              token,
              data,
            },
          })
          .toPromise()
          .catch((error) => {
            console.error(error);
            throw error;
          });

        const userData = userResetPasswordMutation.data.setUserData;
        console.log(userData);

        this.snackbar.open(
          'Your password has been reset, please log in again!',
          undefined,
          { duration: 4000 }
        );
        this.router.navigate([`/auth/login`]);
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = 'There was an error resetting your password. Please try again.';
        }
      }
    } else {
      this.errorMessage = 'There are missing fields';
    }
  }
}
