// @ts-strict-ignore
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { FormValidatorsService } from '@insig-health/services/form-validators/form-validators.service';
import { firstValueFrom } from 'rxjs';

export enum PasswordUpdateState {
  LOGIN,
  ENTER_NEW_PASSWORD,
}

@Component({
  selector: 'insig-health-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent {
  public static readonly PASSWORD_CHANGE_SUCCESS_MESSAGE = 'Password changed successfully.';
  public static readonly PASSWORD_CHANGE_FAILURE_MESSAGE = 'Password change failed. Please try again later.';
  public readonly NEW_PASSWORD_FORM_CONTROL_NAME = 'newPassword';
  public readonly CONFIRM_NEW_PASSWORD_FORM_CONTROL_NAME = 'confirmNewPassword';
  public readonly PasswordUpdateState = PasswordUpdateState;
  public passwordUpdateState = PasswordUpdateState.LOGIN;
  public email = this.gcpIpAuthService.getCurrentUser()?.email ?? undefined;
  public readonly changePasswordForm = new FormGroup({
    [this.NEW_PASSWORD_FORM_CONTROL_NAME]: new FormControl('', [Validators.required, this.formValidatorsService.isPasswordValidValidator]),
    [this.CONFIRM_NEW_PASSWORD_FORM_CONTROL_NAME]: new FormControl('', [Validators.required]),
  }, { validators: [this.formValidatorsService.controlValuesMatchingValidatorFactory(this.NEW_PASSWORD_FORM_CONTROL_NAME, this.CONFIRM_NEW_PASSWORD_FORM_CONTROL_NAME)] });

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private formValidatorsService: FormValidatorsService,
    private gcpIpAuthService: GcpIpAuthService,
    private snackBar: MatSnackBar,
  ) {
    firstValueFrom(this.dialogRef.beforeClosed()).then(async () => {
      const customToken = await this.gcpIpAuthService.getFirebaseCustomToken();
      await this.gcpIpAuthService.signInWithCustomToken(customToken);
    });
  }

  handleLoginSuccess(): void {
    this.passwordUpdateState = PasswordUpdateState.ENTER_NEW_PASSWORD;
  }

  async handleSaveButtonClick(changePasswordForm: typeof this.changePasswordForm): Promise<void> {
    if (!changePasswordForm.valid) {
      return;
    }

    try {
      await this.gcpIpAuthService.setPassword(changePasswordForm.controls.newPassword.value);
      this.snackBar.open(ChangePasswordDialogComponent.PASSWORD_CHANGE_SUCCESS_MESSAGE, undefined, {
        duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS, 
      });
      this.dialogRef.close(true);
    } catch (error) {
      console.error(error);
      this.snackBar.open(ChangePasswordDialogComponent.PASSWORD_CHANGE_FAILURE_MESSAGE, undefined, {
        duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
      });
      this.dialogRef.close(false);
    }
  }
}
