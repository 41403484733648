// <reference types="@types/googlemaps" />

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AWSCLOUDFUNCTIONSENDPOINT } from '@insig-health/config/config';
import { FirestoreService } from '../firestore/firestore.service';
import { PatientProfile } from 'insig-types/user-data';

import firebase from 'firebase/compat/app';

export interface ReferralNetwork {
  address: string;
  fax: string;
  lat: number;
  lng: number;
  locationCategory: string;
  locationID: string;
  name: string;
  type: string;
  banner?: string;
  banner2?: string;
  logo?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ReferralNetworkService {
  public readonly LONGITUDE_SPREAD = 0.05;

  private sendReferralNetworkURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'communications/sendReferralNetwork';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  public ALLIED_HEALTH_NAV_OBJ_ID = 'alliedHealthNav';

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private firestoreService: FirestoreService,
  ) {
  }

  loadReferralNetworkByTypeDistance(type: string, lng: number): Observable<Array<ReferralNetwork & { id: string }>> {
    const longitudeSpread = this.LONGITUDE_SPREAD;
    const lngBottom = lng - longitudeSpread;
    const lngTop = lng + longitudeSpread;

    return this.firestoreService.getQueryWithIdAsObservable<ReferralNetwork>(
      firebase.firestore().collection('referralNetwork')
        .where('type', '==', type)
        .where('lng', '>=', lngBottom)
        .where('lng', '<=', lngTop),
    );
  }

  loadReferralNetworkByType(type: string): Observable<Array<ReferralNetwork & { id: string }>> {
    return this.firestoreService.getQueryWithIdAsObservable<ReferralNetwork>(
      firebase.firestore().collection('referralNetwork')
        .where('type', '==', type),
    );
  }

  loadReferralNetworkByPharmacyBanner(banner: string): Observable<Array<ReferralNetwork & { id: string }>> {
    return this.firestoreService.getQueryWithIdAsObservable<ReferralNetwork>(
      firebase.firestore().collection('referralNetwork')
        .where('banner', '==', banner),
    );
  }

  async getAlliedHealthNavObj(): Promise<ReferralNetwork> {
    return await firebase.firestore()
      .collection('referralNetwork')
      .doc(this.ALLIED_HEALTH_NAV_OBJ_ID)
      .get()
      .then((snapshot) => snapshot?.data() as ReferralNetwork);
  }

  async sendReferral(
    referralOption: string,
    patientInfo: PatientProfile,
    referralDoc: string | false,
    referralMessage: string,
    patientReferral = false,
  ): Promise<void> {
    if (!patientReferral) {
      this.snackBar.open('Sending referral...', undefined, { duration: 5000 });
    }

    await this.http
      .post<{ statusCode: number }>(
        this.sendReferralNetworkURL,
        {
          referralOption,
          patientInfo,
          referralDoc,
          referralMessage,
          patientReferral,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        if (!patientReferral) {
          if (response?.statusCode === 200) {
            this.snackBar.open('Referral Sent!', undefined, { duration: 2000 });
          } else {
            this.snackBar.open('Error sending referral!', undefined, {
              duration: 2000,
            });
          }
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }
}
