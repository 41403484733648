import { Injectable } from '@angular/core';
import { BookSlotRequest, DoctorAvailabilityESService, DoctorScheduleAvailabilityReIndexService, DoctorScheduleDataESService } from '@insig-health/api/doctor-booking-flow-api-v1';
import { DoctorScheduleDataESService as DoctorScheduleDataESCopyService, DoctorAvailabilityESService as DoctorAvailabilityESCopyService, DoctorScheduleAvailabilityReIndexService as DoctorScheduleAvailabilityReIndexCopyService } from '@insig-health/api/doctor-booking-flow-api-v1-copy';
import { firstValueFrom } from 'rxjs';
import { IS_USING_BETA_SITE, PRODUCTION } from '@insig-health/config/config';

@Injectable({
  providedIn: 'root',
})
export class DoctorScheduleReindexService {
  constructor(
    private doctorScheduleDataEsService: DoctorScheduleDataESService,
    private doctorScheduleDataEsCopyService: DoctorScheduleDataESCopyService,
    private doctorScheduleAvailabilityReIndexService: DoctorScheduleAvailabilityReIndexService,
    private doctorScheduleAvailabilityReIndexCopyService: DoctorScheduleAvailabilityReIndexCopyService,
    private doctorAvailabilityEsService: DoctorAvailabilityESService,
    private doctorAvailabilityEsCopyService: DoctorAvailabilityESCopyService,
  ) {}

  async reindexSchedule(doctorId: string, companyId: string): Promise<void> {
    await firstValueFrom(this.doctorScheduleDataEsService.addDoctorSchedule(companyId, doctorId));
    if (PRODUCTION || IS_USING_BETA_SITE) {
      await firstValueFrom(this.doctorScheduleDataEsCopyService.addDoctorSchedule(companyId, doctorId));
    }
  }

  async reindexAvailability(doctorId: string): Promise<void> {
    await firstValueFrom(this.doctorScheduleAvailabilityReIndexService.updateOneDoctorsEarliestAvailability(doctorId));
    if (PRODUCTION || IS_USING_BETA_SITE) {
      await firstValueFrom(this.doctorScheduleAvailabilityReIndexCopyService.updateOneDoctorsEarliestAvailability(doctorId));
    }
  }

  async reserveAppointmentSlot(bookSlotRequest: BookSlotRequest): Promise<void> {
    await firstValueFrom(this.doctorAvailabilityEsService.reserveSlot(bookSlotRequest));
    if (PRODUCTION || IS_USING_BETA_SITE) {
      await firstValueFrom(this.doctorAvailabilityEsCopyService.reserveSlot(bookSlotRequest));
    }
  }

  async releaseAppointmentSlot(bookSlotRequest: BookSlotRequest): Promise<void> {
    await firstValueFrom(this.doctorAvailabilityEsService.releaseSlot(bookSlotRequest));
    if (PRODUCTION || IS_USING_BETA_SITE) {
      await firstValueFrom(this.doctorAvailabilityEsCopyService.releaseSlot(bookSlotRequest));
    }
  }
}
