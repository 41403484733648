import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DateAndTimeService } from '../date-and-time/date-and-time.service';


@Injectable({
  providedIn: 'root',
})
export class FormValidatorsService {
  private NON_DIGITS_REGEX = new RegExp(/\D/gm);
  private PHONE_NUMBER_VALID_REGEX = new RegExp(/^\s*$|^\d{10}$/);
  private PASSWORD_LENGTH_REGEX = new RegExp(/^.{8,}$/);
  private PASSWORD_UPPER_CASE_REGEX = new RegExp(/^.*[A-Z]+.*$/);
  private PASSWORD_LOWER_CASE_REGEX = new RegExp(/^.*[a-z]+.*$/);
  private PASSWORD_NUMBER_REGEX = new RegExp(/^.*\d+.*$/);
  private PASSWORD_SPECIAL_CHARACTER_REGEX = new RegExp(/^.*[!@#$%^&*]+.*/);
  private BIRTHDATE_FORMAT_REGEX = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  private POSTAL_CODE_FORMAT_REGEX = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
  private ZIP_CODE_FORMAT_REGEX = new RegExp(/^\d{5}(-\d{4})?$/);
  private FIREBASE_EMAIL_REGEX = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/);

  constructor(
    private dateAndTimeService: DateAndTimeService,
  ) {}

  isEmailFirebaseCompliantValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const email = `${control.value}`.trim();
    return email.match(this.FIREBASE_EMAIL_REGEX) ? null : { emailNotValid: true };
  };

  isPhoneNumberValidValidator(isPhoneNumberOptional: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phoneNumber = `${control.value}`;

      if (isPhoneNumberOptional && phoneNumber === '') {
        return null;
      }

      const filteredPhoneNumber = phoneNumber.replace(this.NON_DIGITS_REGEX, '');

      return filteredPhoneNumber.match(this.PHONE_NUMBER_VALID_REGEX) ? null : { phoneNumberNotValid: true };
    };
  }

  getNonFormattedPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(this.NON_DIGITS_REGEX, '');
  }

  isPasswordValidValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = `${control.value}`;

    const isPasswordValid = password.match(this.PASSWORD_LENGTH_REGEX) &&
      password.match(this.PASSWORD_UPPER_CASE_REGEX) &&
      password.match(this.PASSWORD_LOWER_CASE_REGEX) &&
      password.match(this.PASSWORD_NUMBER_REGEX) &&
      password.match(this.PASSWORD_SPECIAL_CHARACTER_REGEX);

    return isPasswordValid ? null : { passwordNotValid: true };
  };

  controlValuesMatchingValidatorFactory(formControlOneName: string, formControlTwoName: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const formControlOne = formGroup.get(formControlOneName);
      const formControlTwo = formGroup.get(formControlTwoName);

      return formControlOne && formControlTwo && formControlOne.value === formControlTwo.value ? null : { controlValuesNotMatching: true };
    };
  }

  isDateValid: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const birthDateValue = control.value;
    return this.dateAndTimeService.isDateValid(birthDateValue, 'YYYY-MM-DD') ? null : { birthdateIsNotValid: true };
  };

  isDateInCorrectFormat: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const birthDateValue = `${control.value}`;

    return birthDateValue.match(this.BIRTHDATE_FORMAT_REGEX) ? null : { birthdateNotInCorrectFormat: true };
  };

  isDateBeforeDate(maximumDate: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date = `${control.value}`;
      const [controlYear, controlMonth, controlDay] = date.split('-').map((datePart) => parseInt(datePart, 10));

      const [maxYear, maxMonth, maxDay] = [maximumDate.getFullYear(), maximumDate.getMonth() + 1, maximumDate.getDate()];

      if (controlYear < maxYear) {
        return null;
      } else if (controlYear === maxYear && controlMonth < maxMonth) {
        return null;
      } else if (controlYear === maxYear && controlMonth === maxMonth && controlDay <= maxDay) {
        return null;
      } else {
        return { dateIsAfterMaximumDate: true };
      }
    };
  }

  isMultiFieldBirthDateValid: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const year = control.get('year')?.value;
    const month = control.get('month')?.value;
    const day = control.get('day')?.value;

    return this.dateAndTimeService.isDateValid(`${year}-${month}-${day}`, 'YYYY-MM-DD') ? null : { birthdateIsNotValid: true };
  };

  isMultiFieldBirthDateInTheFuture: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const year = control.get('year')?.value;
    const month = control.get('month')?.value;
    const day = control.get('day')?.value;

    return this.dateAndTimeService.isDateOneAfterDateTwo(new Date(`${year}-${month}-${day}`), new Date()) === false ? null : { birthdateIsInTheFuture: true };
  };

  isPostalCodeValid: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const postalCodeValue = `${control.value}`;
    const isValidPostalCode = this.POSTAL_CODE_FORMAT_REGEX.test(postalCodeValue);
    const isValidZipCode = this.ZIP_CODE_FORMAT_REGEX.test(postalCodeValue);

    return isValidPostalCode || isValidZipCode ? null : { postalCodeNotValid: true };
  };
}
