// @ts-strict-ignore
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { MedicationsListService } from '../../../services/medications-list.service';

import { map, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'medications-edit',
  templateUrl: './medications-edit.component.html',
  providers: [MedicationsListService],
})
export class MedicationsEditComponent implements OnInit {
  @Input() question: any;
  @Output() questionChange = new EventEmitter<any>();

  get allowMultiple(): boolean {
    return !!this.question.allowMultiple;
  }
  set allowMultiple(value: boolean) {
    if (this.question.allowMultiple !== value) {
      this.question.allowMultiple = value;
      this.questionChange.emit(this.question);
    }
  }

  @ViewChild('itemsSelect') itemsSelect;
  itemsCtrl: UntypedFormControl;
  filteredItems: any;
  private items: string[] = [];
  private previewQuestion = { response: { selectedAnswers: [] } };

  constructor(private medicationsListService: MedicationsListService) {
    this.itemsCtrl = new UntypedFormControl();
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      map((name) => this.filterItems(name))
    );
  }

  async ngOnInit() {
    this.question.allowMultiple = !!this.question.allowMultiple; // coerce boolean
    try {
      this.items = await this.medicationsListService
        .getMedications()
        .pipe(take(1))
        .toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  filterItems(val: string) {
    return val
      ? this.items
          .filter((s) => s.toLowerCase().indexOf(val.toLowerCase()) === 0)
          .slice(0, 50)
      : this.items.slice(0, 50);
  }

  removeItem(val) {
    const index = this.previewQuestion.response.selectedAnswers.indexOf(val);
    this.previewQuestion.response.selectedAnswers.splice(index, 1);
  }

  addItem(val) {
    if (this.allowMultiple) {
      if (this.previewQuestion.response.selectedAnswers.indexOf(val) === -1) {
        this.previewQuestion.response.selectedAnswers.push(val);
      }
      this.itemsCtrl.setValue(null);
    }
  } // end add item
} // end component
