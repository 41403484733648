// @ts-strict-ignore
import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import firebase from 'firebase/compat/app';
import { FileUploadService } from '../../../../services/fileUpload.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'diagram-edit',
  templateUrl: './diagram-edit.component.html',
  providers: [FileUploadService],
})
export class DiagramEditComponent {
  @Input() question: any;
  /** The surveyID for saving the image path under. Optional but recommended for organizational purposes. */
  @Input() surveyID?: string;
  @Input() userID: string;
  @ViewChild('container') public container: ElementRef;
  maxWidth = 500;

  constructor(private fileUploadService: FileUploadService) {}

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const extension = fileName.split('.').pop();

    const path = `user/surveys/${this.userID}/${this.surveyID ||
      this.generateRandomID(32)}/${this.question.id}${extension}`;
    const uploadTask = this.fileUploadService.uploadFile(path, fileData);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        console.log(snapshot);
      },
      (error) => {
        console.log(error);
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        this.question.images = downloadURL;
      }
    );
  } // end func

  /**
   * Generates a random alphanumeric string to use as an identifier
   * // TODO adstract this function and similar functions into a global service
   * @param length The length of the id to generate
   */
  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
} // end component
