// @ts-strict-ignore
import {
  Directive,
  ElementRef,
  Input,
  ViewContainerRef,
  OnDestroy,
  HostListener,
} from '@angular/core';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { InsigTooltipComponent } from './insig-tooltip.component';

type TooltipPosition = 'left' | 'right' | 'above' | 'below';

@Directive({
  selector: '[insTooltip]',
  exportAs: 'insTooltip',
})
export class InsigTooltipDirective implements OnDestroy {
  private static readonly OFFSET_PX = 0;

  private _message = '';
  @Input('insTooltip')
  get message(): string {
    return this._message;
  }
  set message(value: string) {
    if (value !== this._message) {
      this._message = value ? `${value}`.trim() : '';
      if (this._componentInstance) {
        this._componentInstance.message = this._message;
      }
    }
  }

  private _position: TooltipPosition = 'below';
  @Input('insTooltipPosition')
  get position(): TooltipPosition {
    return this._position;
  }
  set position(value: TooltipPosition) {
    if (value !== this._position) {
      this._position = value;
    }
  }

  private _disabled = false;
  @Input('insTooltipDisabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (this._disabled !== !!value) {
      this._disabled = !!value;
      if (this._disabled && !!this._overlayRef) {
        this._disposeTooltip();
      }
    }
  }

  private _portal: ComponentPortal<InsigTooltipComponent>;
  private _overlayRef: OverlayRef | null = null;
  private _componentInstance: InsigTooltipComponent | null = null;

  constructor(
    private _elementRef: ElementRef,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngOnDestroy() {
    this._disposeTooltip();
  }

  @HostListener('mouseenter', ['$event']) handleMouseEnter(
    _event: MouseEvent
  ): void {
    if (!this._disabled) {
      this._componentInstance = this._createOverlay().attach(
        this._portal
      ).instance;
      this._componentInstance.message = this._message;
    }
  }

  @HostListener('mouseleave', ['$event']) handleMouseLeave(
    _event: MouseEvent
  ): void {
    this._disposeTooltip();
  }

  @HostListener('click', ['$event']) handleMouseClick(
    _event: MouseEvent
  ): void {
    this._disposeTooltip();
  }

  private _disposeTooltip(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
    this._overlayRef = null;
    this._componentInstance = null;
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      this._portal = new ComponentPortal(
        InsigTooltipComponent,
        this._viewContainerRef
      );
      const config = this._getOverlayConfig();
      this._overlayRef = this._overlay.create(config);
    }
    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this._getPosition(),
      hasBackdrop: false,
      scrollStrategy: this._overlay.scrollStrategies.reposition(),
    });
  }

  private _getPosition(): FlexibleConnectedPositionStrategy {
    let originX: HorizontalConnectionPos;
    let originY: VerticalConnectionPos;

    let overlayX: HorizontalConnectionPos;
    let overlayY: VerticalConnectionPos;

    let offsetX = 0;
    let offsetY = 0;

    switch (this.position) {
      case 'left': {
        originX = 'start';
        originY = 'center';
        overlayX = 'end';
        overlayY = 'center';
        offsetX = -InsigTooltipDirective.OFFSET_PX;
        break;
      }
      case 'right': {
        originX = 'end';
        originY = 'center';
        overlayX = 'start';
        overlayY = 'center';
        offsetX = InsigTooltipDirective.OFFSET_PX;
        break;
      }
      case 'above': {
        originX = 'center';
        originY = 'top';
        overlayX = 'center';
        overlayY = 'bottom';
        offsetY = -InsigTooltipDirective.OFFSET_PX;
        break;
      }
      case 'below': {
        originX = 'center';
        originY = 'bottom';
        overlayX = 'center';
        overlayY = 'top';
        offsetY = InsigTooltipDirective.OFFSET_PX;
        break;
      }
    }

    return this._overlay
      .position()
      .flexibleConnectedTo(this._elementRef)
      .withDefaultOffsetX(offsetX)
      .withDefaultOffsetY(offsetY)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      } as ConnectedPosition])
      .withPush(false);
  }
}
