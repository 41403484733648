import { Injectable } from '@angular/core';
import { map, Observable, switchMap, timer } from 'rxjs';

import firebase from 'firebase/compat/app';
import 'firebase/firestore';

import { OldBookingFlowService, PublicAggregateDoctor, PublicAggregateService } from '../old-booking-flow/old-booking-flow.service';

export interface CompanyPlanVirtualService {
  apptID?: string;
  checked: boolean;
  companyID?: string;
  id: string;
  planID?: string;
  data: {
    buffer: number;
    category?: string[];
    description?: string;
    diagnosticCode: string;
    duration: number;
    inPerson: boolean;
    isPrivate?: boolean;
    label: string;
    phone: boolean;
    price: string;
    serviceCode: string;
    survey: {
      name: string;
      surveyID?: string;
      userID?: string;
    };
    video: boolean;
  }
}

@Injectable({
  providedIn: 'root',
})
export class VirtualCareService {
  constructor(private oldBookingFlowService: OldBookingFlowService) {}

  getUTCTimeStamp(date: Date): number {
    return date.getTime() - date.getTimezoneOffset() * 60 * 1000;
  }

  increment(val: number): number {
    return (val += 1);
  }

  async addCompanyPlanVirtualService(data: CompanyPlanVirtualService): Promise<void> {
    await firebase
      .firestore()
      .collection('companyPlanVirtualServices/')
      .add(data);
  }

  async updateCompanyPlanVirtualService(iden: string, data: CompanyPlanVirtualService): Promise<void> {
    await firebase
      .firestore()
      .collection('companyPlanVirtualServices')
      .doc(iden)
      .set(data, { merge: true });
  }

  rawDoctorListQueryByPlanID(planID: string): Observable<PublicAggregateDoctor[]> {
    return timer(0, 60000).pipe(
      switchMap(() => this.oldBookingFlowService.getDoctorsByPlanId(planID)),
    );
  }

  rawDoctorListQueryByCompanyID(
    companyID: string,
    allPlans: boolean = false,
  ): Observable<PublicAggregateDoctor[]> {
    if (allPlans) {
      return timer(0, 60000).pipe(
        switchMap(() => this.oldBookingFlowService.getDoctorsByNetworkCompanyId(companyID)),
      );
    } else {
      return timer(0, 60000).pipe(
        switchMap(() => this.oldBookingFlowService.getDoctorsByDoctorCompanyId(companyID)),
      );
    }
  }

  getServiceListByCompanyID(companyID: string): Observable<PublicAggregateService[]> {
    return timer(0, 60000).pipe(
      switchMap(() => this.oldBookingFlowService.getServicesByCompanyId(companyID)),
    );
  }

  getServiceListByPlanID(planID: string): Observable<PublicAggregateService[]> {
    return timer(0, 60000).pipe(
      switchMap(() => this.oldBookingFlowService.getServicesByPlanId(planID)),
      map((services) => services.sort((a, b) => a.label.localeCompare(b.label))),
    );
  }

  async removeCompanyPlanVirtualService(iden: string): Promise<void> {
    return firebase
      .firestore()
      .collection('companyPlanVirtualServices')
      .doc(iden)
      .delete();
  }
}
