// @ts-strict-ignore
import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

import firebase from 'firebase/compat/app';

import { FileUploadService } from '../../../../services/fileUpload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'pdf-edit',
  templateUrl: './pdf-edit.component.html',
  styles: [
    `
      .marginRight75em {
        margin-right: 7.5em;
      }
      .marginRight3em {
        margin-right: 3em;
      }
    `,
  ],
  providers: [FileUploadService],
})
export class PDFEditComponent implements OnInit {
  @Input() question: any;
  /** The surveyID for saving the pdf path under. Optional but recommended for organizational purposes. */
  @Input() surveyID?: string;
  @Input() userID: string;
  private page = 1;
  private loading = false;
  private error = false;

  constructor(
    private snackBar: MatSnackBar,
    private fileUploadService: FileUploadService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (!this.question.readOnly) {
      this.question.readOnly = false;
    }
  }

  callBackFn(pdf: any) {
    this.question.numPages = pdf.numPages;
  }

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const extension = fileName.split('.').pop();

    this.loading = true;
    const path = `user/surveys/${this.userID}/${this.surveyID ||
      this.generateRandomID(32)}/${this.question.id}${extension}`;
    const uploadTask = this.fileUploadService.uploadFile(path, fileData);
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        console.log(snapshot);
        this.changeDetector.detectChanges();
      },
      (error) => {
        console.log(error);
        this.loading = false;
      },
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        this.question.pdfURL = downloadURL;
        this.loading = false;
      }
    );
  } // end func

  onError(error: any) {
    this.error = true;
    console.log('PDF Error!');
    console.log(error);
  }

  /**
   * Generates a random alphanumeric string to use as an identifier
   * // TODO adstract this function and similar functions into a global service
   * @param length The length of the id to generate
   */
  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
} // end clas
