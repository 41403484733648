// @ts-strict-ignore
import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-delete-survey-dialog',
  templateUrl: './confirm-delete-survey-dialog.component.html',
})
export class ConfirmDeleteSurveyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<Component>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
} // end class
