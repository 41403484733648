<section class="app-header">
  <div
    class="app-header-inner bg-color-primary"
    [ngStyle]="{ 'box-shadow': AppConfig.navShadow ? '' : 'none' }"
    style="padding: 0px 16px;"
  >
    <div class="hidden-lg-up float-left">
      <button
        mat-icon-button
        myToggleOffcanvasNav
        class="mat-button header-icon toggle-sidebar-btn"
      >
        <mat-icon class="notranslate">menu</mat-icon>
      </button>
    </div>

    <div
      class="top-nav-left hidden-md-down h-100 brand"
      style="display: flex;width: auto;align-items: center;"
    >
      <span class="my-1 ml-1 p-0 h-100 d-flex">
        <img
          *ngIf="AppConfig.branding"
          class="mt-1"
          src="{{ AppConfig.branding }}"
          style="height:40px;max-width:100px;"
        />
      </span>
    </div>

    <div class="top-nav-right h-100 d-flex align-items-center">
      <button
        *ngIf="allUnreadMessageNotifications$ | async as allUnreadMessageNotifications"
        mat-button
        [insMenuTriggerFor]="msgsMenu"
        class="mat-button my-0 mx-2 p-0 h-100 nav-button"
        [ngStyle]="{
          color: allUnreadMessageNotifications.length > 0 ? 'red' : 'inherit',
          background: allUnreadMessageNotifications.length > 0 ? 'white' : 'inherit'
        }"
      >
        <mat-icon class="notranslate">message</mat-icon>
        {{ allUnreadMessageNotifications?.length > INITIAL_UNREAD_MESSAGES_LIMIT ? INITIAL_UNREAD_MESSAGES_LIMIT + '+' : allUnreadMessageNotifications.length }}
      </button>
      <mat-menu
        #msgsMenu="matMenu"
        xPosition="after"
        yPosition="below"
        overlapTrigger="false"
      >
        <ng-container *ngIf="paginatedUnreadMessageNotifications$ | async as paginatedUnreadMessageNotifications; else loadingSpinner">

          <ng-container *ngIf="paginatedUnreadMessageNotifications.length === 0; else unreadMessages">
            <button mat-menu-item>
              <span>No unread messages</span>
            </button>
          </ng-container>
          
          <ng-template #unreadMessages>
            <div 
              class="maxHeight400px overflow-auto"
              insOnScrollToBottom
              (scrolledToBottom)="loadMoreUnreadMessages()"
            >
              <ng-container *ngFor="let unreadMessageNotification of paginatedUnreadMessageNotifications; trackBy: trackByMessageId">
                <button
                  mat-menu-item
                  (click)="handleUnreadMessageNotificationClicked(unreadMessageNotification)"
                >
                  {{ unreadMessageNotification.name }}
                </button>
              </ng-container>

              <div *ngIf="loadingMessages" mat-menu-item>
                <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
              </div>
            </div>
          </ng-template>

        </ng-container>

        <ng-template #loadingSpinner>
          <div class="d-flex gap-2 justify-content-center align-items-center">
            <div>Loading</div><mat-spinner diameter="20"></mat-spinner>
          </div>
        </ng-template>
      </mat-menu>

      <button
        mat-button
        class="text-white"
        [insMenuTriggerFor]="flagMenu"
        style="margin-right:14px;"
      >
        Language
      </button>
      <mat-menu
        #flagMenu="matMenu"
        xPosition="after"
        yPosition="below"
        overlapTrigger="false"
      >
        <button
          mat-menu-item
          *ngFor="let language of languageList"
          (click)="switchLanguage(language)"
        >
          <span>&nbsp;{{ language }}</span>
        </button>
      </mat-menu>

      <button
        mat-icon-button
        [insMenuTriggerFor]="profileSettings"
      >
        <mat-icon class="notranslate">person</mat-icon>
      </button>
      <mat-menu
        #profileSettings="matMenu"
        x-position="after"
        y-position="below"
        overlapTrigger="false"
      >
        <button mat-menu-item [routerLink]="['/app/profile']">
          <mat-icon>mail_outline</mat-icon><span>Profile</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon><span>Logout</span>
        </button>
      </mat-menu>
    </div>

    <div class="top-nav-right" id="googleTranslateHeader">
      <div id="google_translate_element_main"></div>
    </div>
  </div>
</section>
