import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-user-group-delete',
  templateUrl: './confirm-user-group-delete.component.html',
})
export class ConfirmUserGroupDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmUserGroupDeleteDialogComponent>
  ) {}
}
