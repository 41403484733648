// @ts-strict-ignore
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { FlagColorsService } from '../../../services/flagColors.service';
import { IntegrationsDialogComponent } from '../../../dialogs/integrations-dialog.component';

@Component({
  selector: 'yesno-edit',
  templateUrl: './yesno-edit.component.html',
  providers: [FlagColorsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoEditComponent implements OnInit {
  @Input() question: any;
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() openTriggerDialog: (initialId: string) => void;

  public flagColors = null;

  constructor(
    public dialog: MatDialog,
    private flagColorsService: FlagColorsService
  ) {}

  ngOnInit() {
    this.flagColors = this.flagColorsService.getFlagColors();
    if (!this.question.offeredAnswers) {
      this.question.offeredAnswers = [
        {
          id: this.generateRandomID(32),
          value: 'No',
        },
        {
          id: this.generateRandomID(32),
          value: 'Yes',
        },
      ];
    }
    // make sure values are correct from switching questions
    if (
      this.question.offeredAnswers[0].value !== 'No' ||
      this.question.offeredAnswers[1].value !== 'Yes'
    ) {
      this.question.offeredAnswers[0].value = 'No';
      this.question.offeredAnswers[1].value = 'Yes';
    }
    if (this.question.offeredAnswers.length > 2) {
      this.question.offeredAnswers = [
        this.question.offeredAnswers[0],
        this.question.offeredAnswers[1],
      ];
    }
  }

  openIntegrationsDialog(answer) {
    const dialogRef = this.dialog.open(IntegrationsDialogComponent);
    dialogRef.componentInstance.answer = answer;
  }

  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
