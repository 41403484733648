import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, firstValueFrom, map } from 'rxjs';
import {
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  MultiFactorResolver,
  TotpMultiFactorGenerator,
  Unsubscribe,
  User,
  UserCredential,
  signOut,
  TotpSecret,
  sendEmailVerification,
  MultiFactorError,
  signInWithCustomToken,
  updatePassword,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import type { RequireAtLeastOneKey } from '@insig-health/insig-types/type-utils';
import { AuthTokenResponse, AuthenticationService } from '@insig-health/api/auth-api';
import { default as jwtDecode } from 'jwt-decode';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';


@Injectable({
  providedIn: 'root',
})
export class GcpIpAuthService implements OnDestroy {
  private _onAuthStateChangedUnsubscribe: Unsubscribe;
  private _onAuthStateChanged$ = new ReplaySubject<User | null>(1);

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
  ) {
    const auth = getAuth();
    this._onAuthStateChangedUnsubscribe = onAuthStateChanged(auth, async (user) => {
      this._onAuthStateChanged$.next(user);
    });
  }

  ngOnDestroy(): void {
    this._onAuthStateChangedUnsubscribe?.();
  }

  getAuthStateChanged(): Observable<User | null> {
    return this._onAuthStateChanged$.asObservable();
  }

  async signIn(email: string, password: string): Promise<RequireAtLeastOneKey<{ user: UserCredential, mfaResolver: MultiFactorResolver }>> {
    const auth = getAuth();
    try {
      return { user: await signInWithEmailAndPassword(auth, email, password) };
    } catch (error) {
      const multiFactorError = error as MultiFactorError;
      if (multiFactorError.code === 'auth/multi-factor-auth-required') {
        return { mfaResolver: getMultiFactorResolver(auth, multiFactorError) };
      } else {
        throw error;
      }
    }
  }

  async signInWithMfa(mfaResolver: MultiFactorResolver, mfaEnrollmentId: string, oneTimePassword: string): Promise<UserCredential> {
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(mfaEnrollmentId, oneTimePassword);
    return mfaResolver.resolveSignIn(multiFactorAssertion);
  }

  async signInWithCustomToken(customToken: string): Promise<UserCredential> {
    const auth = getAuth();
    return signInWithCustomToken(auth, customToken);
  }

  async isSignedInWithCustomToken(): Promise<boolean> {
    const user = this.getCurrentUser();
    if (!user) {
      return false;
    }

    const idToken = await user.getIdToken();
    const decodedToken = jwtDecode(idToken) as { firebase: { sign_in_provider: string } };
    return decodedToken.firebase.sign_in_provider === 'custom';
  }

  async isUserDoctor(user: User): Promise<boolean> {
    const idToken = await user.getIdToken();
    const decodedToken = jwtDecode(idToken) as { firebase_doctor_id?: string };
    return !!decodedToken.firebase_doctor_id;
  }

  async isUserTiaApproved(user: User): Promise<boolean> {
    const idToken = await user.getIdToken();
    const decodedToken = jwtDecode(idToken) as { tia?: boolean };
    return !!decodedToken.tia;
  }

  async signOut(): Promise<void> {
    await this.signOutOfSpring();
    const auth = getAuth();
    return await signOut(auth);
  }

  isLoggedIn(): Observable<boolean> {
    return this._onAuthStateChanged$.pipe(map((user) => !!user));
  }

  getCurrentUser(): User | null {
    return getAuth().currentUser;
  }

  async getTotpSecret(): Promise<TotpSecret> {
    const user = this.getCurrentUser();
    if (!user) {
      throw new Error('User is not logged in');
    }
    const multiFactorSession = await multiFactor(user).getSession();
    return TotpMultiFactorGenerator.generateSecret(multiFactorSession);
  }

  async enrollMfa(totpSecret: TotpSecret, verificationCode: string, displayName: string): Promise<void> {
    const user = this.getCurrentUser();
    if (!user) {
      throw new Error('User is not logged in');
    }
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, verificationCode);
    return multiFactor(user).enroll(multiFactorAssertion, displayName);
  }

  async unenrollMfa(): Promise<void> {
    const user = this.getCurrentUser();
    if (!user) {
      throw new Error('User is not logged in');
    }
    const multiFactorUser = multiFactor(user);
    const multiFactorInfo = multiFactorUser.enrolledFactors[0];
    if (!multiFactorInfo) {
      throw new Error('User does not have any enrolled factors');
    }
    await multiFactor(user).unenroll(multiFactorInfo);
  }

  sendEmailVerification(): Promise<void> {
    const user = this.getCurrentUser();
    if (!user) {
      throw new Error('User is not logged in');
    }
    return sendEmailVerification(user);
  }

  async getFirebaseCustomToken(): Promise<string> {
    let response: AuthTokenResponse;
    try {
      response = await firstValueFrom(this.authenticationService.getFirebaseCustomToken());
    } catch (error) {
      throw new Error('Failed to get custom token');
    }
    const customToken = response.custom_token;
    if (!customToken) {
      throw new Error('Missing custom token from response');
    } else {
      return customToken;
    }
  }

  async getRequestWithIdToken<T>(request: HttpRequest<T>, user: User): Promise<HttpRequest<T>> {
    const idToken = await user.getIdToken();
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${idToken}`,
      },
      withCredentials: true,
    });
  }

  async isMfaEnrolled(): Promise<boolean> {
    let isMfaEnrolled = false;
    try {
      await this.getTotpSecret();
    } catch (error) {
      if ((error as firebase.auth.Error).code === 'auth/maximum-second-factor-count-exceeded') {
        isMfaEnrolled = true;
      }
    }
    return isMfaEnrolled;
  }

  async setPassword(password: string): Promise<void> {
    const user = this.getCurrentUser();
    if (!user) {
      throw new Error('User is not logged in');
    }
    return updatePassword(user, password);
  }

  private async signOutOfSpring(): Promise<void> {
    const url = `${JAVA_BACKEND_ENDPOINT}logout`;
    await firstValueFrom(this.http.post(url, undefined)).catch(() => { /* ignore errors */ });
  }
}
