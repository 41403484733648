// @ts-strict-ignore
import { Injectable } from '@angular/core';

import { Survey } from 'insig-types/surveys/survey';
import { Page } from 'insig-types/surveys/page';

import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import 'firebase/database';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})

// this loads an individual survey for edit or launch
export class LoadSurveyService {
  constructor() {}

  async getUserSurveyFromFirestore(
    userId: string,
    surveyId: string
  ): Promise<Survey> {
    return this.loadSurveyFromFirestore(
      firebase
        .firestore()
        .collection('userSurveys')
        .doc('surveyData')
        .collection(userId)
        .doc(surveyId)
    );
  }

  async getUserSurveysFromFirestore(userId: string): Promise<Survey[]> {
    return firebase
      .firestore()
      .collection('userSurveys')
      .doc('surveyData')
      .collection(userId)
      .get()
      .then((querySnapshot) =>
        this.loadSurveysFromFirestore(querySnapshot.docs.map((doc) => doc.ref))
      );
  }

  async getUserSurveyShortlistFromFirestore(
    userId: string
  ): Promise<
    Array<{
      id: string;
      name: string;
      folder: string | null;
      feedbackEnabled: boolean;
    }>
  > {
    const baseReference = firebase
      .firestore()
      .collection('userSurveys')
      .doc('surveyData')
      .collection(userId);
    return await baseReference.get().then((querySnapshot) =>
      querySnapshot.docs
        .map((document) => document.data())
        .map(({ name, id, folder, feedbackEnabled }) => ({
          name,
          id,
          folder,
          feedbackEnabled,
        }))
    ); // Destructure to filter output
  }

  async getLibrarySurveyFromFirestore(surveyId: string): Promise<Survey> {
    return this.loadSurveyFromFirestore(
      firebase
        .firestore()
        .collection('librarySurveys')
        .doc('surveyData')
        .collection('library')
        .doc(surveyId)
    );
  }

  async getLibrarySurveysFromFirestore(): Promise<Survey[]> {
    return firebase
      .firestore()
      .collection('librarySurveys')
      .doc('surveyData')
      .collection('library')
      .get()
      .then((querySnapshot) =>
        this.loadSurveysFromFirestore(querySnapshot.docs.map((doc) => doc.ref))
      );
  }

  watchLibrarySurveysFromFirestore(): Observable<Survey[]> {
    return new Observable<Survey[]>((observer) => {
      return firebase
      .firestore()
      .collection('librarySurveys')
      .doc('surveyData')
      .collection('library')
      .onSnapshot({
        next: (snapshot) => {
          let surveys: Survey[] = [];
          const documents = snapshot.docs;
          surveys = documents.map((doc) => {
            return doc.data() as Survey;
          });
          observer.next(surveys);
        },
        error: (error) => {
          console.error(error);
          observer.error(error);
        }
      });
    });
  }

  async getLibrarySurveyShortlistFromFirestore(): Promise<
    Array<{ id: string; name: string; folder: string | null }>
  > {
    const baseReference = firebase
      .firestore()
      .collection('librarySurveys')
      .doc('surveyData')
      .collection('library');
    return await baseReference
      .get()
      .then((querySnapshot) =>
        querySnapshot.docs
          .map((document) => document.data())
          .map(({ name, id, folder }) => ({ name, id, folder }))
      ); // Destructure to filter output
  }

  async loadSurveysFromFirestore(
    baseReferences: firebase.firestore.DocumentReference[]
  ): Promise<Survey[]> {
    return Promise.all(baseReferences.map(this.loadSurveyFromFirestore));
  }
  async loadSurveyFromFirestore(
    baseReference: firebase.firestore.DocumentReference
  ): Promise<Survey> {
    const baseDocument = await baseReference.get();
    if (baseDocument.exists) {
      const survey = baseDocument.data() as any;
      const pages = await baseReference
        .collection('pages')
        .get()
        .then((querySnapshot) =>
          querySnapshot.docs
            .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
            .map((pageSnapshot) => pageSnapshot.data())
        );
      for (const pageNumber in pages) {
        if (pages[pageNumber]) {
          const elements = await baseReference
            .collection('pages')
            .doc(pageNumber)
            .collection('elements')
            .get()
            .then((querySnapshot) =>
              querySnapshot.docs
                .sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10))
                .map((elementSnapshot) => elementSnapshot.data())
            );
          pages[pageNumber].elements = elements;
        }
      }
      survey.pages = pages;
      return survey;
    } else {
      throw new Error('survey not found: ' + baseReference.path);
    }
  }

  watchSurveysFromFirestore(
    baseReferences: firebase.firestore.DocumentReference[]
  ): Observable<Survey[]> {
    return zip(baseReferences.map((ref) => this.watchSurveyFromFirestore(ref)));
  }
  watchSurveyFromFirestore(
    baseReference: firebase.firestore.DocumentReference
  ): Observable<Survey> {
    const surveyObservable = new Observable<
      firebase.firestore.DocumentSnapshot
    >((surveyObserver) =>
      baseReference.onSnapshot(surveyObserver.next, surveyObserver.error)
    );
    const pagesObservable = new Observable<firebase.firestore.QuerySnapshot>(
      (pagesObserver) =>
        baseReference
          .collection('pages')
          .onSnapshot(pagesObserver.next, pagesObserver.error)
    );
    const sortNumericAscending = (a, b) =>
      parseInt(a.id, 10) - parseInt(b.id, 10);
    return pagesObservable.pipe(
      map((pagesSnapshot) => {
        // Get all element observables as well, in numeric order by page
        return zip([
          surveyObservable,
          pagesObservable,
          ...pagesSnapshot.docs.sort(sortNumericAscending).map(
            (pageReference) =>
              new Observable<firebase.firestore.QuerySnapshot>(
                (elementsObserver) =>
                  baseReference
                    .collection('pages')
                    .doc(pageReference.id)
                    .collection('elements')
                    .onSnapshot(elementsObserver.next, elementsObserver.error)
              )
          ),
        ]);
      }),
      map((results) => {
        const survey = (results[0] as firebase.firestore.DocumentSnapshot).data() as Survey;
        const pages = (results[1] as firebase.firestore.QuerySnapshot).docs
          .sort(sortNumericAscending)
          .map((doc) => doc.data() as Page);

        // Parse elements into each page
        for (const pageNumber in pages) {
          if (pages[pageNumber]) {
            const elements = (results[
              pageNumber + 2
            ] as firebase.firestore.QuerySnapshot).docs
              .sort(sortNumericAscending)
              .map((doc) => doc.data() as any);
            pages[pageNumber].elements = elements;
          }
        }

        // Put the pages in the survey
        survey.pages = pages;
        return survey;
      })
    );
  }
} // end service
