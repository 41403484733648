import { Injectable } from '@angular/core';
import { UpdateUserEmailRequest, UserManagementService as GeneratedUserManagementService, SuccessResponse, UserSmsNotification } from '@insig-health/api/user-management-api';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  static readonly MIN_SMS_LENGTH = 10;
  static readonly MAX_SMS_LENGTH = 675;

  constructor(
    private generatedUserManagementService: GeneratedUserManagementService,
  ) {}

  async updateEmail(userId: string, newEmail: UpdateUserEmailRequest): Promise<SuccessResponse> {
    return lastValueFrom(this.generatedUserManagementService.updateUserEmail(userId, newEmail));
  }

  async sendSms(toNumber: string, body: string): Promise<UserSmsNotification> {
    if (body.length < UserManagementService.MIN_SMS_LENGTH || body.length > UserManagementService.MAX_SMS_LENGTH) {
      throw new Error(`SMS body must be between ${UserManagementService.MIN_SMS_LENGTH} and ${UserManagementService.MAX_SMS_LENGTH} characters`);
    }

    const sendSmsRequest = {
      toNumber,
      body,
    };

    return lastValueFrom(this.generatedUserManagementService.sendSms(sendSmsRequest));
  }
}
