// @ts-strict-ignore
import { Component, OnInit, Inject } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'add-users-to-group-dialog',
  templateUrl: './add-users-to-group.component.html',
})
export class AddUsersToGroupDialogComponent implements OnInit {
  public planTitle = '';
  public type: any;
  public selectedUsers: any = [];
  public currentUserName = '';
  public currentUserGroup = '';
  private selectedGroups: any = [];
  private newUsers: any = [];
  public addEmailsForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddUsersToGroupDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.data) {
      this.type = this.data.data.type;
    }
    // (window.document.querySelector(
    //   '.insig-dialog-panel'
    // ) as HTMLElement).style.minWidth = '60%';
  }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.addEmailsForm = this.formBuilder.group({
      email: this.formBuilder.control(null, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  onEnter() {
    console.log(this.addEmailsForm);
    if (!this.addEmailsForm.controls.email.valid) {
      console.log('Invalid email');
      return false;
    }
    if (
      this.selectedUsers.indexOf(this.addEmailsForm.controls.email.value) !== -1
    ) {
      console.log('email already added');
      this.addEmailsForm.controls.email.reset();
      return false;
    }
    this.selectedUsers.push(this.addEmailsForm.controls.email.value);
    this.addEmailsForm.controls.email.reset();
  }

  addNewUsers() {
    this.onEnter();
    const data = this.selectedUsers;
    console.log(data);
    this.dialogRef.close(data);
  }

  addItem(val) {
    if (!this.selectedUsers.includes(val)) {
      this.selectedUsers.push(val);
    }
    this.currentUserName = '';
  }

  removeItem(i) {
    console.log(i);
    console.log(this.selectedUsers[i]);
    this.selectedUsers.splice(i, 1);
  }

  addUserGroupItem(val) {
    if (!this.selectedGroups.includes(val)) {
      this.selectedGroups.push(val);
    }
    this.currentUserGroup = '';
  }

  removeUserGroupItem(val) {
    const index = this.selectedGroups.indexOf(val);
    this.selectedGroups.splice(index, 1);
  }

  addNewUser(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.newUsers.push({ email: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeNewUser(user: any): void {
    const index = this.newUsers.indexOf(user);

    if (index >= 0) {
      this.newUsers.splice(index, 1);
    }
  }
}
