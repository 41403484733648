<mat-card>
  <h1 matDialogTitle>Upload A Profile Image</h1>

  <mat-dialog-content>
    <div class="text-center">
      <img
        style="max-width:25%;height:auto;"
        class="img-responsive center-object"
        *ngIf="!!data.imageUrl"
        [src]="data.imageUrl"
        alt="userProfileImage"
      />
    </div>

    <div class="divider"></div>

    <div class="text-center">
      <button
        mat-raised-button
        *ngIf="!!data.imageUrl"
        (click)="removeUserImage()"
      >
        Remove Image
      </button>
    </div>

    <div class="divider"></div>

    <ngx-file-drop
      (onFileDrop)="onAdded($event)"
      multiple="false"
      accept=".png,.jpg,.jpeg"
      dropZoneClassName="ngxFileDropZone"
      contentClassName="ngxFileDropContent"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <span>Drop file here</span>
        <span class="px-3"><!-- divider --></span>
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="openFileSelector()"
        >
          Browse
        </button>
      </ng-template>
    </ngx-file-drop>

    <div class="divider"></div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="done()">Done</button>
  </mat-dialog-actions>
</mat-card>
