// @ts-strict-ignore
import { Component, Input, ViewChild, ElementRef, OnInit, EventEmitter, OnChanges, SimpleChanges, Output } from '@angular/core';
import { SignatureTermsDialogComponent } from '../../../dialogs/signature-terms-dialog.component';
import { SignatureDialogComponent } from '../signature/signature-dialog.component';

import { MatDialog } from '@angular/material/dialog';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'shareNote-question',
  templateUrl: './shareNote.component.html',
  styleUrls: ['./shareNote.style.css'],
})
export class ShareNoteComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  @ViewChild('container') public container: ElementRef;
  maxWidth = 500;

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.maxWidth = this.container.nativeElement.offsetWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  startSignature() {
    const dialogRef = this.dialog.open(SignatureDialogComponent);
    dialogRef.componentInstance.question = this.question;
    dialogRef.afterClosed().subscribe((_result) => {
      this.question.response.answer = this.question.canvas.toDataURL();
      this.question.canvas = null;
    });
  } // end func

  openTerms() {
    const dialogRef = this.dialog.open(SignatureTermsDialogComponent);
    dialogRef.componentInstance.termsContent = this.question.termsContent;
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        this.question.response.responseTerms = true;
      } else if (result === false) {
        this.question.response.responseTerms = false;
      }
    });
  } // end terms func
} // end component
