<mat-card [ngStyle]="{ 'min-width': '65vw' }">
  <h1 matDialogTitle *ngIf="!patientView">
    {{ patient ? patient.first + ' ' + patient.last : 'Patient' }}'s Notes
  </h1>

  <mat-dialog-content>
    <mat-tab-group>
      <mat-tab label="Notes">
        <h5 class="text-center" *ngIf="caseNotes?.length === 0">
          There are currently no notes for this visit.
        </h5>
        <div class="container">
          <div class="row">
            <div class="card col-md-6 col-sm-12" *ngFor="let note of caseNotes">
              <div class="card-content text-center">
                <p class="card-subtitle">
                  <b>{{ patientView ? appointmentType : note.name }}</b>
                </p>
                <!-- <span>{{ note.time | date: 'fullDate' }}</span> -->
                <span>{{
                  note.time ? (note.time | date: 'MMM d, y, h:mm a') : '-'
                }}</span>
              </div>
              <div class="card-action" *ngIf="!patientView">
                <button
                  mat-raised-button
                  class="float-right"
                  (click)="editNote(note)"
                >
                  Open Note
                </button>
                <button mat-raised-button (click)="viewNote(note)">
                  Quick View
                </button>
              </div>
              <div class="card-action" *ngIf="patientView">
                <!-- <button mat-raised-button (click)="openNotePatient(note)">Open Note</button> -->
                <!-- <button mat-raised-button class="float-right" [insMenuTriggerFor]="docMenu">Documents</button> -->
                <button
                  mat-raised-button
                  class="float-right"
                  (click)="openNotePatient(note)"
                >
                  Open
                </button>
                <!-- <ins-menu #docMenu="insMenu">
                  <button mat-menu-item *ngFor="let doc of note.addedDocs" (click)="openDocument(doc);"> {{doc.name}} </button>
                  <button mat-menu-item *ngFor="let pdfDoc of note.morePDFs" (click)="openDocument(pdfDoc);"> {{pdfDoc.name}} </button>
                  <span *ngIf="!note.addedDocs || note.addedDocs.length == 0">Currently no documents for this case</span>
                </ins-menu> -->
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Documents">
        <!-- !noteObj?.pdfPath &&
            (!allDocs || allDocs.length === 0) &&
            cleanPdfUrls.length === 0 -->
        <div *ngIf="!allDocs || allDocs.length === 0" class="text-center p-5">
          <h4>There are currently no documents to display</h4>
        </div>

        <div class="offset-sm-2 col-sm-8 col-xs-12">
          <ng-container *ngFor="let document of allDocs; let i = index">
            <div class="card px-3 pb-3 pt-0 text-left">
              <h4 class="pl-3 d-flex">
                <mat-icon class="notranslate">attach_file</mat-icon> &nbsp;{{
                  document.name
                }}
              </h4>
              <div class="row container">
                <button
                  mat-button
                  *ngIf="document.custom"
                  (click)="toggleExpandedDocument(i)"
                >
                  <mat-icon class="notranslate">open_in_browser</mat-icon>
                  {{ !expandDoc[i] ? 'Open' : 'Close' }}
                </button>
                <button
                  mat-button
                  *ngIf="!document.custom"
                  (click)="generatePDF(document)"
                >
                  <mat-icon class="notranslate">open_in_browser</mat-icon> Open
                </button>
                <!-- <button mat-button (click)="sendDoc(i, 'email')">
                  <mat-icon class="notranslate">email</mat-icon> Email
                </button>
                <button mat-button (click)="sendDoc(i, 'fax')">
                  <mat-icon class="notranslate">send</mat-icon> Fax
                </button> -->
              </div>
              <!-- <requisition-form
                class="mt-2"
                *ngIf="expandDoc[i]"
                [patientView]="true"
                [(document)]="noteObj.addedDocs[i]"
                [companyID]="appointmentData.companyID"
                [noteID]="caseID"
              ></requisition-form> -->
            </div>
          </ng-container>
          <!-- //end addedDocs -->
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-raised-button (click)="dialogRef.close()">Close</button>
  </mat-dialog-actions>
</mat-card>
