// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';
import { PhysicalGridService } from 'insig-app/services/physicalGrid.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'physicalgrid-edit',
  templateUrl: './physicalgrid-edit.component.html',
  providers: [PhysicalGridService],
})
export class PhysicalGridEditComponent implements OnInit {
  @Input() question: any;
  public questionExample: any = {
    physical: {},
    response: {
      physicalGrid: {},
      checkboxes: {},
    },
  };

  public defaultStructure = this.physicalGridService.getDefaultStructure();
  public checkboxes = this.physicalGridService.getCheckboxes();
  public hideGeneral = false;
  public showBP = false;

  constructor(
    private physicalGridService: PhysicalGridService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    if (!this.question.standard) {
      this.question.standard = {};
    }
    // setting up response dictionary
    const tempQuestion = {};
    const tempResponse = {};
    const thisComponent = this;
    this.defaultStructure.forEach((value) => {
      tempQuestion[value.id] = false;
      tempResponse[value.id] = {};
      thisComponent.question.standard[value.id] = {};
      value.sections.forEach((value2) => {
        tempResponse[value.id][value2.id] = value2;
      });
    });
    this.questionExample.physical = tempQuestion;
    this.questionExample.response.physicalGrid = tempResponse;
  } // end init

  explainIcon() {
    setTimeout(() => {
      this.snackbar.open(
        'This icon will autofill the text you enter under the description column.'
      );
    }, 4000);
  }
} // end component
