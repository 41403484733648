// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AWSCLOUDFUNCTIONSENDPOINT, LambdaResponse, STRIPE_KEY } from '@insig-health/config/config';

import { take } from 'rxjs/operators';
import * as Stripe from 'stripe';
import { DoctorScheduleReindexService } from '@insig-health/services/doctor-schedule-reindex/doctor-schedule-reindex.service';
import { firstValueFrom } from 'rxjs';

export type CompletedVirtualCareResponse = {
  headers: HttpHeaders;
  body: string;
  statusCode: number;
};

export type CancelAppointmentStandardResponse = {
  headers: HttpHeaders;
  body: string;
  statusCode: number;
};

export type NoShowVirtualCareStandardResponse = {
  headers: HttpHeaders;
  body: string;
  statusCode: number;
};

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private getSubscriptionStatusURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/getSubscriptionStatus';
  private getSubscriptionStatusCompanyIDURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/getSubscriptionStatusCompanyID';
  private getSubscriptionStatusUserIDURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/getSubscriptionStatusUserID';
  private checkActiveCustomerIDURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/checkActiveCustomerID';
  private quickCheckActiveCustomerIDPatientURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/quickCheckActiveCustomerIDPatient';
  private deleteCustomerIDURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/deleteCustomerID';
  private createCustomerURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/paymentCreateCustomer';
  private paymentCreateCustomerQuestionnaireURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'surveys/paymentCreateCustomerQuestionnaire';
  private createEmployeeCustomerURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/createEmployeeCustomer';
  private createEmployeeSubscriptionURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/createEmployeeSubscription';
  private createEmployerCustomerURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/paymentCreateEmployerCustomer';
  private cancelAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/cancelAppointment';
  private cancelAppointmentStandardURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/cancelAppointmentStandard';
  private confirmAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/confirmAppointment';
  private cancelAppointmentRequestUrl =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/requestAppointmentCancellationPatient';
  private createSubscriptionURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/paymentCreateSubscription';
  private createConnectAccountURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/createConnectAccount';
  private updateConnectAccountURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/updateConnectAccount';
  private connectStripeAccountURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/connectStripeAccount';
  private createChargeURL = AWSCLOUDFUNCTIONSENDPOINT + 'payments/createCharge';
  private completedVirtualCareURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/completedVirtualCare';
  private noShowVirtualCareStandardURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/noShowVirtualCareStandard';
  private createVirtualCareChargeStandardURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'book-appointment/virtualCarePayment';
  private manualBookingURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'book-appointment/manualBooking';
  private inviteToGroupAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'book-appointment/inviteToGroupAppointment';
  private getStripeConnectAccountDetailsURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/getStripeConnectAccountDetails';
  private uploadIDPhotoURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'payments/uploadIDPhoto';

  public STRIPECURRENCIES = {
    cad: true,
    usd: true,
    mxn: true,
  };

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });


  constructor(
    private http: HttpClient,
    private doctorScheduleReindexService: DoctorScheduleReindexService,
  ) {}

  getStripeKey(): any {
    return STRIPE_KEY;
  }

  /**
   * Creates a customer profile using the AWS endpoint.
   * @param  {string}          IDToken     A valid Insig custom token minted by Google
   * @param  {any}             stripeToken A valid token object minted by stripe.createToken
   * @param  {string}          userType    User type specification: 'patient' if the user is a patient
   * @return {Promise<any>}                Promise containing success response object (currently unused)
   */
  async createCustomer(
    IDToken: string,
    stripeToken: any,
    userType: string,
  ): Promise<any> {
    return this.http
      .post(
        this.createCustomerURL,
        {
          IDToken,
          stripeToken: stripeToken.id,
          userType,
        },
        { headers: this.headers },
      )
      .toPromise();
  }


  /**
   * Creates a customer profile using the AWS endpoint.
   * @param  {string}          IDToken     A valid Insig custom token minted by Google
   * @param  {any}             stripeToken A valid token object minted by stripe.createToken
   * @param  {string}          userType    User type specification: 'patient' if the user is a patient
   * @return {Promise<any>}                Promise containing success response object (currently unused)
   */
  async createCustomerQuestionnaire(
    companyID: string,
    stripeToken: any,
    name: string,
    email: string,
  ): Promise<any> {

    return this.http
      .post(
        this.paymentCreateCustomerQuestionnaireURL,
        {
          companyID,
          stripeToken: stripeToken.id,
          name,
          email,
        },
        { headers: this.headers },
      )
      .toPromise();
  }


  /**
   * Creates a customerId on a patient if it does not already exist, then returns the customerId.
   * @author Colin Tong <colin@insighealth.com>
   * @param validators.idToken firebase id token
   * @param stripeToken stripe token from {@link [stripe.createToken()]https://stripe.com/docs/stripe-js/reference#stripe-create-token}
   * @return The customer id
   */
  async createEmployeeCustomer(
    stripeToken: Stripe.tokens.IToken,
    validators: { idToken: string },
  ): Promise<string> {
    return this.http
      .post(
        this.createEmployeeCustomerURL,
        { idToken: validators.idToken, stripeToken: stripeToken.id },
        { headers: this.headers, responseType: 'text' },
      )
      .toPromise();
  }

  async createEmployeeSubscription(
    idToken: string,
    subscriptionId: string,
  ): Promise<string> {
    return this.http
      .post(
        this.createEmployeeSubscriptionURL,
        { idToken, subscriptionId },
        { headers: this.headers, responseType: 'text' },
      )
      .toPromise();
  }

  async createEmployerCustomer(
    IDToken: string,
    email: string,
    employerID: string,
    stripeToken: any,
  ): Promise<any> {
    return this.http
      .post(
        this.createEmployerCustomerURL,
        {
          IDToken,
          email,
          employerID,
          stripeToken: stripeToken.id,
        },
        { headers: this.headers },
      )
      .toPromise();
  }

  async connectStripeAccount(IDToken, companyBankAccount): Promise<any> {
    if (location.search.indexOf('?scope') === 0) {
      const userStripeCode = location.search.split('code=')[1];
      console.log('User Stripe code: ', userStripeCode);
      return this.http
        .post(
          this.connectStripeAccountURL,
          {
            IDToken,
            userStripeCode,
            companyBankAccount,
          },
          { headers: this.headers },
        )
        .toPromise();
    }
  }

  cancelAppointmentStandard(
    linkID: string,
    IDToken?: string,
    reason?: string,
    firstName?: string,
    lastName?: string,
  ): Promise<CancelAppointmentStandardResponse> {
    return this.http
      .post<CancelAppointmentStandardResponse>(
        this.cancelAppointmentStandardURL,
        {
          linkID,
          IDToken,
          reason,
          firstName,
          lastName,
        },
        { headers: this.headers },
      )
      .pipe(take(1))
      .toPromise();
  }

  confirmAppointment(linkID: string, IDToken?: string, _reason?): Promise<any> {
    return this.http
      .post(
        this.confirmAppointmentURL,
        {
          linkID,
          IDToken,
        },
        { headers: this.headers, responseType: 'text' },
      )
      .pipe(take(1))
      .toPromise();
  }

  cancelAppointmentRequest(body: {
    linkId: string,
    firstName?: string,
    lastName?: string,
  }, idToken?: string): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    if (idToken) {
      headers = headers.set('Authorization', `Bearer ${idToken}`);
    }
    return firstValueFrom(this.http.post(
      this.cancelAppointmentRequestUrl,
      body,
      {
        headers,
        withCredentials: !!idToken,
      },
    ));
  }

  noShowVirtualCareStandard(linkID, IDToken): Promise<NoShowVirtualCareStandardResponse | undefined> {
    return this.http
      .post<NoShowVirtualCareStandardResponse>(
        this.noShowVirtualCareStandardURL,
        {
          linkID,
          IDToken,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return undefined;
      });
  }

  completedVirtualCare(linkID, IDToken): Promise<CompletedVirtualCareResponse | undefined> {
    return this.http
      .post<CompletedVirtualCareResponse>(
        this.completedVirtualCareURL,
        {
          linkID,
          IDToken,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return undefined;
      });
  }

  createSubscription(
    IDToken,
    subscriptionID,
    userIDPurchasedFor?,
    userType: any = false,
  ): any {
    return this.http
      .post(
        this.createSubscriptionURL,
        {
          IDToken,
          subscriptionID,
          userIDPurchasedFor,
          userType,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getSubscriptionStatusCompanyID(companyID, _type: any = 'all'): any {
    return this.http
      .post(
        this.getSubscriptionStatusCompanyIDURL,
        {
          companyID,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  getSubscriptionStatusUserID(userID, type: any = 'all'): any {
    return this.http
      .post(
        this.getSubscriptionStatusUserIDURL,
        {
          userID,
          type,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  }

  /**
   * Returns whether or not the user has a valid subscription.
   * @param  {string}  IDToken   A valid Insig custom token minted by Google
   * @param  {boolean} corporate Whether or not to check a corporate subscription
   * @return {Promise<boolean>}  A promise containing whether or not the user has a valid subscription
   */
  async getSubscriptionStatus(IDToken, corporate): Promise<any> {
    return this.http
      .post<LambdaResponse>(
        this.getSubscriptionStatusURL,
        {
          IDToken,
          corporate,
        },
        { headers: this.headers },
      )
      .toPromise();
  }

  quickCheckActiveCustomerIDPatient(userID): any {
    return this.http
      .post(
        this.quickCheckActiveCustomerIDPatientURL,
        {
          userID,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkActiveCustomerID(IDToken, userType): any {
    return this.http
      .post(
        this.checkActiveCustomerIDURL,
        {
          IDToken,
          userType,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteCustomerID(IDToken, userType): any {
    return this.http
      .post(
        this.deleteCustomerIDURL,
        {
          IDToken,
          userType,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  createCharge(amount, IDToken, userType, description): any {
    return this.http
      .post(
        this.createChargeURL,
        {
          amount,
          IDToken,
          userType,
          description,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async createVirtualCareChargeStandard(
    IDToken,
    appointmentConfig,
  ): Promise<any> {
    const output = await this.http
      .post(
        this.createVirtualCareChargeStandardURL,
        {
          IDToken,
          appointmentConfig,
        },
        { headers: this.headers, observe: 'response', responseType: 'text' },
      )
      .toPromise();

    await this.doctorScheduleReindexService.reindexSchedule(appointmentConfig.doctor.uid, appointmentConfig.companyID);

    return output;
  }

  async inviteToGroupAppointment(appointmentID, IDToken, patient): Promise<any> {
    console.log(IDToken);
    return this.http
      .post(
        this.inviteToGroupAppointmentURL,
        {
          appointmentID,
          IDToken,
          patient,
        },
        { headers: this.headers, observe: 'response', responseType: 'text' },
      )
      .toPromise();
  }

  async manualBooking(appointmentConfig, IDToken): Promise<any> {
    console.log(IDToken);
    const output = await this.http
      .post(
        this.manualBookingURL,
        {
          appointmentConfig,
          IDToken,
        },
        { headers: this.headers, observe: 'response', responseType: 'text' },
      )
      .toPromise();

    await this.doctorScheduleReindexService.reindexSchedule(appointmentConfig.doctor.uid, appointmentConfig.companyID);

    return output;
  }

  // createTransferCharge(amount, IDToken, connectID, userType): any {
  //   return this.http.post(this.createTransferChargeURL, {
  //       amount: amount,
  //       IDToken: IDToken,
  //       connectID: connectID,
  //       userType: userType,
  //   }, { headers: this.headers }).toPromise()
  //     .then(response => {
  //       return response;
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     })
  // }

  async getStripeConnectAccountDetails(
    IDToken,
    companyBankAccount,
  ): Promise<any> {
    return await this.http
      .post(
        this.getStripeConnectAccountDetailsURL,
        {
          IDToken,
          companyBankAccount,
        },
        { headers: this.headers },
      )
      .toPromise();
  }

  uploadIDPhoto(fileName, filePath, IDToken, companyBankAccount): any {
    return this.http
      .post(
        this.uploadIDPhotoURL,
        {
          fileName,
          filePath,
          IDToken,
          companyBankAccount,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  createConnectAccount(userInfoObj, IDToken, companyBankAccount): any {
    return this.http
      .post(
        this.createConnectAccountURL,
        {
          IDToken,
          companyBankAccount,
          // basic info
          email: userInfoObj.email,
          firstName: userInfoObj.firstName,
          lastName: userInfoObj.lastName,
          // dob
          dobDay: userInfoObj.dobDay,
          dobMonth: userInfoObj.dobMonth,
          dobYear: userInfoObj.dobYear,
          // address
          city: userInfoObj.city,
          address: userInfoObj.address,
          postalCode: userInfoObj.postalCode,
          state: userInfoObj.state,
          // business stuff
          businessType: userInfoObj.businessType,
          taxID: userInfoObj.taxID,
          businessName: userInfoObj.businessName,
          // bank account
          externalAccount: userInfoObj.externalAccount,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  updateConnectAccount(userInfoObj, IDToken, companyBankAccount): any {
    return this.http
      .post(
        this.updateConnectAccountURL,
        {
          // IDToken
          IDToken,
          companyBankAccount,
          // basic info
          email: userInfoObj.email,
          firstName: userInfoObj.firstName,
          lastName: userInfoObj.lastName,
          // dob
          dobDay: userInfoObj.dobDay,
          dobMonth: userInfoObj.dobMonth,
          dobYear: userInfoObj.dobYear,
          // address
          city: userInfoObj.city,
          address: userInfoObj.address,
          postalCode: userInfoObj.postalCode,
          state: userInfoObj.state,
          // business stuff
          businessType: userInfoObj.businessType,
          taxID: userInfoObj.taxID,
          businessName: userInfoObj.businessName,
          // bank account
          externalAccount: userInfoObj.externalAccount,
        },
        { headers: this.headers },
      )
      .toPromise()
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  }
} // end service
