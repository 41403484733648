// @ts-strict-ignore
/// <reference types="@types/googlemaps" />

import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Injectable,
  AfterViewInit,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { PRODUCTION } from '@insig-health/config/config';
import { MailchimpService } from 'insig-app/services/mailchimp.service';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PatientTermsDialogComponent } from 'insig-app/global/patient/dialogs/terms/patient-terms-dialog.component';

import { Subscription } from 'rxjs';
import firebase from 'firebase/compat/app';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';
import { PatientTermsOfUseComponent } from '@insig-health/patient-terms-of-use/patient-terms-of-use.component';
import { GooglePlaceService } from '@insig-health/services/google-place/google-place.service';

@Component({
  selector: 'patient-login',
  templateUrl: './patient-login.component.html',
  styleUrls: [
    './patient-login.component.style.scss',
    '../../../styles/surveys/patient-login.scss',
  ],
  providers: [
    PatientUserDataService,
    MailchimpService,
  ],
})
@Injectable()
export class PatientLoginComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  parsedHealthCardInfo = {};
  firstName = '';
  response = {};
  validationForm: UntypedFormGroup;
  @ViewChild('searchAddress')
  public addressElementRef: ElementRef;
  @Input() surveyLoaded: boolean;
  @Input() tabletMode: any;
  @Input() creatingNote: any = false;
  stillLoading = false;
  loading = false;
  @Input() formSubmitted: boolean;
  @Output() formSubmittedChange = new EventEmitter<boolean>();
  @Input() patientInfo: any = {};
  @Output() patientInfoChange = new EventEmitter<any>();
  quickPreview = false;
  quickPreviewVals = {
    first: 'Test',
    last: 'Patient',
    year: 1990,
    day: 1,
    month: 1,
    gender: 'Male',
    terms: true,
    email: 'test@patient.com',
    phone: '1234567890',
    address: 'Test Street',
    postalCode: 'M5E 1X8',
    province: 'ON',
    city: 'Toronto',
    healthCardNumber: '1234567890AA',
  };
  public yearValues = [2010, 2000, 1990, 1950, 1930];
  today = new Date();
  private loginEmail = null;
  private loginPassword = null;
  public signingUp = false;
  public loggingIn = false;
  public onlyLogin = false;
  @Input() preview = false;
  private sub = null;
  param = { value: 'world' };
  public showSelectionSignIn = false;
  public showPatientDemographics = true;
  public showScanDriversLicence = false;
  public showQuickPreview = false;
  @ViewChild('video') video: any;
  @ViewChild('canvas') canvas: any;
  private localStream = null;
  private activeCamera = false;
  public familyID = null;
  private mainAccountUID = null;
  public apptID: string = null;
  public companyID: string = null;
  public manualAppt = false;
  private authSub: Subscription = null;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private patientUserDataService: PatientUserDataService,
    private gcpIpAuthService: GcpIpAuthService,
    private mailchimpService: MailchimpService,
    public dialog: MatDialog,
    private http: HttpClient,
    private dateAndTimeService: DateAndTimeService,
    private googlePlaceService: GooglePlaceService,
  ) { }

  async ngOnInit() {
    this.validationForm = this.buildValidationForm();
    if (this.preview || this.tabletMode === 'true') {
      this.showPatientDemographics = true;
      this.showSelectionSignIn = false;
    }
    // get familyID if applicable
    this.route.queryParams.subscribe((params) => {
      this.familyID = params['familyID'];
      this.apptID = params['apptID'];
    });

    const paramMap = this.route.snapshot.paramMap.get('companyID') ? this.route.paramMap : this.route.parent.paramMap;

    paramMap.subscribe((paramMap) => {
      this.companyID = paramMap.get('companyID');
      // if manual appt dont login, just show check-in
      if (paramMap.get('manualAppt')) {
        this.manualAppt = true;
        this.showPatientDemographics = true;
        this.showSelectionSignIn = false;
      } else if (paramMap.get('uid')) {
        this.showPatientDemographics = true;
        this.showSelectionSignIn = false;
        this.mainAccountUID = paramMap.get('uid');
        this.checkIfLoggedIn(paramMap.get('uid'));
      } else if (paramMap.get('onlyLogin')) {
        this.onlyLogin = true;
        this.loggingIn = true;
        this.showSelectionSignIn = false;
        this.showPatientDemographics = true;
        const expectedUid = this.route.snapshot.queryParamMap.get('uid');
        this.checkIfLoggedIn(expectedUid);
      }
    });
  }

  ngAfterViewInit(): void {
    try {
      // load Places Autocomplete
      this.googlePlaceService.getPlaceResultObservable(this.addressElementRef.nativeElement).subscribe((placeResult) => {
        this.validationForm.controls['address'].setValue(placeResult.formatted_address);
      });
    } catch (error) {
      console.error(error);
    }
  }

  buildValidationForm(): UntypedFormGroup {
    return this.formBuilder.group(
      {
        first: this.formBuilder.control(this.patientInfo.first, [
          Validators.required,
        ]),
        last: this.formBuilder.control(this.patientInfo.last, [
          Validators.required,
        ]),
        email: this.formBuilder.control(this.patientInfo.email),
        phone: this.formBuilder.control(this.patientInfo.phone, [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(14),
        ]),
        address: this.formBuilder.control(this.patientInfo.address),
        healthCardNumber: this.formBuilder.control(
          this.patientInfo.healthCardNumber
        ),
        year: this.formBuilder.control(this.patientInfo.year, [
          Validators.required,
          Validators.min(1900),
          Validators.max(this.today.getFullYear()),
          Validators.minLength(4),
          Validators.maxLength(4),
        ]),
        month: this.formBuilder.control(this.patientInfo.month, [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        day: this.formBuilder.control(this.patientInfo.day, [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          Validators.minLength(1),
          Validators.maxLength(2),
        ]),
        gender: this.formBuilder.control(this.patientInfo.gender, [
          Validators.required,
        ]),
        terms: this.formBuilder.control(false, [Validators.requiredTrue]),
        password: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
        passwordConfirm: this.formBuilder.control(null, [
          Validators.required,
          Validators.minLength(6),
        ]),
      },
      {
        validator: this.checkIfMatchingPasswords('password', 'passwordConfirm'),
      }
    );
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  } // end func

  onResetForm() {
    this.validationForm.reset();
    this.formSubmitted = false;
  }

  signUp(event) {
    // stops form from subbmitting --- onSubmit()
    event.preventDefault();
    if (!this.checkSignUpFormValid(this.validationForm)) {
      return false;
    }
    this.stillLoading = true;
    if (this.tabletMode === 'true') {
      console.log('logging out.');
      this.gcpIpAuthService.signOut();
    }
    this.snackBar.open('Please wait while we create your account...', null, {
      duration: 4000,
    });
    const patientUserData: any = {};
    for (const field in this.validationForm.controls) {
      if (this.validationForm.controls[field]) {
        patientUserData[field] = this.validationForm.controls[field].value;
      }
    }
    patientUserData.lastAcceptedTermsOfUseVersion = PatientTermsOfUseComponent.VERSION;
    delete patientUserData['password'];
    delete patientUserData['passwordConfirm'];
    this.patientInfo = patientUserData;
    this.patientUserDataService
      /** @firestore Firestore migration */
      .createPatientUserProxy(
        patientUserData,
        patientUserData.email,
        this.validationForm.controls['passwordConfirm'].value
      )
      .then((uid) => {
        this.patientInfo.uid = uid;
        this.snackBar.open('Your account has been created!', null, {
          duration: 4000,
        });
        this.onSubmit();
      })
      .catch((error) => {
        if (error.message) {
          this.snackBar.open('Error! ' + error.message, null, {
            duration: 4000,
          });
        } else {
          this.snackBar.open(
            'Error creating account, please check your information!',
            null,
            { duration: 4000 }
          );
        }
        this.stillLoading = false;
      });
    // .createPatientAccount(
    //   patientUserData,
    //   this.validationForm.controls['passwordConfirm'].value
    // )
    // .then(async (result) => {
    //   console.log(result);

    //   let jsonResult = result;
    //   if (!jsonResult.body.code && jsonResult.statusCode === 200) {
    //     this.patientInfo['uid'] = jsonResult.uid;
    //     this.snackBar.open('Your account has been created!', null, {
    //       duration: 4000,
    //     });
    //     this.onSubmit();
    //   } else if (jsonResult.body.message) {
    //     this.snackBar.open('Error! ' + jsonResult.body.message, null, {
    //       duration: 4000,
    //     });
    //     this.stillLoading = false;
    //   } else {
    //     this.snackBar.open(
    //       'Error creating account, please check your information!',
    //       null,
    //       { duration: 4000 }
    //     );
    //     this.stillLoading = false;
    //   }
    // });
  } // end func

  signInWithPatientData(patientID) {
    this.sub = this.patientUserDataService
      .getPatientData(patientID)
      .subscribe(async (snapshot: any) => {
        console.log(snapshot);
        console.log(this.familyID);
        if (snapshot && snapshot.uid) {
          if (this.familyID && this.familyID !== 'false') {
            // set property so you know this is a family member's data
            snapshot.family = true;
            const familyDataSnapshot = await this.patientUserDataService.getOneFamilyMember(
              this.familyID
            );
            const familyData = familyDataSnapshot.data();
            if (familyData) {
              delete snapshot['healthCardNumber'];
              for (const field in familyData.data) {
                if (familyData.data[field]) {
                  snapshot[field] = familyData.data[field];
                }
              }

              snapshot.cpp = familyData.cpp;
            } else {
              console.log('error getting family data');
            }
          }
          console.log(this.patientInfo);
          this.patientInfo = snapshot;
          this.patientInfoChange.emit(this.patientInfo);
          this.formSubmitted = true;
          this.formSubmittedChange.emit(this.formSubmitted);
          window.scrollTo(0, 0);
        }
        this.sub.unsubscribe();
        if (this.tabletMode === 'true') {
          console.log('logging out.');
          this.gcpIpAuthService.signOut();
        }
        this.stillLoading = false;
      });
  }

  checkIfLoggedIn(uid?: string) {
    this.loggingIn = true;
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    this.authSub = this.gcpIpAuthService
      .getAuthStateChanged()
      .subscribe((user) => {
        if (user) {
          if (!!uid && user.uid !== uid) {
            this.snackBar.open('You are not authorized to complete this questionnaire.', undefined, { duration: 4000 });
            this.logout();
          } else {
            console.log(user);
            this.showSelectionSignIn = false;
            this.showPatientDemographics = true;
            this.signInWithPatientData(user.uid);
          }
        }
      });
  }

  login() {
    this.stillLoading = true;
    this.gcpIpAuthService.signOut();
    const snackBar = this.snackBar;
    const thisComponent = this;
    firebase
      .auth()
      .signInWithEmailAndPassword(this.loginEmail, this.loginPassword)
      .then((userCredential) => {
        const firebaseUser = userCredential.user;

        this.signInWithPatientData(firebaseUser.uid);
      })
      .catch((error) => {
        console.log(error);
        // wrong password
        if (error['code'] === 'auth/wrong-password') {
          snackBar.open('Incorrect password!', null, { duration: 4000 });
          // wrong email
        } else if (error['code'] === 'auth/user-not-found') {
          snackBar.open('No user with this email exists!', null, {
            duration: 4000,
          });
          // no internet
        } else if (error['code'] === 'auth/network-request-failed') {
          snackBar.open(
            'Failed to connect! Please ensure that you are connected to the internet.',
            null,
            { duration: 4000 }
          );
        } else {
          snackBar.open(error.message, null, { duration: 4000 });
        }
        thisComponent.stillLoading = false;
      });
  }

  checkSignUpFormValid(form) {
    if (form.controls['year'].value) {
      form.controls['year'].setValue(
        form.controls['year'].value.replace(/[^\d]/g, '')
      );
    }
    if (form.controls['month'].value) {
      form.controls['month'].setValue(
        form.controls['month'].value.replace(/[^\d]/g, '')
      );
    }
    if (form.controls['day'].value) {
      form.controls['day'].setValue(
        form.controls['day'].value.replace(/[^\d]/g, '')
      );
    }
    let message = '';

    if (!form.controls['first'].valid) {
      message = 'Please enter your first name!';
    } else if (!form.controls['last'].valid) {
      message = 'Please enter your last name!';
    } else if (!form.controls['email'].valid) {
      message = 'Please enter a valid email!';
    } else if (
      !form.controls['phone'].valid ||
      (form.controls['phone'].value.length !== 10 &&
        form.controls['phone'].value.length !== 14)
    ) {
      message = 'Please enter a valid phone number!';
    } else if (
      !form.controls.year.valid ||
      !form.controls.month.valid ||
      !form.controls.day.valid ||
      !this.isBirthdayValid(this.validationForm)
    ) {
      message = 'Please enter a valid birthdate!';
    } else if (!form.controls['address'].valid) {
      message = 'Please enter your address!';
    } else if (!form.controls['password'].valid) {
      message = 'Password must be at least 6 characters!';
    } else if (!form.controls['passwordConfirm'].valid) {
      message = 'Password Confirm must match password!';
    } else if (!form.controls['gender'].valid) {
      message = 'Please enter your gender!';
    } else if (!form.controls['terms'].valid) {
      message = 'You must accept the Terms & Conditions to continue!';
    } else {
      form.controls['phone'].setValue(
        form.controls['phone'].value.replace(/[^\d]/g, '')
      );
      if (form.controls['phone'].value.length !== 10) {
        message = 'Please enter a valid phone number!';
        this.snackBar.open(message, null, { duration: 4000 });
        return false;
      }
      // form is valid so return true
      return true;
    }
    this.snackBar.open(message, null, { duration: 4000 });
    return false;
  }

  checkFormValid() {
    if (this.validationForm.controls['year'].value) {
      this.validationForm.controls['year'].setValue(
        this.validationForm.controls['year'].value.replace(/[^\d]/g, '')
      );
    }
    if (this.validationForm.controls['month'].value) {
      this.validationForm.controls['month'].setValue(
        this.validationForm.controls['month'].value.replace(/[^\d]/g, '')
      );
    }
    if (this.validationForm.controls['day'].value) {
      this.validationForm.controls['day'].setValue(
        this.validationForm.controls['day'].value.replace(/[^\d]/g, '')
      );
    }
    let message = '';

    if (!this.validationForm.controls['first'].valid) {
      message = 'Please enter your first name!';
    } else if (!this.validationForm.controls['last'].valid) {
      message = 'Please enter your last name!';
    } else if (!this.validationForm.controls['phone'].valid) {
      message = 'Please enter a valid phone number!';
    } else if (
      !this.validationForm.controls.year.valid ||
      !this.validationForm.controls.month.valid ||
      !this.validationForm.controls.day.valid ||
      !this.isBirthdayValid(this.validationForm)
    ) {
      message = 'Please enter a valid birthdate!';
    } else if (!this.validationForm.controls['gender'].valid) {
      message = 'Please enter your gender!';
    } else if (!this.validationForm.controls['terms'].valid) {
      message = 'You must accept the Terms & Conditions to continue!';
    } else {
      this.validationForm.controls['phone'].setValue(
        this.validationForm.controls['phone'].value.replace(/[^\d]/g, '')
      );
      if (this.validationForm.controls['phone'].value.length !== 10) {
        message = 'Please enter a valid phone number!';
        this.snackBar.open(message, null, { duration: 4000 });
        return false;
      }
      // form is valid so retun true
      return true;
    }
    this.snackBar.open(message, null, { duration: 4000 });
    return false;
  }

  // just used to check if survey is loaded yet
  ngOnChanges(changes) {
    if (this.stillLoading && changes.surveyLoaded.currentValue === true) {
      setTimeout(() => {
        this.onSubmit();
        this.stillLoading = false;
      }, 100);
    }
  }

  ngOnDestroy() {
    if (this.activeCamera && this.video) {
      this.video.nativeElement.pause();
      this.video.nativeElement.src = '';
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  setPatientInfo(form) {
    const patientInfo = {};
    for (const item in form) {
      if (form[item].value) {
        patientInfo[item] = form[item].value;
      }
    }
    patientInfo['day'] = parseInt(patientInfo['day'], 10);
    patientInfo['month'] = parseInt(patientInfo['month'], 10);
    patientInfo['year'] = parseInt(patientInfo['year'], 10);
    return patientInfo;
  }

  getDoBFBString(year, month, day) {
    let newMonth;
    let newDay;
    if (month.toString().length === 1) {
      newMonth = '0' + month.toString();
    } else {
      newMonth = month.toString();
    }

    if (day.toString().length === 1) {
      newDay = '0' + day.toString();
    } else {
      newDay = day.toString();
    }
    return year.toString() + newMonth.toString() + newDay.toString();
  }

  onSubmit() {
    // check if survey not loaded, shows loading wheel
    if (!this.surveyLoaded) {
      this.stillLoading = true;
      return false;
    }
    // if quickPreview skip form checking
    if (this.quickPreview) {
      this.patientInfo = this.quickPreviewVals;
    } else if (this.loggingIn || this.signingUp) {
      // skip this stuff
      // if invalid form, show error messages. If loggign in already good
    } else if (!this.checkFormValid()) {
      return false;
    } else {
      this.patientInfo = this.setPatientInfo(this.validationForm.controls);
      if (this.mainAccountUID) {
        this.patientInfo.uid = this.mainAccountUID;
        this.patientInfo.family = true;
      } else if (this.manualAppt && this.apptID) {
        // if manual appt make uid the linkID
        this.patientInfo.uid = this.companyID + '-' + this.apptID;
      }
      console.log(this.patientInfo);
    } // end if else

    if (!!PRODUCTION && !this.preview) {
      try {
        // add to insig mailing list
        if (this.patientInfo.email) {
          this.mailchimpService.subscribeMailChimpUserData(
            this.mailchimpService.mailchimpInsigPatients,
            this.patientInfo
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    this.patientInfoChange.emit(this.patientInfo);
    this.formSubmitted = true;
    this.formSubmittedChange.emit(this.formSubmitted);
    window.scrollTo(0, 0);
  }

  openTerms() {
    const dialogRef = this.dialog.open(PatientTermsDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true || result === false) {
        this.validationForm.controls.terms.setValue(result);
      }
    });
  }

  resetVariables() {
    this.showPatientDemographics = false;
    this.showSelectionSignIn = false;
    this.showScanDriversLicence = false;
    this.showQuickPreview = false;
    this.signingUp = false;
  }

  handleLoggedIn(): void {
    this.loggingIn = false;
    this.formSubmitted = true;
    this.formSubmittedChange.emit(this.formSubmitted);
  }

  logout(): void {
    this.gcpIpAuthService.signOut();
    this.loggingIn = true;
    this.formSubmitted = false;
    this.formSubmittedChange.emit(this.formSubmitted);
  }

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    this.showScanDriversLicence = false;
    this.stillLoading = true;

    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    const server = 'https://wabr.inliteresearch.com/barcodes';

    // Optional.  Required to read all barcode data using WaBR Test Server and aBR SaaS Server
    // let auth = 'YOUR_AUTHORIZATION_CODE';
    const headers = new HttpHeaders({
      Authorization: 'AVvgLKo83o3V0qhnYoyzfRMzdPHctVXZ',
    });
    // Optional.  List of barcode types to read
    // Selecting specific values optimizes WaBR performance
    // Valid values  are listed  at http://how-to.inliteresearch.com/web-api-barcode-reader/#types
    const types = 'pdf417'; // e.g. "Code39,Code128"  reads only Code39 and Code128 barcodes
    // Optional.  Other WABR options listed at  http://how-to.inliteresearch.com/web-api-barcode-reader/#Optional-reader-parameters
    const tbr = '';
    const options = '';
    const format = '';
    // let fields = 'values';
    console.log(event);
    const formData = new FormData();
    formData.append('types', types);
    if (tbr !== '') {
      formData.append('tbr', tbr);
    }
    if (options !== '') {
      formData.append('options', options);
    }
    if (format !== '') {
      formData.append('format', format);
    }
    // if (fields !== "") formData.append("fields", fields);

    formData.append('file[]', fileData, fileName);

    this.http
      .post(server, formData, { headers })
      .toPromise()
      .then(async (response) => {
        console.log(response);
        this.resetVariables();

        if (
          !response ||
          !response['Barcodes'] ||
          !response['Barcodes'][0] ||
          !response['Barcodes'][0].Values ||
          !response['Barcodes'][0].Values.AAMVA
        ) {
          this.stillLoading = false;
          this.showPatientDemographics = true;
          this.snackBar.open(
            'Card Scan Failed! \n Please Enter Information Manually',
            null,
            { duration: 4000 }
          );
        } else {
          this.stillLoading = false;
          this.showPatientDemographics = true;
          const ptInfo = response['Barcodes'][0].Values.AAMVA;
          this.validationForm.get('first').setValue(ptInfo.first);
          this.validationForm.get('last').setValue(ptInfo.last);
          this.validationForm.get('year').setValue(ptInfo.dob.split('-')[0]);
          this.validationForm.get('month').setValue(ptInfo.dob.split('-')[1]);
          this.validationForm.get('day').setValue(ptInfo.dob.split('-')[2]);
          this.validationForm
            .get('gender')
            .setValue(ptInfo.sex === 'M' ? 'Male' : 'Female');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  } // end func

  isBirthdayValid(validationForm: UntypedFormGroup): boolean {
    const year = validationForm.controls.year.value;
    let month = validationForm.controls.month.value;
    let day = validationForm.controls.day.value;

    month = (month.length < 2) ? `0${month}` : month;
    day = (day.length < 2) ? `0${day}` : day;

    const combinedBirthDateFields = `${year}-${month}-${day}`;
    return this.dateAndTimeService.isDateValid(combinedBirthDateFields, 'YYYY-MM-DD');
  }
}
