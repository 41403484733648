import { Component } from '@angular/core';

import firebase from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { PatientUserDataService } from 'insig-app/services/patient-user-data/patient-user-data.service';

import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { AuthService } from '@insig-health/services/auth/auth.service';

@Component({
  templateUrl: './patient-sign-up-page.component.html',
}) export class PatientSignUpPageComponent {

  private physioAccount: string | undefined = undefined; // ID of the physio salesperson who referred us

  constructor(
    private authService: AuthService,
    private firebaseAuthService: FirebaseAuthService,
    private patientUserDataService: PatientUserDataService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      if (params.phy) {
        this.physioAccount = params.phy;
        console.log('! physio account');
      }
    });

    this.route.params.subscribe((params) => {
      console.log(params);
      if (params.phy) {
        this.physioAccount = params.phy;
        console.log('! physio account');
      }
    });
  }

  async handlePatientRegistrationComplete(): Promise<void> {
    const firebaseCustomToken = await this.authService.getFirebaseCustomToken();
    await this.firebaseAuthService.signInWithCustomToken(firebaseCustomToken);

    if (this.physioAccount) {
      const physioAccountData = this.patientUserDataService.getPhysioReferralData(this.physioAccount);
      const user = this.firebaseAuthService.getFirebaseCurrentUser();
      if (user) {
        await firebase.firestore().collection('patientUsers').doc(user.uid).set(physioAccountData, { merge: true });
      }
    }

    this.router.navigateByUrl('/app/virtual/dashboard');
  }

  handleBackToLoginClicked(): void {
    this.router.navigateByUrl('/auth/login');
  }
}
