// @ts-strict-ignore
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

import { CalendarEvent } from 'angular-calendar';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
  DayOfTheWeek,
  DoctorSettingsService,
  ExceptionSchedule,
  LocalExceptionSchedule,
  LocalWeekdaySchedule,
  LocalWeeklySchedule,
  TimeIncrement,
  TimeSegment,
  WeekdaySchedule,
} from 'insig-app/services/doctorSettings.service';
import { SchedulerService } from 'insig-app/services/scheduler.service';
import { CompanyLocationService } from 'insig-app/services/company/location.service';

import { switchMap } from 'rxjs/operators';

import { getAllDatesOfMonth } from './edit-availability.utilities';

import { MILLISECONDS_PER_DAY, MILLISECONDS_PER_MINUTE } from 'insig-app/services/doctorSettings.service.utilities';
import { DateAndTimeService } from '@insig-health/services/date-and-time/date-and-time.service';
import { SNACK_BAR_AUTO_DISMISS_MILLISECONDS } from '@insig-health/config/config';
import { MatDialog } from '@angular/material/dialog';
import { OverlappingTimeSegmentsWarningDialogComponent } from './overlapping-time-slots-warning-dialog/overlapping-time-segments-warning-dialog.component';

@Component({
  selector: 'edit-availability',
  templateUrl: 'edit-availability.component.html',
  styles: [
    `
      .flex-col {
        flex: 1 1 0;
      }
      .flex-equal {
        flex: 1;
      }
    `,
  ],
  providers: [DoctorSettingsService, SchedulerService, CompanyLocationService],
})
export class EditAvailabilityComponent implements OnInit, OnDestroy {
  @Input() uid: string;
  @Input() company: string;

  public showCalendar = false;
  public showTimes = false;

  private _viewDate: Date;
  public get viewDate(): Date {
    return this._viewDate;
  }
  public set viewDate(value: Date) {
    this._viewDate = value;
    this.viewDateSubject.next(value);
  }

  public events: CalendarEvent[] = [];
  public refresh: Subject<any> = new Subject();
  public activeDayIsOpen = false;

  private weeklySchedule: LocalWeeklySchedule;
  private exceptionSchedule: { [date: string]: ExceptionSchedule } = {}; // A dictionary with date as the key and daily schedule as the value
  private exceptionsToSave: { [date: string]: ExceptionSchedule } = {};
  private exceptionDate: Date;

  public selectedWeekday: string | null = null;

  private _weeklyLoading = true;
  public get weeklyLoading(): boolean {
    return this._weeklyLoading;
  }
  public set weeklyLoading(value: boolean) {
    this._weeklyLoading = !!value;
    this.changeDetector.detectChanges();
  }

  private _appointmentsLoading = true;
  public get appointmentsLoading(): boolean {
    return this._appointmentsLoading;
  }
  public set appointmentsLoading(value: boolean) {
    if (this._appointmentsLoading !== value) {
      this._appointmentsLoading = !!value;
      this.changeDetector.detectChanges();
    }
  }

  private _exceptionsLoading = true;
  public get exceptionsLoading(): boolean {
    return this._exceptionsLoading;
  }
  public set exceptionsLoading(value: boolean) {
    if (this._exceptionsLoading !== value) {
      this._exceptionsLoading = !!value;
      this.changeDetector.detectChanges();
    }
  }

  private weeklyScheduleSubscription: Subscription;
  private exceptionScheduleSubscription: Subscription;
  private locationSubscription: Subscription;
  private existingAppointmentsSubscription: Subscription;

  private viewDateSubject = new Subject<Date>();

  private daysOfTheWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  public locations: any[] = [];

  /**
   * A dictionary containing the dates of all the days where appointments have been booked.
   */
  private monthlyBookedDays: { [dateString: string]: boolean } = {};

  constructor(
    private doctorSettingsService: DoctorSettingsService,
    private schedulerService: SchedulerService,
    private companyLocationService: CompanyLocationService,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,
    private dateAndTimeService: DateAndTimeService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    // #region load weekly schedule
    this.weeklyScheduleSubscription = this.doctorSettingsService
      .getUserWeeklySchedule(this.company, this.uid)
      .subscribe((weeklySchedule) => {
        this.weeklyLoading = true;

        let needToSave = false;
        const localTimeZone = this.dateAndTimeService.getLocalTimeZone();
        this.weeklySchedule = this.doctorSettingsService.convertWeeklyScheduleToLocalTime(weeklySchedule, new Date(), localTimeZone);

        if (!this.weeklySchedule.defaultAvailability) {
          this.weeklySchedule.defaultAvailability = 'both';
          needToSave = true;
        }

        for (const weekday of this.daysOfTheWeek) {
          if (!this.weeklySchedule[weekday]) {
            this.weeklySchedule[weekday] = this.generateDefaultDailySchedule();
            needToSave = true;
          }
        }

        if (needToSave) {
          this.doctorSettingsService
            .setUserWeeklySchedule(this.company, this.uid, this.weeklySchedule)
            .then(() => {
              this.weeklyLoading = false;
            })
            .catch((error) => {
              console.error(error);
              this.snackBar.open(
                'An error occured while initializing your schedule: ' +
                  (error.message || error),
                null,
                { duration: 4000 },
              );
            });
        } else {
          this.weeklyLoading = false;
        }
      });
    // #endregion load weekly schedule

    // #region load exception schedule
    this.exceptionScheduleSubscription = this.viewDateSubject
      .pipe(
        switchMap((viewDate: Date) => {
          // View date is a date being shown. We want to the the first date in the week of the first day of the current month (Sunday).
          // Compute first date of the current month
          const firstDateOfMonth = new Date(viewDate.getTime());
          firstDateOfMonth.setDate(1);

          // Compute date of Sunday for the first week
          const firstDateDayOfTheWeek = firstDateOfMonth.getDay();
          const firstSunday = new Date(
            firstDateOfMonth.getTime() -
              firstDateDayOfTheWeek * 24 * 60 * 60 * 1000,
          );

          return this.doctorSettingsService.getExceptionScheduleByDoctor(
            this.uid,
            this.dateAndTimeService.getLocalTimeZone(),
            firstSunday.getTime() - 24 * 60 * 60 * 1000, // Start query 24 hours prior to account for timezone/dst issues
            undefined,
            true,
          );
        })
      )
      .subscribe((exceptionArray) => {
        this.exceptionsLoading = true;
        this.exceptionSchedule = exceptionArray.reduce((dict, exception) => {
          const epochMs = exception.epochDay * MILLISECONDS_PER_DAY;
          const utcDate = new Date(epochMs);
          const localDate = new Date(new Date().setFullYear(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate()));
          dict[localDate.toDateString()] = exception;
          return dict;
        }, {} as { [dateString: string]: LocalExceptionSchedule });
        this.exceptionsLoading = false;
      });
    // #endregion load exception schedule

    // #region load locations
    this.locationSubscription = this.companyLocationService
      .getLocations(this.company)
      .subscribe((locationArray) => {
        this.locations = locationArray;
      });
    // #endregion load locations

    // #region load existing appointments
    this.existingAppointmentsSubscription = this.viewDateSubject
      .asObservable()
      .pipe(
        switchMap((viewDate) => {
          return this.schedulerService.getDoctorAppointmentsByIdByMonth(
            this.uid,
            this.company,
            viewDate,
          );
        }),
      )
      .subscribe((appointments) => {
        this.appointmentsLoading = true;
        const monthlyAppointments = appointments.filter(
          (appointment) => !appointment.status,
        );

        this.monthlyBookedDays = getAllDatesOfMonth(this.viewDate).reduce(
          (accumulator, currDate) => {
            accumulator[currDate.toString()] = monthlyAppointments.some(
              (appointment) => {
                const startDate = new Date(appointment.event.start);
                startDate.setHours(0, 0, 0, 0);
                return startDate.toString() === currDate.toString();
              },
            );
            return accumulator;
          },
          {} as { [dateString: string]: boolean },
        );

        console.log(this.monthlyBookedDays);
        this.appointmentsLoading = false;
      });
    // #endregion load existing appointments

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.viewDate = today;
  }

  ngOnDestroy(): void {
    if (this.weeklyScheduleSubscription) {
      this.weeklyScheduleSubscription.unsubscribe();
    }
    if (this.exceptionScheduleSubscription) {
      this.exceptionScheduleSubscription.unsubscribe();
    }
    if (this.locationSubscription) {
      this.locationSubscription.unsubscribe();
    }
    if (this.existingAppointmentsSubscription) {
      this.existingAppointmentsSubscription.unsubscribe();
    }
  }

  getDatesOfWeek(): Date[] {
    const currentDate = new Date();
    const timezone = this.dateAndTimeService.getLocalTimeZone();
    return this.dateAndTimeService.getDatesOfWeek(currentDate, timezone);
  }

  getTimeZoneAbbr(weekdaySchedule: LocalWeekdaySchedule, index: number): string {
    const datesOfWeek = this.getDatesOfWeek();
    const dateOfWeek = new Date(datesOfWeek[index].setHours(12));
    return this.dateAndTimeService.getTimeZoneAbbreviation(dateOfWeek, weekdaySchedule.operatingTimeZone);
  }

  async saveFullSchedule(): Promise<void> {
    if (!this.isWeekdayScheduleValid(this.weeklySchedule) || !this.isExceptionScheduleValid(this.exceptionsToSave)) {
      this.dialog.open(OverlappingTimeSegmentsWarningDialogComponent);
      return;
    }

    try {
      await this.saveExceptionSchedule(this.exceptionsToSave);
      await this.doctorSettingsService.setUserWeeklySchedule(
        this.company,
        this.uid,
        this.weeklySchedule,
      );
      this.snackBar.open('Availability Saved!', null, { duration: 4000 });
    } catch (error) {
      console.error(error);
      this.snackBar.open(error.message || 'Saving failed: ' + error, null, {
        duration: SNACK_BAR_AUTO_DISMISS_MILLISECONDS,
      });
    }
  }

  isExceptionScheduleValid(exceptionSchedule: {
    [date: string]: ExceptionSchedule;
  }): boolean {
    return Object.keys(exceptionSchedule).every((exceptionDate) => {
      return this.isExceptionDateValid(exceptionSchedule[exceptionDate]);
    });
  }

  isExceptionDateValid(exceptionSchedule: ExceptionSchedule): boolean {
    return this.areTimeSegmentsValid(exceptionSchedule.timeSegments);
  }

  async saveExceptionSchedule(exceptionSchedule: {
    [date: string]: ExceptionSchedule;
  }): Promise<void> {
    const updateExceptionSchedulePromises = Object.keys(exceptionSchedule).map((exceptionDate) => {
      const epochDay = this.dateAndTimeService.getEpochDayFromDate(new Date(exceptionDate));
      const exceptionDateSchedule = exceptionSchedule[exceptionDate];
      return this.doctorSettingsService.setUserExceptionSchedule(exceptionDateSchedule.doctorID, exceptionDateSchedule.doctorCompany, epochDay, exceptionDateSchedule);
    });

    await Promise.all(updateExceptionSchedulePromises);
  }

  isWeekdayScheduleValid(weeklySchedule: LocalWeeklySchedule): boolean {
    return this.areTimeSegmentsValid(weeklySchedule.sunday?.timeSegments ?? []) &&
      this.areTimeSegmentsValid(weeklySchedule.monday?.timeSegments ?? []) &&
      this.areTimeSegmentsValid(weeklySchedule.tuesday?.timeSegments ?? []) &&
      this.areTimeSegmentsValid(weeklySchedule.wednesday?.timeSegments ?? []) &&
      this.areTimeSegmentsValid(weeklySchedule.thursday?.timeSegments ?? []) &&
      this.areTimeSegmentsValid(weeklySchedule.friday?.timeSegments ?? []) &&
      this.areTimeSegmentsValid(weeklySchedule.saturday?.timeSegments ?? []);
  }

  areTimeSegmentsValid(timeSegments: TimeSegment[]): boolean {
    return this.getOverlappingTimeSegments(timeSegments).length === 0;
  }

  getOverlappingTimeSegments(timeSegments: TimeSegment[]): number[] {
    // Returns an array of segment indices that have overlapping time periods
    // We have to compare each time segment with each other time segment
    let overlappingSegments = [];
    for (let indexA = 0; indexA < timeSegments.length - 1; indexA++) {
      for (let indexB = indexA + 1; indexB < timeSegments.length; indexB++) {
        if (
          overlappingSegments.indexOf(indexA) !== -1 &&
          overlappingSegments.indexOf(indexB) !== -1
        ) {
          // We already know these two periods are overlapping so we do not have to check them
          continue;
        }

        const segmentA = timeSegments[indexA];
        const segmentB = timeSegments[indexB];
        const isOverlap = this.isOverlapBetweenSegments(segmentA, segmentB);

        if (isOverlap) {
          overlappingSegments = this.addOverlappingSegments(overlappingSegments, indexA, indexB);
        }
      }
    }
    return overlappingSegments;
  }

  isOverlapBetweenSegments(segmentA: TimeSegment, segmentB: TimeSegment): boolean {
    if (segmentA.operatingStartTime === segmentB.operatingStartTime) {
      return true;
    } else if (
      this.minutesBetween(segmentA.operatingStartTime, segmentB.operatingStartTime) > 0
    ) {
      // segmentA starts earlier
      // there is overlap if segmentA ends after segmentB starts
      return this.minutesBetween(segmentA.operatingEndTime, segmentB.operatingStartTime) < 0;
    } else {
      // segmentB starts earlier
      // there is overlap if segmentB ends after segmentA starts
      return this.minutesBetween(segmentB.operatingEndTime, segmentA.operatingStartTime) < 0;
    }
  }

  addOverlappingSegments(currentOverlappingSegments: number[], indexA: number, indexB: number): number[] {
    const overlappingSegments = currentOverlappingSegments.slice();
    // Add the indices to the array if they are not already in there
    if (currentOverlappingSegments.indexOf(indexA) === -1) {
      overlappingSegments.push(indexA);
    }
    if (currentOverlappingSegments.indexOf(indexB) === -1) {
      overlappingSegments.push(indexB);
    }
    return overlappingSegments;
  }

  minutesBetween(msPastMidnightA: number, msPastMidnightB: number): number {
    // Returns the number of minutes from timeA to timeB, negative if timeB is earlier than timeA
    return (msPastMidnightB - msPastMidnightA) / MILLISECONDS_PER_MINUTE;
  }

  /**
   * Retire the calendar to the previous month.
   */
  goToPreviousMonth(): void {
    this.viewDate.setDate(1);
    this.viewDate.setMonth(this.viewDate.getMonth() - 1);
    this.viewDate = new Date(this.viewDate.toString()); // Refresh viewDate bindings
    this.refresh.next(undefined);
  }

  /**
   * Advance the calendar to the next month.
   */
  goToNextMonth(): void {
    this.viewDate.setDate(1);
    this.viewDate.setMonth(this.viewDate.getMonth() + 1);
    this.viewDate = new Date(this.viewDate.toString()); // Rrefresh viewDate bindings
    this.refresh.next(undefined);
  }

  /**
   * Whether or not there is an excpetion on the given date.
   * @param  {Date}    date The date to query
   * @return {boolean}      Whether or not that date has an exception enabled
   */
  hasExceptionOn(date: Date): boolean {
    if (this.exceptionSchedule[date.toDateString()]) {
      return this.exceptionSchedule[date.toDateString()].exception;
    } else {
      return false;
    }
  }

  /**
   * Whether of not there is an appointment on the given date.
   * @param  {Date}    date The date to query
   * @return {boolean}      Whether or not that date has an appointment booked
   */
  hasAppointmentOn(date: Date): boolean {
    return this.monthlyBookedDays[date.toString()];
  }

  dayClicked(event) {
    this.exceptionDate = event.day.date;
    if (!this.exceptionSchedule[this.exceptionDate.toDateString()]) {
      const epochDay = this.dateAndTimeService.getEpochDayFromDate(event.day.date);
      this.exceptionSchedule[this.exceptionDate.toDateString()] = this.generateEmptyExceptionSchedule(epochDay);
    }
    if (!this.exceptionSchedule[this.exceptionDate.toDateString()].day) {
      this.exceptionSchedule[this.exceptionDate.toDateString()].day = this.exceptionDate.getTime();
    }
    if (!this.exceptionSchedule[this.exceptionDate.toDateString()].doctorID) {
      this.exceptionSchedule[this.exceptionDate.toDateString()].doctorID = this.uid;
    }
    this.showTimes = true;
  }

  setExceptionDate(date: Date, exceptionSchedule: ExceptionSchedule): void {
    this.exceptionsToSave[date.toDateString()] = exceptionSchedule;
    this.exceptionSchedule[date.toDateString()] = exceptionSchedule;
  }

  async backToCalendar(): Promise<void> {
    await this.loadWeeklySchedule();

    this.showTimes = false;
    this.selectedWeekday = null;
  }

  editWeekdaySchedule(day: string) {
    this.selectedWeekday = day;
    this.showTimes = true;
  }

  generateDefaultDailySchedule(): WeekdaySchedule {
    return {
      timeSegments: [],
      timeIncrement: '10',
      operatingTimeZone: this.dateAndTimeService.getLocalTimeZone(),
    };
  }

  generateEmptyExceptionSchedule(epochDay: number): ExceptionSchedule {
    return {
      timeSegments: [],
      timeIncrement: '15',
      operatingTimeZone: this.dateAndTimeService.getLocalTimeZone(),
      doctorID: this.uid,
      doctorCompany: this.company,
      epochDay,
      day: epochDay * MILLISECONDS_PER_DAY,
    };
  }

  dynamicHeight(): number {
    return (
      Math.max(
        this.weeklySchedule.sunday.timeSegments
          ? this.weeklySchedule.sunday.timeSegments.length
          : 0,
        this.weeklySchedule.monday.timeSegments
          ? this.weeklySchedule.monday.timeSegments.length
          : 0,
        this.weeklySchedule.tuesday.timeSegments
          ? this.weeklySchedule.tuesday.timeSegments.length
          : 0,
        this.weeklySchedule.wednesday.timeSegments
          ? this.weeklySchedule.wednesday.timeSegments.length
          : 0,
        this.weeklySchedule.thursday.timeSegments
          ? this.weeklySchedule.thursday.timeSegments.length
          : 0,
        this.weeklySchedule.friday.timeSegments
          ? this.weeklySchedule.friday.timeSegments.length
          : 0,
        this.weeklySchedule.saturday.timeSegments
          ? this.weeklySchedule.saturday.timeSegments.length
          : 0
      ) * 88
    );
  }

  getAppointmentSlotCountForDay(date): number {
    if (typeof date === 'string' || date instanceof String) {
      date = new Date(date.toString());
    }
    if (Object.prototype.toString.call(date) !== '[object Date]') {
      date = new Date();
    }

    let timeIncrement: TimeIncrement;
    let timeSegments: TimeSegment[];
    if (this.hasExceptionOn(date)) {
      timeIncrement = this.exceptionSchedule[date.toDateString()].timeIncrement;
      timeSegments = this.exceptionSchedule[date.toDateString()].timeSegments;
    } else {
      const dayOfWeek = date.getDay();
      const dayDict: { [dayIndex: number]: DayOfTheWeek } = {
        0: 'sunday',
        1: 'monday',
        2: 'tuesday',
        3: 'wednesday',
        4: 'thursday',
        5: 'friday',
        6: 'saturday',
      };
      timeIncrement = this.weeklySchedule[dayDict[dayOfWeek]].timeIncrement;
      timeSegments = this.weeklySchedule[dayDict[dayOfWeek]].timeSegments;
    }

    if (!timeSegments) {
      return 0;
    } else {
      let slotCount = 0;
      timeSegments.forEach((segment) => {
        slotCount += this.getNumberOfTimeSlotsForSegment(
          segment,
          parseInt(timeIncrement, 10)
        );
      });
      return slotCount;
    }
  }

  /**
   * Returns the number of time slots in a time segment given the start and end
   * times as 24h strings e.g. "16:15" for the time 4:15pm, and the time
   * increment in number of minutes.
   * @param  {string} startTime     24h start time
   * @param  {string} endTime       24h end time
   * @param  {number} timeIncrement Integer number of minutes in a time slot
   * @return {number}               Number of time slots in the time segment
   */
  getNumberOfTimeSlotsForSegment(
    timeSegment: TimeSegment,
    timeIncrement: number
  ): number {
    const timeSegmentDurationMs = timeSegment.operatingEndTime - timeSegment.operatingStartTime;
    const timeSegmentDurationMinutes = timeSegmentDurationMs / MILLISECONDS_PER_MINUTE;
    return Math.floor(timeSegmentDurationMinutes / timeIncrement);
  }

  async loadWeeklySchedule(): Promise<void> {
    this.weeklyLoading = true;

    const weeklySchedule = await firstValueFrom(this.doctorSettingsService.getUserWeeklySchedule(this.company, this.uid));

    const localTimeZone = this.dateAndTimeService.getLocalTimeZone();
    this.weeklySchedule = this.doctorSettingsService.convertWeeklyScheduleToLocalTime(weeklySchedule, new Date(), localTimeZone);

    this.weeklyLoading = false;
  }
}
