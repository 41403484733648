import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-company-plan-service-delete',
  templateUrl: './confirm-company-plan-service-delete.component.html',
})
export class ConfirmCompanyPlanServiceDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<
      ConfirmCompanyPlanServiceDeleteDialogComponent
    >
  ) {}
}
