import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pillway-info-dialog',
  templateUrl: './pillway-info-dialog.component.html',
  styleUrls: ['./pillway-info-dialog.component.scss'],
})
export class PillwayInfoDialogComponent {

  constructor(public dialogRef: MatDialogRef<PillwayInfoDialogComponent>) {}
}
