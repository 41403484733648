import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { CpsEsDoctor, CpsEsService } from '@insig-health/services/cps-es/cps-es.service';
import { combineLatest, debounceTime, filter, Observable, startWith, switchMap } from 'rxjs';

@Component({
  selector: 'insig-health-family-doctor-search',
  templateUrl: './family-doctor-search.component.html',
})
export class FamilyDoctorSearchComponent implements AfterViewInit {
  static readonly DEBOUNCE_TIME_MILLISECONDS = 300;
  static readonly FAMILY_DOCTOR_FULL_NAME_CONTROL_REQUIRED_ERROR = 'familyDoctorFullNameControl is required';
  static readonly RESULTS_LIMIT = 10;

  public familyDoctors$: Observable<CpsEsDoctor[]> | undefined;
  public _isRequired = false;

  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() inputStyle: 'insigBooking' | 'matFormField' = 'insigBooking';
  @Input() set isRequired(isRequired: boolean | 'true' | 'false') {
    this._isRequired = coerceBooleanProperty(isRequired);
  }

  @Input() provinceQuery = '';
  @Input() provinceControl: FormControl | undefined;
  @Input() familyDoctorFullNameControl: FormControl | undefined;

  @Output() familyDoctorSelected = new EventEmitter<CpsEsDoctor>();

  constructor(private cpsEsService: CpsEsService) {}

  async ngAfterViewInit(): Promise<void> {
    if (this.familyDoctorFullNameControl && this.provinceControl) {
      this.familyDoctors$ = combineLatest([
        this.familyDoctorFullNameControl.valueChanges.pipe(startWith(this.familyDoctorFullNameControl.value), filter((value) => !!value)),
        this.provinceControl.valueChanges.pipe(startWith(this.provinceControl.value), filter((value) => !!value)),
      ]).pipe(
        debounceTime(FamilyDoctorSearchComponent.DEBOUNCE_TIME_MILLISECONDS),
        switchMap(([familyDoctorFullNameQuery, provinceQuery]): Promise<CpsEsDoctor[]> => {
          return this.cpsEsService.searchCpsEsDoctors(familyDoctorFullNameQuery ?? '', provinceQuery ?? '', FamilyDoctorSearchComponent.RESULTS_LIMIT);
        }),
      );
    } else if (this.familyDoctorFullNameControl) {
      this.familyDoctors$ = this.familyDoctorFullNameControl.valueChanges.pipe(
        debounceTime(FamilyDoctorSearchComponent.DEBOUNCE_TIME_MILLISECONDS),
        startWith(this.familyDoctorFullNameControl.value),
        switchMap((familyDoctorFullNameQuery): Promise<CpsEsDoctor[]> => {
          return this.cpsEsService.searchCpsEsDoctors(familyDoctorFullNameQuery ?? '', this.provinceQuery, FamilyDoctorSearchComponent.RESULTS_LIMIT);
        }),
      );
    } else {
      throw new Error(FamilyDoctorSearchComponent.FAMILY_DOCTOR_FULL_NAME_CONTROL_REQUIRED_ERROR);
    }
  }

  handleFamilyDoctorOptionClicked(familyDoctor: CpsEsDoctor): void {
    this.familyDoctorFullNameControl?.setValue(familyDoctor.fullName);
    this.familyDoctorSelected.emit(familyDoctor);
  }
}
