// @ts-strict-ignore
import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Apollo, gql } from 'apollo-angular';

// Services
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { PaymentService } from 'insig-app/services/payment.service';

import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';

import firebase from 'firebase/compat/app';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { PatientSearchResult } from 'insig-types/spring-api/patient-search';

@Component({
  selector: 'patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
  providers: [PaymentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientsListComponent implements OnInit, OnDestroy {
  // graphql queries
  private userDataQuery = gql`
    query User($userID: ID!) {
      getUserData(uid: $userID) {
        uid
        company
      }
    }
  `;

  public JSON = JSON;

  private authSubscription: Subscription;
  private patientSubscription: Subscription;

  public companyId: string;
  public loading = true;
  public searchMode = false;

  public patientsList: PatientSearchResult[] = [];

  public columnsToDisplay: string[] = ['last', 'first', 'email', 'phoneNumber', 'gender', 'dateOfBirth'];
  public dataSource = new MatTableDataSource<PatientSearchResult>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private firebaseAuthService: FirebaseAuthService,
    public router: Router,
    private changeDetector: ChangeDetectorRef,
    private apollo: Apollo,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.authSubscription = this.firebaseAuthService
      .onIdTokenChanged()
      .subscribe(async (user) => {
        if (user) {
          try {
            this.companyId = await this.loadCompanyId(user);
            this.loadAllPatients();
          } catch (error) {
            console.error(error);
          }
        } else {
          this.router.navigate(['/auth/login']);
        }
      }); // end auth service
  }

  ngAfterViewInit(): void {
    this.setupDataSource();
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
    if (this.patientSubscription) {
      this.patientSubscription.unsubscribe();
    }
  }

  setupDataSource(): void {
    this.setDataSourceProperties({ paginator: this.paginator, sort: this.sort });
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLowerCase();
      } else {
        return data[sortHeaderId];
      }
    };
  }

  setDataSourceProperties(
    { paginator, sort }: { paginator: MatPaginator, sort: MatSort }
  ): void {
    this.dataSource.paginator = paginator;
    this.dataSource.sort = sort;
  }

  async loadCompanyId(user: firebase.User): Promise<string> {
    try {
      this.changeDetector.detectChanges();
      const userDataQuery: any = await this.apollo
        .query({
          query: this.userDataQuery,
          variables: {
            userID: user.uid,
          },
        })
        .toPromise();
      const userData = userDataQuery.data.getUserData;
      console.log(userData);
      if (!!userData && !!userData.company) {
        return userData.company;
      } else {
        throw new Error('User or company does not exist');
      }
    } catch (error) {
      switch (error.code) {
        default:
          console.log(error);
          break;
        case 'PERMISSION_DENIED':
          console.log('Wrong uid. User may have logged out.');
          break;
      }
    }
  }

  loadAllPatients(): void {
    this.searchPatients('');
    this.searchMode = false;
  }

  async searchPatients(query: string): Promise<void> {
    this.searchMode = true;
    this.loading = true;
    this.changeDetector.detectChanges();

    const searchResults = await this.http
      .get<PatientSearchResult[]>(
        `${JAVA_BACKEND_ENDPOINT}company/${this.companyId}/admitted-patients/?q=${query}`
      )
      .pipe(take(1))
      .toPromise()
      .catch((error) => {
        console.error(error);
        throw error;
      });

    this.patientsList = searchResults;
    this.dataSource.data = this.patientsList;

    this.loading = false;
    this.changeDetector.detectChanges();
  }

  visitPatient(patient: PatientSearchResult): void {
    this.router.navigate(['/app/notes/visit-notes/incomplete/' + (patient.sid || patient.pid)]);
  }
} // end class
