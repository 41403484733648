import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'add-user-groups-to-plan-dialog',
  templateUrl: './add-user-groups-to-plan.component.html',
})
export class AddUserGroupsToPlanDialogComponent {
  public userGroupList: any;
  public type: any;
  public selectedGroups = {};

  constructor(
    public dialogRef: MatDialogRef<AddUserGroupsToPlanDialogComponent>
  ) {
    // (window.document.querySelector(
    //   '.insig-dialog-panel'
    // ) as HTMLElement).style.minWidth = '60%';
  }

  addGroups() {
    const selectedGroups = [];
    for (const item in this.userGroupList) {
      if (this.userGroupList[item].selected) {
        selectedGroups.push(this.userGroupList[item]);
      }
    }
    console.log('selected groups', selectedGroups);
    this.dialogRef.close(selectedGroups);
  }
}
