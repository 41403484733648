import { Injectable } from '@angular/core';

import { SurveyFolder } from 'insig-types/surveys/folder';

import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { FirestoreService } from '@insig-health/services/firestore/firestore.service';

@Injectable()
// this loads the list of surveys
export class FoldersService {
  constructor(private firestoreService: FirestoreService) {}

  getUserFolders(userID: string): Observable<SurveyFolder[]> {
    return this.firestoreService.getCollectionAsObservable(
      firebase.firestore().collection('users').doc(userID).collection('folders')
    );
  }

  setUserFolder(userID: string, folder: any): Promise<void> {
    return firebase.firestore()
      .collection('users').doc(userID)
      .collection('folders').doc(folder.id)
      .set(folder);
  }

  deleteUserFolder(userID: string, folderID: string): Promise<void> {
    return firebase.firestore()
      .collection('users').doc(userID)
      .collection('folders').doc(folderID)
      .delete();
  }

  getLibraryFolders(): Observable<Array<{}>> {
    return this.firestoreService.getCollectionAsObservable(
      firebase.firestore().collection('libraryFolders')
    );
  }

  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

}
