import { AfterViewInit, Component, ElementRef, Renderer2, RendererFactory2 } from '@angular/core';
import { TALI_AI_SCRIPT_URL } from '@insig-health/config/config';

@Component({
  selector: 'insig-health-tali-ai-widget',
  templateUrl: './tali-ai-widget.component.html',
})
export class TaliAiWidgetComponent implements AfterViewInit {
  private renderer: Renderer2;

  constructor(
    private rendererFactory: RendererFactory2,
    private elementRef: ElementRef,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement === undefined) {
      return;
    }
    const script = this.renderer.createElement('script');
    script.id = 'tali-ai';
    script.type = 'text/javascript';
    script.src = TALI_AI_SCRIPT_URL;
    script.async = true;
    script.setAttribute('client-id', 'insigHealth');
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }
}
