import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'insig-ui-dialog',
  templateUrl: './insig-dialog.component.html',
})
export class InsigDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<InsigDialogComponent, void>,
  ) {}

  handleCloseClicked(): void {
    this.dialogRef.close();
  }
}
