// @ts-strict-ignore
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { Question } from 'insig-types/surveys';

@Component({
  selector: 'yearmonthday-question',
  templateUrl: './yearmonthday.component.html',
  styles: [
    `
      .marginTop05em {
        margin-top: 0.5em;
      }
    `,
  ],
})
export class YearMonthDayComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  yearMonthDayForm: UntypedFormGroup;
  sub: any;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    if (!this.question.response.answer) {
      this.buildForm();
      this.question.response.answer = {};
    } else {
      // this stops field from becoming null when changing page
      const tempYear = this.question.response.answer.year;
      const tempMonth = this.question.response.answer.month;
      const tempDay = this.question.response.answer.day;
      this.buildForm();
      this.yearMonthDayForm.controls['year'].setValue(tempYear);
      this.yearMonthDayForm.controls['month'].setValue(tempMonth);
      this.yearMonthDayForm.controls['day'].setValue(tempDay);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  buildForm() {
    this.yearMonthDayForm = this.formBuilder.group({
      year: this.formBuilder.control(null),
      month: this.formBuilder.control(null),
      day: this.formBuilder.control(null, [
        Validators.min(1),
        Validators.max(31),
      ]),
    });

    this.sub = this.yearMonthDayForm.valueChanges.subscribe((data) => {
      this.question.response.answer.year = data.year;
      this.question.response.answer.month = data.month;
      this.question.response.answer.day = data.day;
    });
  } // end build form
} // end component
