import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'redirect',
  templateUrl: './redirect.component.html',
})
export class RedirectComponent implements OnInit {
  public status = 'Please wait...';
  public loginError = '';

  public shouldShowLoginScreen = false;
  public redirectRoute: string | undefined;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private firebaseAuthService: FirebaseAuthService,
  ) {}

  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    const user = this.firebaseAuthService.getFirebaseCurrentUser();

    this.redirectRoute = params?.route;

    if (params?.route) {
      if (params.customToken) {
        try {
          const user = await firebase
            .auth()
            .signInWithCustomToken(params.customToken);
          console.log(user);

          if (this.firebaseAuthService.getFirebaseCurrentUser()) {
            // If login is successful, redirect
            this.status = 'Login successful. Redirecting now...';
            this.redirect(params.route);
          } else {
            this.status =
              'Login unsuccessful. Check your credentials and try again';
          }
        } catch (error) {
          console.error(error);
          this.loginError = JSON.stringify(error);
        }
      } else {
        // When there is no custom token, show the login screen
        if (user) {
          // If the user is already logged in, redirect them immediately
          this.redirect(params.route);
        } else {
          this.status = 'Please login to continue';
          this.shouldShowLoginScreen = true;
        }
      }
    } else {
      this.status = 'No redirect route specified';
    }
  }

  redirect(route: string | undefined): Promise<boolean> {
    return this.router.navigate([route ?? this.redirectRoute]);
  }
}
