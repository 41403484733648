// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PDF_BASE_PATH, AWSCLOUDFUNCTIONSENDPOINT, JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';

import { AdmittedPatient, NoteListResponse, SpringNote, SpringNoteLargeStringKey, PatientInfo, FullSpringNote } from 'insig-types/spring-api/notes';

import { take } from 'rxjs/operators';
import type { PartialNoteToggleableFields, PartialNoteToggleableLargeStrings, PickedLargeStrings, PickedFields } from './notes.service.types';

import { getAllPartialNoteLargeStrings } from './notes.service.utilities';

import firebase from 'firebase/compat/app';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { jsPDF } from 'jspdf';

const UNDEFINED_PATIENT_SID = 'HIQ2HpQqFoFcB2ATApgMwJYJUmBGIA'; // A patient hash generated with undefined and NaN values (NaN/NaN/NaN-undefined-1)

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  private setAppointmentIsApprovedURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/setAppointmentIsApproved';
  private documentsSentForAppointmentURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/documentsSentForAppointment';
  private checkIfQuestionnaireCompletedURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/checkIfQuestionnaireCompleted';
  private loadOneDocumentToReFaxURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/loadOneDocumentToReFax';

  private getAppointmentNotesPatientAdminURL =
    AWSCLOUDFUNCTIONSENDPOINT + 'virtual/getAppointmentNotesPatientAdmin';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  private fillPDFURL = AWSCLOUDFUNCTIONSENDPOINT + 'fillPDF/fillPdf';

  private templateIDs = {
    allergy: 'tem_DuNTvot7ZNPigN',
    ministry: 'tem_AV5kxctVSKfrLE',
    publicHealth: 'tem_XmvEiBkvd8ZKV5',
    sti: 'tem_iUHLjiwLbFqC95',
    mri: 'tem_MwHDCp4PG5MLXg',
    fit: 'tem_DgbLiyMf7PXvbA',
    covidAntibodyOntario: 'tem_SvcrHwERkqBTkb',
    covidAntibodyOntarioTraveller: 'tem_vi2iABrTcX9d4z'
  };

  private radioGroups = {
    gender: true,
    insurance: true,
    psa: true,
    psaInsurance: true,
    glucoseType: true,
    vitaminDInsured: true,
    fobt: true,
    reason: true,
  };

  constructor(
    private http: HttpClient,
    private firebaseAuthService: FirebaseAuthService,
  ) {}

  getFavoritePrescriptions(uid): Promise<any> {
    return firebase
      .firestore()
      .collection('favoritePrescriptions')
      .doc(uid)
      .get();
  }

  async addFavoritePrescriptions(uid, prescriptions) {
    console.log(prescriptions);
    firebase
      .firestore()
      .collection('favoritePrescriptions')
      .doc(uid)
      .set({ drugs: prescriptions });
  }

  async getPdfFromHtml(html: string, footer: string = ''): Promise<Blob> {
    const pageWidth = 960;
    const pageHeight = Math.round(pageWidth / 8.5 * 11); // letter size ratio
    const pageMargins = 20;
    const contentWidth = pageWidth - 2 * pageMargins;

    const pdfDocument = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      format: [pageWidth, pageHeight],
    });

    const tabCharacter = '\\9';
    const bulletCharacter = '\\2022';

    const canvasId = 'pdfCanvas';
    const styles = `
      <style>
        #${canvasId} * {
          font-size: 12px !important;
          line-height: 12px !important;
        }
        #${canvasId} img {
          max-width: ${contentWidth}px;
          object-fit: contain;
        }
        #${canvasId} li:before {
          content: '${tabCharacter}${bulletCharacter}${tabCharacter}';
        }
      </style>
    `;
    const content = `<div id="${canvasId}">${html + footer}</div>${styles}`;

    try { 
      await pdfDocument.html(content, {
        margin: pageMargins,
        html2canvas: {
          removeContainer: true,
        },
        width: contentWidth,
        windowWidth: pageWidth,
        x: 0,
        y: 0,
      });
  
      return pdfDocument.output('blob');
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * Converts appointment identifiers to a set of identifiers for the notes of those appointments.
   * @param appointmentIds - A set of appointment identifiers
   * @param appointmentIds.companyId - The id of the company the appointment was booked for
   * @param appointmentIds.appointmentId - The id of the appointment within the company
   * @param patientUid - The uid of the patient that booked the appointment
   * @param queryUnlockedNotesOnly - Whether or not to limit the query to notes that are not locked
   * @returns A set of note identifiers by companyId and noteId
   */
  async getNoteIdsFromAppointmentIds (
    appointmentIds: Array<{ companyId: string; appointmentId: string }>,
    patientUid: string,
    queryUnlockedNotesOnly: boolean
  ): Promise<Array<{ companyId: string; noteId: string }>> {
    const settledResults = await Promise.allSettled(
      appointmentIds.map((ids) => {
        let query = firebase
          .firestore()
          .collection('companies')
          .doc(ids.companyId)
          .collection('notes')
          .where('patientUid', '==', patientUid)
          .where('apptID', '==', ids.appointmentId);

        if (queryUnlockedNotesOnly) {
          query = query.where('locked', '==', false);
        }

        return query
          .get()
          .then((snapshot) => snapshot.empty
            ? []
            : snapshot.docs.map((doc) => ({
                companyId: ids.companyId,
                noteId: doc.id,
              }))
          )
          .catch((error) => {
            console.error(`Error attempting to access note at companies/${ids.companyId}/notes
              with patientUid ${patientUid} and appointmentId ${ids.appointmentId}`, error);
            throw error;
          });
      })
    )

    const rejectedResults = settledResults.filter<PromiseRejectedResult>((result): result is PromiseRejectedResult => result.status === 'rejected');
    const fulfilledResults = settledResults.filter((result): result is PromiseFulfilledResult<Array<{ companyId: string, noteId: string }>> => result.status === 'fulfilled');

    rejectedResults.forEach((result) => {
      console.error(result.reason);
    });
    return fulfilledResults.map((result) => result.value).flat();
  }

  /**
   * Set a note's lock status to true. This prevents patients from accessing the note.
   * @param identifiers - Identifiers of the note to lock.
   * @param identifiers.companyId - The company id that the note resides in.
   * @param identifiers.noteId - The id of the note.
   */
  async lockNote(identifiers: { companyId: string; noteId: string; }): Promise<void> {
    await this.http.put(`${JAVA_BACKEND_ENDPOINT}company/${identifiers.companyId}/note/${identifiers.noteId}/lock`, null)
      .pipe(take(1))
      .toPromise();
  }

  /**
   * Set a note's lock status to false. This allows patients to access the note.
   * @param identifiers - Identifiers of the note to unlock.
   * @param identifiers.companyId - The company id that the note resides in.
   * @param identifiers.noteId - The id of the note.
   */
  async unlockNote(identifiers: { companyId: string; noteId: string; }): Promise<void> {
    await this.http.put(`${JAVA_BACKEND_ENDPOINT}company/${identifiers.companyId}/note/${identifiers.noteId}/unlock`, null)
      .pipe(take(1))
      .toPromise();
  }

  /**
   * Retrieve all optional data for a note from the Java Srping API.
   * @param identifiers - Identifiers for the note to retrieve data for.
   * @param identifiers.companyId - The company id that the note resides in.
   * @param identifiers.noteId - The id of the note.
   * @returns The full note data.
   */
  async loadFullNoteFromSpring(
    identifiers: {
      companyId: string;
      noteId: string;
    }
  ): Promise<FullSpringNote> {
    const allLargeStrings = Object.values(SpringNoteLargeStringKey).reduce((combined, key) => {
      combined[key] = true;
      return combined;
    }, {});
    return this.loadNoteFromSpring(identifiers, {
      largeStrings: allLargeStrings,
      admittedPatient: true,
    });
  }

  /**
   * Retrieve data for a note from the Java Spring API, specifying which parts to load.
   * @param identifiers - Identifiers for the note to retrieve data for
   * @param identifiers.companyId - The company id that the note resides in
   * @param identifiers.noteId - The id of the note
   * @param optionalData - An object to specify what optional data to load
   * @param optionalData.largeStrings - A object specifying which large strings to load, if any
   * @param optionalData.admittedPatient - Whether or not to load the admitted patient information
   */
  async loadNoteFromSpring<
    ToggledLargeStrings extends PartialNoteToggleableLargeStrings,
    ToggledFields extends PartialNoteToggleableFields,
    ExtendedNote extends
      SpringNote
      & PickedLargeStrings<ToggledLargeStrings>
      & PickedFields<ToggledFields>
  >(
    identifiers: { companyId: string; noteId: string; },
    optionalData?: {
      largeStrings?: ToggledLargeStrings;
    } & ToggledFields
  ):
    Promise<ExtendedNote>
  {
    const baseNote = await this.getNoteFromSpring(identifiers.companyId, identifiers.noteId);
    if (!optionalData) {
      return baseNote as ExtendedNote;
    }

    let extendedNote = baseNote as ExtendedNote;

    if (extendedNote.addedDocs) {
      // Retrieve addedDocs data
      await Promise.all(
        extendedNote.addedDocs.map((addedDoc) => {
          if (addedDoc.htmlId) {
            return this.loadCaseStorageDocument(
              identifiers.companyId,
              baseNote.patientSid,
              identifiers.noteId,
              `addedDocs/${addedDoc.htmlId}/html`
            ).then((html) => {
              addedDoc.html = html;
              return addedDoc;
            });
          } else {
            return addedDoc;
          }
        })
      ).then((addedDocs) => {
        extendedNote.addedDocs = addedDocs;
      }).catch((error) => {
        console.error(error);
        throw error;
      });
    }

    // Add any requested large strings
    if (optionalData?.largeStrings) {
      const toggledLargeStrings = optionalData.largeStrings;
      const largeStrings = await getAllPartialNoteLargeStrings(identifiers, toggledLargeStrings, this.getLargeStringFromSpringNote.bind(this));
      extendedNote = Object.assign(extendedNote, largeStrings);
    }

    // Add admitted patient information if requested
    if (optionalData.admittedPatient) {
      let admittedPatient = {} as AdmittedPatient;

      // Add fields from patientInfo included in the note
      if (baseNote.patientInfo) {
        const patientInfo = baseNote.patientInfo;
        admittedPatient = {
          ...patientInfo,
          firstName: patientInfo.first,
          lastName: patientInfo.last,
        };
      }

      // Merge in non-null fields from admittedPatient record using patientSid, if available
      if (baseNote.patientSid) {
        const admittedPatientRecord = (await this.getAdmittedPatient(baseNote)) ?? {};
        const nonNullAdmittedPatientFields = Object.fromEntries(Object.entries(admittedPatientRecord)
          .filter(([_key, value]) => {
            return value !== null && value !== undefined;
          }),
        );

        admittedPatient = {
          ...admittedPatient,
          ...nonNullAdmittedPatientFields,
        };
      }

      extendedNote = {
        ...extendedNote,
        admittedPatient,
      };
    }

    return extendedNote;
  }

  /**
   * Retrieve base note data from Java Spring API.
   * @param companyId - The company id that the note belongs under.
   * @param noteId - The id of the note under the company.
   * @returns The base note data.
   */
  async getNoteFromSpring(companyId: string, noteId: string): Promise<SpringNote> {
    return this.http.get<SpringNote>(`${JAVA_BACKEND_ENDPOINT}company/${companyId}/note/${noteId}/`)
      .pipe(take(1))
      .toPromise();
  }

  /**
   * Retrieve the admitted patient data.
   * @param identifiers - Identifiers of the admitted patient entry.
   * @param identifiers.companyId - The id of the company that the admitted patient belongs under.
   * @param identifiers.patientSid - The generated sid of the patient.
   * @returns The admitted patient data.
   */
  async getAdmittedPatient(identifiers: {
    companyId: string;
    patientSid: string;
  } | SpringNote | NoteListResponse): Promise<AdmittedPatient> {
    const { companyId, patientSid } = identifiers;
    return firebase
      .firestore()
      .collection('companies')
      .doc(companyId)
      .collection('admittedPatients')
      .doc(patientSid)
      .get()
      .then((snapshot) => snapshot.data() as AdmittedPatient);
  }

  /**
   * Converts PatientInfo to AdmittedPatient data type. Taken from the submit note backend algorithm.
   * @param companyId Company ID of the company the data is submitted to
   * @param patienstSid Patient ID to save the data under
   * @param patientInfo The patient info to save
   * @param latestNote A string indicating the time of the latest note submitted by the patient
   */
  async setAdmittedPatientData(
    companyId: string,
    patientSid: string,
    patientInfo: PatientInfo,
    latestNote: string
  ) {
    if (!patientInfo) {
      console.error('No patient info found');
      throw new Error('No patient info found');
    }
    if (
      !patientInfo.first ||
      !patientInfo.last ||
      !patientInfo.year ||
      !patientInfo.month ||
      !patientInfo.day ||
      !patientInfo.gender
    ) {
      console.error('Insufficient patient info');
      throw new Error('Insufficient patient info');
    }
    if (patientSid === UNDEFINED_PATIENT_SID) {
      throw new Error('Forbidden patientSid value');
    }

    const pid = patientSid;
    const cid = companyId;

    const lowerFirstName = patientInfo.first.toLowerCase();
    const lowerLastName = patientInfo.last.toLowerCase();
    const birthday = patientInfo.year + '/' + parseInt(patientInfo.month, 10) + '/' + parseInt(patientInfo.day, 10);
    const healthCardNumber = patientInfo.healthCardNumber ? patientInfo.healthCardNumber.toUpperCase() : null;
    const healthCardNumberDigits = patientInfo.healthCardNumber
      ? patientInfo.healthCardNumber.replace(/\D/g, '')
      : null;
    const basePatientReference = firebase
      .firestore()
      .collection('companies')
      .doc(cid)
      .collection('admittedPatients')
      .doc(pid);
    return basePatientReference.set(
      {
        pid,
        firstName: patientInfo.first,
        lastName: patientInfo.last,
        lowerFirstName,
        lowerLastName,
        birthday,
        pharmaFax: patientInfo.pharmaFax || null,
        pharmaName: patientInfo.pharmaName || null,
        gender: patientInfo.gender,
        phone: patientInfo.phone ? patientInfo.phone.replace(/\D/g, '') : null, // Only keep the digits
        address: patientInfo.address || null,
        healthCardNumber,
        healthCardNumberDigits,
        email: patientInfo.email || null,
        terms: patientInfo.terms || null,
        uid: patientInfo.uid || null,
        latestNote, // old format that is in some date string
        latestNoteTime: new Date().getTime() // new format in epoch ms
      },
      { merge: true }
    );
  }

  /**
   * Retrieve a large string for a Spring note.
   * @param springNote - The Spring note to load the large string for.
   * @param key - The name of the large string to load.
   * @returns The large string.
   */
  async getLargeStringFromSpringNote(springNote: { companyId: string; noteId: string; } | SpringNote, key: SpringNoteLargeStringKey): Promise<string> {
    // Get a new copy of the note for up to date link signatures
    const noteCopy = await this.getNoteFromSpring(springNote.companyId, springNote.noteId);
    const largeStringLink = noteCopy._links[key].href;

    // Get the requested large string
    return this.http.get(largeStringLink, { responseType: 'text' }).pipe(take(1)).toPromise();
  }

  /**
   * List notes from a company using the Java Spring API
   * @param companyId - The company id to list notes for.
   * @param params - Additional parameters for the query.
   * @param params.lastLoaded - The id of the note to start the index from.
   * @param params.size - The number of notes to list. Server default is 10.
   * @returns A list of notes under the specified company.
   */
  async getNoteListFromSpring(companyId: string, params: { lastLoaded?: string, size?: number, patientSid?: string } = {}): Promise<NoteListResponse[]> {
    const noteListResponses = await this.http.get<{ _embedded: { noteListResponses: NoteListResponse[] } }>(
      `${JAVA_BACKEND_ENDPOINT}company/${companyId}/note/`, {
        params: Object.entries(params).reduce((accumulator, [key, value]) => {
          if (value !== undefined) {
            accumulator[key] = value;
          }
          return accumulator;
        }, {}),
      }
    )
      .pipe(take(1))
      .toPromise()
      .then((response) => {
        if (response._embedded?.noteListResponses) {
          return response._embedded.noteListResponses
          .map((noteListResponse) => {
            noteListResponse.noteId = noteListResponse.noteId || noteListResponse.nid;
            return noteListResponse;
          })
          .filter((noteListResponse) => !!noteListResponse.noteId);
        } else {
          return [];
        }
      });
    return noteListResponses;
  }

  async getNoteListForAppointmentFromSpring(
    identifiers: {
      companyId: string;
      appointmentId: string;
    },
    params: {
      lastLoaded?: string;
      size?: number;
    } = {},
    includeLockedNotes?: boolean,
  ): Promise<NoteListResponse[]> {
    const { companyId, appointmentId } = identifiers;

    const uri = `${JAVA_BACKEND_ENDPOINT}company/${companyId}/appointment/${appointmentId}/note/${includeLockedNotes ? 'includeLocked' : ''}`;
    const queryParams = Object.entries(params).reduce((accumulator, [key, value]) => {
      if (value !== undefined) {
        accumulator[key] = value;
      }
      return accumulator;
    }, {});

    const noteListResponses = await this.http.get<{ _embedded: { noteListResponses: NoteListResponse[] } }>(uri, { params: queryParams })
      .pipe(take(1))
      .toPromise()
      .then((response) => {
        if (!response._embedded) {
          return [];
        }

        return response._embedded.noteListResponses
          .map((noteListResponse) => {
            noteListResponse.noteId = noteListResponse.noteId || noteListResponse.nid;
            return noteListResponse;
          })
          .filter((noteListResponse) => !!noteListResponse.noteId);
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
    return noteListResponses;
  }

  async getNoteListForPatientFromSpring(
    identifiers: {
      companyId: string;
      appointmentId: string;
      patientUid: string;
    },
  ): Promise<NoteListResponse[]> {
    const { companyId, appointmentId, patientUid } = identifiers;
    const uri = `${JAVA_BACKEND_ENDPOINT}company/${companyId}/appointment/${appointmentId}/patient/${patientUid}/note/`;
    const noteListResponses = await this.http.get<{ _embedded: { noteListResponses: NoteListResponse[] } }>(uri)
      .pipe(take(1))
      .toPromise()
      .then((response) => {
        if (!response._embedded) {
          return [];
        } else {
          return this.filterNoteListResponses(response._embedded.noteListResponses);
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
    return noteListResponses;
  }

  filterNoteListResponses(noteListResponses: NoteListResponse[]): NoteListResponse[] {
    return noteListResponses
      .map((noteListResponse) => {
        noteListResponse.noteId = noteListResponse.noteId || noteListResponse.nid;
        return noteListResponse;
      })
      .filter((noteListResponse) => !!noteListResponse.noteId);
  }

  async deleteNoteFromSpring(identifiers: { companyId: string; noteId: string; }): Promise<string> {
    const { companyId, noteId } = identifiers;
    const uri = `${JAVA_BACKEND_ENDPOINT}company/${companyId}/note/${noteId}`;
    return this.http.delete(uri, { responseType: 'text' }).pipe(take(1)).toPromise();
  }

  loadCaseStorageDocument(
    cid: string,
    psid: string,
    nid: string,
    pathExtension: string
  ): Promise<string> {
    return firebase
      .storage()
      .ref(`cases/${cid}/${psid}/${nid}/${pathExtension}.txt`)
      .getDownloadURL()
      .then((url) => this.http.get(url, { responseType: 'text' }).toPromise()) // If the download URL is available use it
      .catch((error) => {
        switch (error.code) {
          case 'storage/unauthorized': {
            // The user is not authorized by Firebase Storage, try authorizing through LAMBDA instead
            return firebase
              .auth()
              .currentUser.getIdToken()
              .then((idToken) =>
                this.http
                  .post(
                    `${AWSCLOUDFUNCTIONSENDPOINT}users/getStorageDocumentAsPatient`,
                    {
                      idToken,
                      storagePath: `cases/${cid}/${psid}/${nid}/${pathExtension}.txt`,
                    },
                    {
                      responseType: 'text',
                    }
                  )
                  .toPromise()
              );
          }

          case 'storage/object-not-found': {
            // The file does not exist, substitute with empty string
            return '';
          }

          default: {
            // Throw the error up for caller to handle
            console.error(error);
            throw error;
          }
        }
      });
  }

  // loads one document that failed to be faxed from the
  // faxStatus collection
  loadOneDocumentToReFax(IDToken:string, linkID:string, faxSid:string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.loadOneDocumentToReFaxURL,
          JSON.stringify({
            IDToken,
            linkID,
            faxSid
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  // audit trail for document sent <boolean>
  documentsSentForAppointment(IDToken, linkID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.documentsSentForAppointmentURL,
          JSON.stringify({
            IDToken,
            linkID,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  setAppointmentIsApproved(IDToken, linkID, isApproved = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.setAppointmentIsApprovedURL,
          JSON.stringify({
            IDToken,
            linkID,
            isApproved,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  checkIfQuestionnaireCompleted(
    companyID: string,
    apptID: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.checkIfQuestionnaireCompletedURL,
          JSON.stringify({
            companyID,
            apptID,
          }),
          { headers: this.headers }
        )
        .toPromise()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  processAllergyData(data) {
    const newData = data;
    // rhinitis field
    if (data.rhinitis) {
      newData['rhinitis' + this.capitalize(data.rhinitis)] = '✔';
    }

    // conjuctivitis
    if (data.conjunctivitis) {
      newData['conjunctivitis' + this.capitalize(data.conjunctivitis)] = '✔';
    }

    // asthma
    if (data.asthma) {
      newData['asthma' + this.capitalize(data.asthma)] = '✔';
    }

    if (data.timing) {
      newData['timing' + this.capitalize(data.timing)] = '✔';
    }

    // allergens
    if (data.allergens) {
      for (const a in data.allergens) {
        if (data.allergens[a].treatment) {
          newData[a + 'Treat'] = '✔';
          newData[a + 'Order'] =
            this.capitalize(data.allergens[a].treatment) +
            ' ' +
            data.allergens[a][data.allergens[a].treatment];
        }
      }
    }

    // treatment options
    if (data.treatmentOptions) {
      newData['treatment' + this.capitalize(data.treatmentOptions)] = '✔';
    }

    // payment method
    if (data.paymentMethod) {
      newData['payment' + this.capitalize(data.paymentMethod)] = '✔';
      if (data.paymentMethod === 'ohip') {
        newData.paymentOther = '✔';
        newData.otherPaymentMethod = 'OHIP+';
      }
    }

    // vials
    if (data.vialsInSet) {
      newData['vials' + data.vialsInSet] = '✔';
    }

    // treatment stage
    if (data.treatmentStage) {
      newData['treatmentStage' + this.capitalize(data.treatmentStage)] = '✔';
    }

    delete newData.setAArr;
    delete newData.setBArr;
    delete newData.setCArr;
    delete newData.allergens;

    return newData;
  }

  capitalize(inputString) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  async generatePDFRequisition(
    document: any,
    companyID: string,
    noteID: string
  ): Promise<any> {
    // Find the patientUID, if any
    console.log(companyID);
    console.log(noteID);
    let patientUID;
    if (!!companyID && !!noteID) {
      let patientSID;
      try {
        patientSID = (
          await firebase
            .firestore()
            .collection('companies')
            .doc(companyID)
            .collection('notes')
            .doc(noteID)
            .get()
        ).data().patientSID;
      } catch (err) {
        console.log(err);
      }
      if (patientSID) {
        patientUID = (
          await firebase
            .firestore()
            .collection('companies')
            .doc(companyID)
            .collection('admittedPatients')
            .doc(patientSID)
            .get()
        ).data().uid;
      }
    }

    const pdfPath = this.generateRandomID(32);
    document.url = PDF_BASE_PATH + pdfPath + '/' + companyID;
    let data: any = {};
    for (const i in document.data) {
      if (document.data.hasOwnProperty(i)) {
        data[i] = document.data[i];
      }
    }
    for (const i in data) {
      if (data.hasOwnProperty(i)) {
        if (this.radioGroups[i]) {
          data[data[i]] = 'x';
        }
        if (data[i] === true) {
          data[i] = 'x';
        }
        if (data[i] === undefined || data[i] === null) {
          delete data[i];
        }
      }
    }
    if (data['signature']) {
      data['image:signature'] = data['signature'].split(',')[1];
    }
    delete data['signature'];

    if (!this.templateIDs[document.type]) {
      console.log('TEMPLATE ID NOT FOUND!');
    }
    console.log(data);

    if (document.type === 'mri') {
      if (data['licenseCode']) {
        data['licenseCode'] = 'License Number: ' + data['licenseCode'];
      }
      if (data['providerNumber']) {
        data['providerNumber'] = 'Provider Number: ' + data['providerNumber'];
      }
    } else if (document.type === 'allergy') {
      data = this.processAllergyData(data);
      const time = new Date().toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      const date = new Date().toDateString();
      data.header = `Appletree Medical Group - ${date} - ${time}`;
      data.disclaimer = `This fax may be privileged and/or confidential, and the sender does
                                not waive any related rights and obligations. Any distribution, use or copying of
                                this fax or the information it contains by other than an intended recipient is
                                unauthorized. If you receive this fax in error, please fax it back immediately.`;
      data.page1 = 'Page 1/3';
      data.page2 = 'Page 2/3';
      data.page3 = 'Page 3/3';
      console.log(data);
    } else if (document.type === 'covidAntibodyOntario' || document.type === 'covidAntibodyOntarioTraveller'){
      data.clinicName = 'Tia Health';
    }

    const params: { [key: string]: any } = {
      pdfData: {
        data,
      },
      template: this.templateIDs[document.type],
      path: pdfPath,
      token: await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken(),
      company: companyID || null,
      patients: [patientUID],
    };
    console.log(patientUID);
    if (patientUID) {
      params.patients = [patientUID];
    }

    return this.http
      .post(this.fillPDFURL, JSON.stringify(params), { headers: this.headers })
      .toPromise()
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getAppointmentNotesPatientAdmin(IDToken, companyID, apptID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.getAppointmentNotesPatientAdminURL,
          JSON.stringify({
            IDToken,
            companyID,
            apptID,
          }),
          {
            headers: this.headers.append('Accept', 'application/pdf'),
            responseType: 'json',
          }
        )
        .toPromise()
        .then((response) => {
          console.log(response);
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }
} // end class
