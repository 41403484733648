// @ts-strict-ignore
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { VirtualService } from 'insig-app/services/virtual/virtual.service';
import { GeneralService } from 'insig-app/services/general.service';
import { CreateCompanyPlanDialogComponent } from '../../dialogs/create-company-plan/create-company-plan.component';
import { ConfirmCompanyPlanServiceDeleteDialogComponent } from '../../dialogs/confirm-company-plan-service-delete/confirm-company-plan-service-delete.component';
import { AddCompaniesToPlanDialogComponent } from '../../dialogs/add-companies-to-plan/add-companies-to-plan.component';
import { AddUserGroupsToPlanDialogComponent } from '../../dialogs/add-user-groups-to-plan/add-user-groups-to-plan.component';
import { ConfirmCompanyPlanDeleteDialogComponent } from '../../dialogs/confirm-company-plan-delete/confirm-company-plan-delete.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { APPCONFIG, APPURL } from '@insig-health/config/config';
import { SelectAppointmentSurveyDialogComponent } from 'insig-app/global/virtual/settings/dialogs/select-appointment-survey.component';
import { ClipboardService } from 'ngx-clipboard';

import { take } from 'rxjs/operators';
import { DoctorScheduleReindexService } from '@insig-health/services/doctor-schedule-reindex/doctor-schedule-reindex.service';

@Component({
  selector: 'company-plans',
  templateUrl: './company-plans.component.html',
  styleUrls: [
    './company-plans.component.scss',
    '../../shared/styles/info-page.style.scss',
  ],
})
export class CompanyPlansComponent implements OnInit, OnDestroy {


  @Input() userData: any;
  @Input() userListFinal: any;
  @Input() librarySurveys: any;
  public companyPlanVirtualServices: any = [];
  public selectedCategoryItem: any;
  public selectedCategory: any;
  public selectedCategoryName: any;
  public selectedGroup: any;
  public selectedService: any;
  private fullCompanyPlanSub: any;
  private companyPlanVirtualServicesSub: any;
  private companies: any;
  public companyPlans: any = [];
  public newService = false;
  public selectedPlan: any;
  public showBottomSheet = false;
  public subscribedCompanies: any = [];
  public subscribedUserGroups: any = [];
  private showSubscriptionWarning = false;

  // endpoints
  public AppConfig: any = APPCONFIG;

  constructor(
    private virtualService: VirtualService,
    private insigDialog: MatDialog,
    private snackBar: MatSnackBar,
    private generalService: GeneralService,
    private _clipboardService: ClipboardService,
    private doctorScheduleReindexService: DoctorScheduleReindexService,
  ) {}

  public menuItems: any;

  ngOnInit() {
    this.resetMenu();
    this.loadCompanyPlans();
  }

  resetMenu() {
    console.log(this.userData);

    this.menuItems = {
      title: 'Company Plans',
      showAdd: true,
      groups: [
        {
          title: 'All Company Plans',
          items: [],
          emptyArrStr: 'You currently have no company plans.',
        },
      ],
    };

    if (this.userData.type?.insigSuperAdmin) {
      console.log('is super admin');
      this.menuItems.groups[0].items.push({
        iden: 'tiaHealth',
        image: '/assets/images/logo/tia_blue.svg',
        title: 'Tia Health',
        companyID: 'tiaHealth',
      });
    }

    for (const plan of this.companyPlans) {
      this.menuItems.groups[0].items.push({
        iden: plan.id,
        image: plan.image ? plan.image : '/assets/images/logo/logo_short.svg',
        title: plan.title,
        companyID: plan.companyID,
      });
    }
  }

  async getUserGroups(): Promise<any> {
    const userGroups = await this.virtualService
      .getUserGroupsByCompany(this.userData.company)
      .pipe(take(1))
      .toPromise();
    if (userGroups) {
      return userGroups;
    } else {
      return [];
    }
  }

  loadCompanyPlans() {
    this.fullCompanyPlanSub = this.virtualService
      .getCompanyPlans(this.userData.company)
      .subscribe((snapshot) => {
        if (snapshot !== null) {
          this.companyPlans = snapshot;
          this.resetMenu();
        }
        console.log(snapshot);
      });
  }

  async addNewCategory() {
    console.log('adding new category');
    const dialogRef = this.insigDialog.open(CreateCompanyPlanDialogComponent);

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        console.log(selection);
        const newPlan = selection;
        newPlan.companyID = this.userData.company;

        await this.virtualService.addCompanyPlan(newPlan);
      }
    });
    return dialogRef.afterClosed();
  }

  editService(service: any) {
    console.log('editting service');
    console.log(service);
    this.newService = false;
    this.selectedService = service;
  }

  sortServicesFunc(array) {
    return array.sort((a, b) => {
      if (!a.data || !a.data.label) {
        return 1;
      }
      if (!b.data || !b.data.label) {
        return -1;
      }
      return a.data.label.localeCompare(b.data.label);
    });
  }

  async menuItemSelected(item: any) {
    console.log(item);

    delete this.companyPlanVirtualServices;
    delete this.selectedCategory;

    this.companyPlanVirtualServicesSub = this.virtualService
      .getCompanyPlanVirtualServicesByPlanID(item.iden)
      .subscribe((snapshot) => {
        if (snapshot !== null) {
          this.companyPlanVirtualServices = this.sortServicesFunc(snapshot);
        }
        console.log(snapshot);
      });

    this.selectedCategoryName = item.title ? item.title : 'Tia Health';
    this.selectedCategoryItem = item.iden;
    this.selectedCategory = item;

    this.selectedPlan = await this.getSelectedCompanyPlan(item);

    if (!this.selectedPlan.restrictions) {
      this.selectedPlan.restrictions = {};
    }
    this.subscribedCompanies = await this.getSelectedPlanCompanySubscriptions(
      item
    );
    this.subscribedUserGroups = await this.getSelectedPlanUserGroupSubscriptions(
      item
    );
    // this.showSubscriptionWarning = this.
    console.log(this.subscribedCompanies);
    console.log(this.subscribedUserGroups);
    console.log(this.selectedPlan);
  }

  async getSelectedCompanyPlan(item: any): Promise<any> {
    if (item.iden === 'tiaHealth') {
      const companyPlans = await this.virtualService
        .getCompanyPlans('tiaHealth')
        .pipe(take(1))
        .toPromise();
      if (!!companyPlans && companyPlans.length > 0) {
        return companyPlans[0];
      } else {
        return {
          companyID: 'tiaHealth',
          coverPlan: true,
          title: 'Tia Health',
        };
      }
    } else {
      for (const plan of this.companyPlans) {
        if (plan.id === item.iden) {
          return plan;
        }
      }
      return null;
    }
  }

  async getSelectedPlanCompanySubscriptions(item: any): Promise<any[]> {
    const companyPlans = await this.virtualService
      .getSubscribedCompanyPlansByPlanIDByType(item.iden, 'company')
      .pipe(take(1))
      .toPromise();
    if (companyPlans !== null && companyPlans.length > 0) {
      return companyPlans;
    } else {
      return [];
    }
  }

  async getSelectedPlanUserGroupSubscriptions(item: any): Promise<any> {
    const companyPlans = await this.virtualService
      .getSubscribedCompanyPlansByPlanIDByType(item.iden, 'userGroup')
      .pipe(take(1))
      .toPromise();
    if (companyPlans !== null && companyPlans.length > 0) {
      return companyPlans;
    } else {
      return [];
    }
  }

  setMinPrice(data) {
    const price = data.price;
    if (!price || parseFloat(price) < 5) {
      data.price = '5.00';
    } else {
      data.price = (Math.round(price * 100) / 100).toFixed(2);
    }
  }

  addNewService() {
    this.newService = true;
    this.selectedService = {
      apptID: '',
      data: {
        duration: 5,
        buffer: 0,
        coverAmount: 40,
        coverPercent: 100,
        category: null,
        desc: '',
        label: '',
        private: false,
        phone: true,
        price: '40.00',
        video: true,
        inPerson: true,
        serviceCode: 'A007',
        diagnosticCode: '799',
        survey: {
          name: 'Default Questionnaire',
          surveyID: 'default',
          userID: 'default',
        },
      },
    };
  }

  back() {
    this.save();
    delete this.selectedService;
  }

  save() {
    console.log(this.selectedService);

    if (this.newService) {
      const newService = this.selectedService;
      newService.companyID = this.selectedCategory.companyID;
      newService.planID = this.selectedCategory.iden;
      newService.apptID = this.generalService.generateRandomID(32);
      this.virtualService.addCompanyPlanVirtualService(newService);
      this.snackBar.open('Service added to company plan!', null, {
        duration: 4000,
      });
      delete this.selectedService;
    } else {
      const editedServiceIden = this.selectedService.id;
      const editedServiceData = {
        ...this.selectedService,
      };
      delete editedServiceData.id;

      this.virtualService.updateCompanyPlanVirtualService(
        editedServiceIden,
        editedServiceData,
      );
      this.snackBar.open('Service saved!', null, { duration: 4000 });
      delete this.selectedService;
    }
  }

  async delete() {
    console.log('delete clicked');

    const dialogRef = this.insigDialog.open(
      ConfirmCompanyPlanServiceDeleteDialogComponent
    );

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        await this.virtualService.removeCompanyPlanVirtualService(
          this.selectedService.id
        );
        this.snackBar.open('Service removed from company plan!', null, {
          duration: 4000,
        });
        delete this.selectedService;
      }
    });
    return dialogRef.afterClosed();
    // this.showView = 'list';
    // this.virtualService.removeUserVirtualService(this.selectedService.id);
  }

  selectSurvey() {
    const dialogRef = this.insigDialog.open(
      SelectAppointmentSurveyDialogComponent
    );
    dialogRef.componentInstance.userList = this.userListFinal;
    dialogRef.componentInstance.librarySurveys = this.librarySurveys;
    dialogRef.componentInstance.appointment = true;
    dialogRef.afterClosed().subscribe((result) => {
      console.log(this.selectedService);
      if (result) {
        if (result === 'none') {
          this.selectedService.data.survey = null;
        } else if (result === 'default') {
          this.selectedService.data.survey = {
            name: 'Default Questionnaire',
            surveyID: 'default',
            userID: 'default',
          };
        } else if (result.survey) {
          this.selectedService.data.survey = result.survey;
        }
        console.log(result);
        // this.saveUserData.emit(true);
        // this.save();
      }
    }); // end after closed
  } // end func

  cancel() {
    console.log('cancelling service');
    delete this.selectedService;
  }

  async companyPlanSettingsToggle(save = false) {
    console.log(save);
    this.showBottomSheet = !this.showBottomSheet;
    if (save) {
      console.log(this.selectedPlan);
      const iden = this.selectedPlan.id;
      const savedPlan = this.selectedPlan;
      delete savedPlan.id;
      await this.virtualService.updateCompanyPlans(iden, savedPlan);
      this.snackBar.open('Company Plan Updated', null, { duration: 4000 });
    }
  }

  saveCompanyPlanSettings() {
    this.companyPlanSettingsToggle(true);
    this.snackBar.open('Company Plan settings saved!', null, {
      duration: 4000,
    });
  }

  async getAllCompanies() {
    try {
      let allCompanies: any[] = [];

      // Only display companies that have an id and a name
      allCompanies = allCompanies.filter(
        (company) => !!company.id && !!company.name
      );

      // Sort companies by alphabetical order
      allCompanies = allCompanies.sort((a, b) => a.name.localeCompare(b.name));

      console.log(allCompanies);
      return allCompanies;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  checkIfCompaniesAreSubscribed(companyID) {
    for (const sub of this.subscribedCompanies) {
      if (sub.companyID === companyID) {
        return true;
      }
    }
    return false;
  }

  checkIfGroupsAreSubscribed(userGroupID) {
    for (const sub of this.subscribedUserGroups) {
      if (sub.userGroupID === userGroupID) {
        return true;
      }
    }
    return false;
  }

  async subscribeToPlan(obj: any, type: string): Promise<any> {
    let data;
    if (type === 'company') {
      data = {
        companyID: this.userData.company,
        subscribedCompanyID: obj.id,
        groupName: obj.name,
        planID: this.selectedCategory.iden,
        type,
        title: this.selectedCategory.title,
      };
    } else {
      data = {
        companyID: this.userData.company,
        subscribedUserGroupID: obj.id,
        groupName: obj.title,
        planID: this.selectedCategory.iden,
        type,
        groupType: obj.type,
        title: this.selectedCategory.title,
      };
    }

    await this.virtualService.addSubscribedCompanyPlan(data);
  }

  async addSubscribedCompany() {
    console.log(this.selectedCategory);

    const companyList = await this.getAllCompanies();
    console.log(companyList);

    const dialogRef = this.insigDialog.open(AddCompaniesToPlanDialogComponent, {
      data: {
        companyList,
      },
    });

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        if (selection.length > 0) {
          for (const company of selection) {
            if (!this.checkIfCompaniesAreSubscribed(company.id)) {
              console.log('adding company');
              await this.subscribeToPlan(company, 'company');
            }
          }
          this.subscribedCompanies = await this.getSelectedPlanCompanySubscriptions(
            this.selectedCategory
          );
          this.subscribedUserGroups = await this.getSelectedPlanUserGroupSubscriptions(
            this.selectedCategory
          );
        }
      }
    });
    return dialogRef.afterClosed();
  }

  async addSubscribedUserGroup(type: string) {
    console.log('selected category', this.selectedCategory);
    let userGroupList = await this.getUserGroups();

    const dialogRef = this.insigDialog.open(AddUserGroupsToPlanDialogComponent, {
      height: '80vh',
    });
    userGroupList = userGroupList.filter((group) => group.type === type);
    dialogRef.componentInstance.userGroupList = userGroupList;
    dialogRef.componentInstance.type = type;


    dialogRef.afterClosed().subscribe(async (selection) => {
      console.log('selection', selection);

      if (selection && selection.length > 0) {
        for (const group of selection) {
          if (!this.checkIfGroupsAreSubscribed(group.id)) {
            console.log('adding user group');
            await this.subscribeToPlan(group, 'userGroup');

            const userGroup = await this.virtualService.getUserGroupSubscribedByGroupIdData(group.id).pipe(take(1)).toPromise();

            const reindexingPromises = userGroup.map((user) => {
              if (user?.doctorID && user?.companyID) {
                return this.doctorScheduleReindexService.reindexSchedule(user.doctorID, user.companyID);
              }
            });

            await Promise.all(reindexingPromises);
          }
        }
        // this.subscribedCompanies = await this.getSelectedPlanCompanySubscriptions(this.selectedCategory);
        this.subscribedUserGroups = await this.getSelectedPlanUserGroupSubscriptions(
          this.selectedCategory,
        );
      }
    });
    return dialogRef.afterClosed();
  }

  copyPlanURL(){
    // https://app.insighealth.com/virtual/book-appointment/TnxJ5ZxJkZbLB493WVeaty2KwVtQhdl2;planID=z7H0AYFpyit8VfY8iNkO
    const planID = this.selectedCategory.iden;
    const companyID = this.selectedCategory.companyID;
    this._clipboardService.copyFromContent(`${APPURL}virtual/book-appointment/${companyID};planID=${planID}`);
    this.snackBar.open('URL Copied to Clipboard', null, {
      duration: 4000,
    });
  }

  async deleteCompanyPlan() {
    const dialogRef = this.insigDialog.open(
      ConfirmCompanyPlanDeleteDialogComponent,
    );

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        for (const comp of this.subscribedCompanies) {
          await this.virtualService.removeSubscribedCompanyPlan(comp.id);
        }

        for (const group of this.subscribedUserGroups) {
          await this.virtualService.removeSubscribedCompanyPlan(group.id);

          const userGroup = await this.virtualService.getUserGroupSubscribedByGroupIdData(group.subscribedUserGroupID).pipe(take(1)).toPromise();

          const reindexingPromises = userGroup.map((user) => {
            if (user?.doctorID && user?.companyID) {
              return this.doctorScheduleReindexService.reindexSchedule(user.doctorID, user.companyID);
            }
          });

          await Promise.all(reindexingPromises);
        }

        for (const service of this.companyPlanVirtualServices) {
          await this.virtualService.removeCompanyPlanVirtualService(service.id);
        }

        // NOTE: need to add deletion of reference to VC appointments by doctor

        await this.virtualService.removeCompanyPlan(this.selectedCategory.iden);
        delete this.selectedCategory;
        this.snackBar.open('Company Plan Deleted', null, { duration: 4000 });
      }
    });
    return dialogRef.afterClosed();
  }

  async removeSubscribedElement(obj: any) {
    console.log(obj);
    await this.virtualService.removeSubscribedCompanyPlan(obj.id);
    this.subscribedCompanies = await this.getSelectedPlanCompanySubscriptions(
      this.selectedCategory
    );
    this.subscribedUserGroups = await this.getSelectedPlanUserGroupSubscriptions(
      this.selectedCategory
    );
  }

  ngOnDestroy() {
    if (this.fullCompanyPlanSub) {
      this.fullCompanyPlanSub.unsubscribe();
    }

    if (this.companyPlanVirtualServicesSub) {
      this.companyPlanVirtualServicesSub.unsubscribe();
    }
  }
}
