import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirm-preview-dialog',
  templateUrl: './confirm-preview-dialog.component.html',
})
export class ConfirmPreviewDialogComponent {
  public surveyName = null;

  constructor(public dialogRef: MatDialogRef<ConfirmPreviewDialogComponent>) {}
}
