import { Injectable } from '@angular/core';
import {
  FamilyMemberDataNonMedicalResponse,
  FamilyMemberRequest as ApiFamilyMemberRequest,
  FamilyMemberResponse,
  FamilyMemberSuccessResponse,
  PatientManagementService,
  SuccessResponse,
} from '@insig-health/api/patient-management-api';
import { firstValueFrom } from 'rxjs';
import { RegexService } from '../regex/regex.service';

export interface FamilyMemberProfile {
  familyMemberId: string;
  patientId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  day: number;
  month: number;
  year: number;
  birthdate: string;
  gender: 'Male' | 'Female';
  email: string;
  phone: string;
  extension?: string;
  healthCardNumber: string;
  address: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  pharmacyName: string;
  pharmacyFax: string;
  familyDoctorFirstName?: string;
  familyDoctorLastName?: string;
  familyDoctorFullName?: string;
  familyDoctorFaxNumber?: string;
  prefersSendNoteToFamilyDoctor?: boolean;
}

export interface FamilyMemberRequest {
  first: string;
  last: string;
  day: number;
  month: number;
  year: number;
  gender: string;
  address: string;
  city: string;
  province: string;
  postalCode?: string;
  healthCardNumber?: string;
  healthCardProvince?: string;
  phone: string;
  latestNote?: string;
  latestNoteTime?: number;
  pharmaFax?: string;
  pharmaName?: string;
  promo?: boolean;
  terms?: boolean;
  extension?: string;
  country?: string;
  disableFamilySMS?: string;
  familyDoctorFirstName?: string;
  familyDoctorLastName?: string;
  familyDoctorFullName?: string;
  familyDoctorFaxNumber?: string;
  prefersSendNoteToFamilyDoctor?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FamilyMemberService {

  constructor(
    private patientManagementService: PatientManagementService,
  ) {}

  async getFamilyMemberProfiles(patientId: string): Promise<FamilyMemberProfile[]> {
    const familyMembers = await firstValueFrom(this.patientManagementService.getFamilyMembersNonMedicalByPatientId(patientId));
    return familyMembers.map((familyMemberResponse) => this.getFamilyMemberProfileFromResponse(familyMemberResponse));
  }

  async getFamilyMemberProfile(patientId: string, familyMemberId: string): Promise<FamilyMemberProfile> {
    const familyMemberProfiles = await this.getFamilyMemberProfiles(patientId);
    const familyMemberProfile = familyMemberProfiles.find((familyMemberProfile) => familyMemberProfile.familyMemberId === familyMemberId);
    if (familyMemberProfile === undefined) {
      throw new Error(`Family member with id ${familyMemberId} not found`);
    }
    return familyMemberProfile;
  }

  getFamilyMemberProfileFromResponse(familyMemberResponse: FamilyMemberResponse): FamilyMemberProfile {
    const familyMemberDataNonMedicalResponse = familyMemberResponse.familyMemberDataResponse;

    if (familyMemberDataNonMedicalResponse.id === undefined) {
      throw new Error('Family member id not defined in response');
    }

    if (familyMemberDataNonMedicalResponse.gender !== 'Male' && familyMemberDataNonMedicalResponse.gender !== 'Female') {
      throw new Error('Incorrect gender schema from response');
    }

    if (familyMemberDataNonMedicalResponse.day === undefined || familyMemberDataNonMedicalResponse.month === undefined || familyMemberDataNonMedicalResponse.year === undefined) {
      throw new Error(`No birthdate defined for ${familyMemberDataNonMedicalResponse.id}`);
    }

    return {
      familyMemberId: familyMemberDataNonMedicalResponse.id,
      patientId: familyMemberResponse.patientId,
      firstName: familyMemberDataNonMedicalResponse.first ?? '',
      lastName: familyMemberDataNonMedicalResponse.last ?? '',
      fullName: this.getFullName(familyMemberDataNonMedicalResponse),
      day: familyMemberDataNonMedicalResponse.day,
      month: familyMemberDataNonMedicalResponse.month,
      year: familyMemberDataNonMedicalResponse.year,
      birthdate: this.formatBirthDate(familyMemberDataNonMedicalResponse),
      gender: familyMemberDataNonMedicalResponse.gender,
      email: '',
      phone: familyMemberDataNonMedicalResponse.phone ?? '',
      extension: familyMemberDataNonMedicalResponse.extension ?? '',
      healthCardNumber: familyMemberDataNonMedicalResponse.healthCardNumber ?? '',
      address: familyMemberDataNonMedicalResponse.address ?? '',
      city: familyMemberDataNonMedicalResponse.city ?? '',
      province: familyMemberDataNonMedicalResponse.province ?? '',
      country: familyMemberDataNonMedicalResponse.country ?? '',
      postalCode: familyMemberDataNonMedicalResponse.postalCode ?? '',
      pharmacyName: familyMemberDataNonMedicalResponse.pharmaName ?? '',
      pharmacyFax: familyMemberDataNonMedicalResponse.pharmaFax ?? '',
      familyDoctorFirstName: familyMemberDataNonMedicalResponse.familyDoctorFirstName ?? '',
      familyDoctorLastName: familyMemberDataNonMedicalResponse.familyDoctorLastName ?? '',
      familyDoctorFullName: familyMemberDataNonMedicalResponse.familyDoctorFullName ?? '',
      familyDoctorFaxNumber: familyMemberDataNonMedicalResponse.familyDoctorFaxNumber ?? '',
      prefersSendNoteToFamilyDoctor: familyMemberDataNonMedicalResponse.requestSendNoteToFamilyDoctor ?? undefined,
    };
  }

  getFullName(familyMemberDataNonMedicalResponse: FamilyMemberDataNonMedicalResponse): string {
    const { first, last } = familyMemberDataNonMedicalResponse;
    if (first === undefined && last === undefined) {
      return '';
    }
    if (first === undefined && last !== undefined) {
      return last;
    }
    if (first !== undefined && last === undefined) {
      return first;
    }
    return `${first} ${last}`;
  }

  formatBirthDate(familyMemberDataNonMedicalResponse: FamilyMemberDataNonMedicalResponse): string {
    const formattedMonth = ('0' + familyMemberDataNonMedicalResponse.month).slice(-2);
    const formattedDay = ('0' + familyMemberDataNonMedicalResponse.day).slice(-2);
    return `${familyMemberDataNonMedicalResponse.year}-${formattedMonth}-${formattedDay}`;
  }

  createNewFamilyMember(familyMemberRequest: FamilyMemberRequest): Promise<FamilyMemberSuccessResponse> {
    const apiFamilyMemberRequest = this.getFamilyMemberApiRequest(familyMemberRequest);
    return firstValueFrom(this.patientManagementService.createFamilyMemberNonMedicalForPatient(apiFamilyMemberRequest));
  }

  setFamilyMember(patientId: string, familyMemberId: string, familMemberProfile: FamilyMemberProfile): Promise<SuccessResponse> {
    const familyMemberRequest = this.getFamilyMemberRequestFromProfile(familMemberProfile);
    const apiFamilyMemberRequest = this.getFamilyMemberApiRequest(familyMemberRequest);
    return firstValueFrom(this.patientManagementService.updateFamilyMemberNonMedical(patientId, familyMemberId, apiFamilyMemberRequest));
  }

  deleteFamilyMember(patientId: string, familyMemberId: string): Promise<SuccessResponse> {
    return firstValueFrom(this.patientManagementService.deleteFamilyMemberNonMedical(patientId, familyMemberId));
  }

  getFamilyMemberApiRequest(familyMemberRequest: FamilyMemberRequest): ApiFamilyMemberRequest {
    if (familyMemberRequest.healthCardNumber) {
      familyMemberRequest.healthCardNumber = familyMemberRequest.healthCardNumber.replace(RegexService.WHITESPACE_REGEX, '');
      familyMemberRequest.healthCardNumber = familyMemberRequest.healthCardNumber.replace(RegexService.DASH_REGEX, '');
    }

    return {
      first: familyMemberRequest.first,
      last: familyMemberRequest.last,
      day: familyMemberRequest.day,
      month: familyMemberRequest.month,
      year: familyMemberRequest.year,
      gender: familyMemberRequest.gender,
      address: familyMemberRequest.address,
      city: familyMemberRequest.city,
      province: familyMemberRequest.province,
      postalCode: familyMemberRequest.postalCode,
      healthCardNumber: familyMemberRequest.healthCardNumber,
      healthCardProvince: familyMemberRequest.healthCardProvince,
      phone: familyMemberRequest.phone,
      latestNote: familyMemberRequest.latestNote,
      latestNoteTime: familyMemberRequest.latestNoteTime,
      pharmaFax: familyMemberRequest.pharmaFax,
      pharmaName: familyMemberRequest.pharmaName,
      promo: familyMemberRequest.promo,
      terms: familyMemberRequest.terms,
      extension: familyMemberRequest.extension,
      country: familyMemberRequest.country,
      disableFamilySMS: familyMemberRequest.disableFamilySMS,
      familyDoctorFirstName: familyMemberRequest.familyDoctorFirstName,
      familyDoctorLastName: familyMemberRequest.familyDoctorLastName,
      familyDoctorFullName: familyMemberRequest.familyDoctorFullName,
      familyDoctorFaxNumber: familyMemberRequest.familyDoctorFaxNumber,
      requestSendNoteToFamilyDoctor: familyMemberRequest.prefersSendNoteToFamilyDoctor,
    };
  }

  private getFamilyMemberRequestFromProfile(familyMemberProfile: FamilyMemberProfile): FamilyMemberRequest {
    return {
      ...familyMemberProfile,
      first: familyMemberProfile.firstName,
      last: familyMemberProfile.lastName,
      healthCardProvince: familyMemberProfile.province,
    };
  }
}
