// @ts-strict-ignore
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { fromEvent as observableFromEvent } from 'rxjs';
import { takeUntil, switchMap, pairwise } from 'rxjs/operators';

@Component({
  selector: 'drawing-edit-dialog-question',
  templateUrl: './drawing-edit-dialog.component.html',
  styleUrls: ['./drawing-edit.style.css'],
})
export class DrawingEditDialogComponent implements OnInit, AfterViewInit {
  @Input() question = null;
  // a reference to the canvas element from our template
  @ViewChild('canvas') public canvas: ElementRef;
  @ViewChild('container') public container: ElementRef;
  canvasEl: HTMLCanvasElement = null;
  public cx: CanvasRenderingContext2D;
  dimensions = { width: 600, height: 400 };
  background = null;
  drawingBorder = 'drawingBorder';
  lineWidths = [2, 4, 6, 8, 10];
  lineWidth = this.lineWidths[0];
  colors = [
    '#000',
    '#9CB199',
    '#CF3759',
    '#485247',
    '#E77547',
    '#D38E47',
    '#0A6A74',
    '#153974',
  ];
  selectedColor = this.colors[0];

  constructor(private dialogRef: MatDialogRef<DrawingEditDialogComponent>) {}

  ngOnInit(): void {
    // remove border if image question
    if (this.question.images) {
      this.drawingBorder = '';
    }
  }

  ngAfterViewInit(): void {
    this.canvasEl = this.canvas.nativeElement;
    this.cx = this.canvasEl.getContext('2d');
    // check if background image
    if (this.question.images) {
      this.setupImageCanvas();
    } else {
      this.setupCanvas();
    }
    this.setCanvasDefaults(this.canvasEl);
  } // end after view init

  doneDrawing(): void {
    this.dialogRef.close(this.canvas.nativeElement.toDataURL());
  }

  setCanvasDefaults(canvasEl: HTMLCanvasElement): void {
    // set some default properties about the line
    this.cx.lineWidth = this.lineWidth;
    this.cx.lineCap = 'round';
    // This chooses the color
    this.cx.strokeStyle = this.selectedColor;
    // capturing mouse events
    this.captureEvents(canvasEl);
  }

  setDimensions(): void {
    this.dimensions.width = this.container.nativeElement.offsetWidth;
    if (window.screen.width < 500) {
      this.dimensions.height = this.container.nativeElement.offsetHeight;
    } else {
      this.dimensions.height = this.container.nativeElement.offsetHeight * 2;
    }
  }

  setupImageCanvas(): void {
    const canvasEl = this.canvasEl;
    // setup image
    const background = (this.background = new Image());
    background.crossOrigin = 'Anonymous';
    // Make sure the image is loaded first otherwise nothing will draw.
    // and scaling image size properly
    background.src = this.question.images;
    this.setDimensions();
    const dimensions = this.dimensions;
    background.onload = () => {
      // console.log("loaded")
      if (
        background.width > dimensions.width ||
        background.height > dimensions.height
      ) {
        if (background.width > background.height) {
          dimensions.height =
            background.height * (dimensions.width / background.width);
        } else {
          dimensions.width =
            background.width * (dimensions.height / background.height);
        }
      } else {
        dimensions.width = background.width;
        dimensions.height = background.height;
      }
      canvasEl.width = dimensions.width;
      canvasEl.height = dimensions.height;
      canvasEl
        .getContext('2d')
        .drawImage(background, 0, 0, dimensions.width, dimensions.height);
    };
  }

  setupCanvas(): void {
    const canvasEl = this.canvasEl;
    // set the width and height
    this.setDimensions();
    canvasEl.width = this.dimensions.width;
    canvasEl.height = this.dimensions.height;
  }

  changeColor(color: string): void {
    this.cx.strokeStyle = this.selectedColor = color;
  }

  changeLineWidth(size: number): void {
    this.cx.lineWidth = this.lineWidth = size;
  }

  clear(): void {
    if (this.question.images) {
      this.cx.clearRect(0, 0, this.dimensions.width, this.dimensions.height);
      const cx = this.canvas.nativeElement.getContext('2d');
      cx.drawImage(
        this.background,
        0,
        0,
        this.dimensions.width,
        this.dimensions.height
      );
    } else {
      this.cx.clearRect(0, 0, this.dimensions.width, this.dimensions.height);
    }
  }

  private captureEvents(canvasEl: HTMLCanvasElement): void {
    // mouse
    observableFromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((_e) => {
          return observableFromEvent(canvasEl, 'mousemove').pipe(
            takeUntil(observableFromEvent(canvasEl, 'mouseup')),
            pairwise()
          );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top,
        };
        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top,
        };
        this.drawOnCanvas(prevPos, currentPos);
      });
    // touch display
    observableFromEvent(canvasEl, 'touchstart')
      .pipe(
        switchMap((_e) => {
          return observableFromEvent(canvasEl, 'touchmove').pipe(
            takeUntil(observableFromEvent(canvasEl, 'touchend')),
            pairwise()
          );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
        const rect = canvasEl.getBoundingClientRect();
        const prevPos = {
          x: res[0].touches[0].clientX - rect.left,
          y: res[0].touches[0].clientY - rect.top,
        };
        const currentPos = {
          x: res[1].touches[0].clientX - rect.left,
          y: res[1].touches[0].clientY - rect.top,
        };
        this.drawOnCanvas(prevPos, currentPos);
      });
  } // end capture events

  private drawOnCanvas(
    prevPos: { x: number; y: number },
    currentPos: { x: number; y: number }
  ): void {
    // incase the context is not set
    if (!this.cx) {
      return;
    }
    // start our drawing path
    this.cx.beginPath();
    // we're drawing lines so we need a previous position
    if (prevPos) {
      // sets the start point
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      // draws a line from the start pos until the current position
      this.cx.lineTo(currentPos.x, currentPos.y);
      // strokes the current path with the styles we set earlier
      this.cx.stroke();
    }
  } // end draw on canvas
} // end component
