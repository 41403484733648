// @ts-strict-ignore
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'pdf-textfield-dialog-question',
  templateUrl: './pdf-textfield-dialog.component.html',
  styles: ['.minWidth{min-width:30em;}'],
})
export class PDFTextFieldDialogComponent implements OnInit {
  public text: any;
  public loading: any;
  public signature = false;
  @ViewChild('textBox') textBox;

  constructor(public dialogRef: MatDialogRef<PDFTextFieldDialogComponent>) {}

  ngOnInit() {
    try {
      setTimeout(() => {
        this.textBox.nativeElement.focus();
      });
    } catch (err) {
      console.log(err);
    }
  }
} // end component
