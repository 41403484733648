// @ts-strict-ignore
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { PRODUCTION } from '@insig-health/config/config';

import { PDFDialogComponent } from './pdf-dialog.component';

import { from as observableFrom, Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';

import { Question } from 'insig-types/surveys';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

@Component({
  selector: 'pdf-question',
  templateUrl: './pdf.component.html',
  styles: [
    '.marginLeft47em{margin-left:4.7em;} .maxWidth75{max-width:75%;} .smallMarginTop{margin-top:0.6em;}',
  ],
})
export class PDFComponent implements OnChanges, OnInit {
  @Input() companyID = null;
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();
  @Input() morePDFs = null;
  @Input() uploadedPDFIDs = {};
  public error = false;
  private loading = true;
  private sanitizedPDFURL = null;
  private page = 1;

  public authTokenObservable: Observable<string>;

  private putPdfEndpoint = PRODUCTION
    ? 'https://express.goinsig.com/s3/insig-filled-pdfs/putPdf/'
    : 'https://dev.express.goinsig.com/s3/insig-filled-pdfs/putPdf/';
  private getPdfEndpoint = PRODUCTION
    ? 'https://express.goinsig.com/s3/insig-filled-pdfs/getPdf/'
    : 'https://dev.express.goinsig.com/s3/insig-filled-pdfs/getPdf/';

  constructor(
    private firebaseAuthService: FirebaseAuthService,
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    private http: HttpClient,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    try {
      this.authTokenObservable = this.firebaseAuthService.onIdTokenChanged().pipe(
        switchMap((user) => observableFrom(user.getIdToken())),
        shareReplay({ refCount: true, bufferSize: 1 })
      );
    } catch (error) {
      console.log(error);
    }
    if (this.question.pdfType !== 'patient') {
      this.sanitizedPDFURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
        this.question.pdfURL
      );
    }
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  removePDF(i) {
    if (this.morePDFs) {
      const index = this.morePDFs.findIndex(
        (x) => x.url === this.question.patientPDFs[i].url
      );
      this.morePDFs.splice(index, 1);
    }
    this.uploadedPDFIDs[this.question.patientPDFs[i].pdfDictID] = null;
    delete this.uploadedPDFIDs[this.question.patientPDFs[i].pdfDictID];
    this.question.patientPDFs.splice(i, 1);
  }

  openPDF() {
    const dialogRef = this.dialog.open(PDFDialogComponent);
    dialogRef.componentInstance.question = this.question;
    dialogRef.afterClosed().subscribe((_result) => {
      this.page = 1;
      const response = [];
      if (this.question.canvasOptions) {
        this.question.canvasOptions.forEach((cx) => {
          response.push(cx.canvas.toDataURL());
        });
        this.question.response = response;
        this.question.canvasOptions = null;
      }
    });
  }

  callBackFn(pdf: any) {
    if (!pdf.numPages || pdf.numPages < 1) {
      this.error = true;
    } else {
      this.question.numPages = pdf.numPages;
    }
    this.loading = false;
  }

  onError(error: any) {
    this.error = true;
    console.log('PDF Error!');
    console.log(error);
  }

  async onAdded(fileDropEntries: NgxFileDropEntry[]): Promise<void> {
    this.loading = true;

    if (!this.isFileOfTypePdf(fileDropEntries[0])) {
      this.snackBar.open('Please upload a pdf file', null, { duration: 2000 });
      this.loading = false;
      return;
    }

    const fileDrop = fileDropEntries[0];
    const fileName = fileDrop.fileEntry.name;
    const fileData = await new Promise<File>((resolve) => {
      const fileEntry = fileDrop.fileEntry as FileSystemFileEntry;
      fileEntry.file((file) => {
        resolve(file);
      });
    });

    let IDToken = null;
    let patients = [];
    try {
      IDToken = await this.firebaseAuthService.getFirebaseCurrentUser().getIdToken();
      patients = [
        (await this.firebaseAuthService.getFirebaseCurrentUser().getIdTokenResult()).claims.user_id,
      ];
    } catch (error) {
      console.log(error);
    }

    const reader = new FileReader();
    reader.onloadend = async (_event) => {
      try {
        const body = {
          token: IDToken,
          payload: [Array.from(new Uint8Array(reader.result as ArrayBuffer))],
          patients,
          company: this.companyID,
        };
        console.log(body);
        const res = await this.http
          .post<any>(this.putPdfEndpoint, body)
          .toPromise();
        console.log(res);

        if (!this.question.patientPDFs) {
          this.question.patientPDFs = [];
        }

        const pdfObject = {
          pdfDictID: this.generateRandomID(32),
          url: this.getPdfEndpoint + res.documentID + '/' + this.companyID,
          name: fileName,
        };
        console.log(pdfObject);
        this.morePDFs.push(pdfObject);
        this.question.patientPDFs.push(pdfObject);

        // this is done to avoid saving the data in the note obj, just tempeorary while compelting survey
        this.uploadedPDFIDs[
          pdfObject.pdfDictID
        ] = this.domSanitizer.bypassSecurityTrustResourceUrl(
          'data:application/pdf;base64,' +
            this.arrayBufferToBase64(reader.result as ArrayBuffer)
        );
      } catch (error) {
        console.error(error);
        this.snackBar.open('Error uploading file!', null, { duration: 2000 });
      } finally {
        this.loading = false;
      }
    };
    reader.readAsArrayBuffer(fileData);
  } // end func

  isFileOfTypePdf(file: NgxFileDropEntry): boolean {
    const pdfMatchRegex = /^.*\.pdf$/;
    const result = file.fileEntry.name.match(pdfMatchRegex);

    if (result !== null && file.fileEntry.isFile === true) {
      return true;
    } else {
      return false;
    }
  }

  generateRandomID(length) {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * Converts Uint8Array to base64 string
   * @param  buffer ArrayBuffer of Uint8
   * @return        Raw base64 string
   */
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
} // end component
