// @ts-strict-ignore
import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Question } from 'insig-types/surveys';

import { DrawingDialogComponent } from './drawing-dialog.component';

@Component({
  selector: 'drawing-question',
  templateUrl: './drawing.component.html',
  styleUrls: ['./drawing.style.css'],
})
export class DrawingComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Output() questionChange = new EventEmitter<Question>();

  drawingBorder = 'drawingBorder';
  dimensions = { width: 100, height: 400 };
  @ViewChild('container') public container: ElementRef;
  maxWidth = 500;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
    // remove border if image question
    if (this.question.images) {
      this.drawingBorder = null;
    }
    this.maxWidth = this.container.nativeElement.offsetWidth;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.question) {
      this.questionChange.emit(this.question);
    }
  }

  startDrawing(): void {
    const dialogRef = this.dialog.open(DrawingDialogComponent);
    dialogRef.componentInstance.question = this.question;
    dialogRef.afterClosed().subscribe((_result) => {
      if (this.question.doneLoading) {
        console.log('Saving');
        this.question.response.answer = this.question.canvas.toDataURL();
        this.question.canvas = null;
      } else {
        console.log('Not saving b/c img not loaded');
      }
    });
  } // end func
} // end component
