// @ts-strict-ignore
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateUserGroupDialogComponent } from '../../dialogs/create-user-group/create-user-group.component';
import { AddUsersToGroupDialogComponent } from '../../dialogs/add-users-to-group/add-users-to-group.component';
import { ConfirmUserGroupSubscribedDeleteDialogComponent } from '../../dialogs/confirm-user-group-subscribed-delete/confirm-user-group-subscribed-delete.component';
import { ConfirmUserGroupDeleteDialogComponent } from '../../dialogs/confirm-user-group-delete/confirm-user-group-delete.component';
import { VirtualService } from 'insig-app/services/virtual/virtual.service';
import { FirebaseAuthService } from 'insig-app/services/firebase-auth/firebase-auth.service';

import { take } from 'rxjs/operators';
import { DoctorScheduleReindexService } from '@insig-health/services/doctor-schedule-reindex/doctor-schedule-reindex.service';

@Component({
  selector: 'user-groups',
  templateUrl: './user-groups.component.html',
  providers: [VirtualService],
  styleUrls: [
    './user-groups.component.scss',
    '../../shared/styles/info-page.style.scss',
  ],
})
export class UserGroupsComponent implements OnInit, OnDestroy {
  public menuItems: any;
  public selectedCategory: any;
  @Input() userData: any;
  public userGroups: any = [];
  private userGroupsSub: any;
  private userGroupList: any = [];
  private userGroupListSub: any;
  public selectedGroup: any;
  public selectedCategoryItem: any;

  constructor(
    private insigDialog: MatDialog,
    private virtualService: VirtualService,
    private firebaseAuthService: FirebaseAuthService,
    private snackBar: MatSnackBar,
    private doctorScheduleReindexService: DoctorScheduleReindexService,
  ) {}

  ngOnInit() {
    this.resetMenu();
    this.getUserGroups();
  }

  ngOnDestroy() {
    if (this.userGroupsSub) {
      this.userGroupsSub.unsubscribe();
    }
    if (this.userGroupListSub) {
      this.userGroupListSub.unsubscribe();
    }
  }

  getUserGroups() {
    this.userGroupsSub = this.virtualService
      .getUserGroupsByCompany(this.userData.company)
      .subscribe((snapshot) => {
        if (snapshot !== null) {
          console.log(snapshot);
          this.userGroups = snapshot;
          this.resetMenu();
        }
      });
  }

  resetMenu() {
    this.menuItems = {
      title: 'User Groups',
      showAdd: true,
      groups: [
        {
          title: 'Patient Groups',
          items: [],
          emptyArrStr: 'You currently have no patient user groups.',
        },
        {
          title: 'Medical Practitioner Groups',
          items: [],
          emptyArrStr: 'You currently have no medical practitioner groups.',
        },
      ],
    };

    for (const group of this.userGroups) {
      if (group.type === 'patient') {
        this.menuItems.groups[0].items.push({
          iden: group.id,
          image: false,
          icon: 'people',
          title: group.title,
          type: group.type,
        });
      } else if (group.type === 'practitioner') {
        this.menuItems.groups[1].items.push({
          iden: group.id,
          image: false,
          icon: 'people',
          title: group.title,
          type: group.type,
        });
      }
    }
  }

  menuItemSelected(item: any) {
    console.log(item);

    this.userGroupListSub = this.virtualService
      .getUserGroupSubscribedByGroupID(item.iden)
      .subscribe((snapshot) => {
        if (snapshot !== null) {
          console.log(snapshot);
          this.userGroupList = snapshot;
        } else {
          this.userGroupList = [];
        }
      });

    this.selectedCategory = item;
  }

  async addNewCategory() {
    const dialogRef = this.insigDialog.open(CreateUserGroupDialogComponent);

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        console.log(selection);
        selection.companyID = this.userData.company;
        await this.virtualService.addUserGroup(selection);

        this.snackBar.open('User group created successfully!', null, {
          duration: 4000,
        });
      }
    });
    return dialogRef.afterClosed();
  }

  async addUsersToGroup() {
    console.log(this.selectedCategory);
    const dialogRef = this.insigDialog.open(AddUsersToGroupDialogComponent, {
      data: { type: this.selectedCategory.type },
    });
    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        console.log('selection:', selection);
        for (const email of selection) {
          console.log('iden', this.selectedCategory.iden);
          const newData = {
            userGroupID: this.selectedCategory.iden,
            companyID: this.userData.company,
            email,
            title: this.selectedCategory.title,
            enabled: true,
          };
          if (this.selectedCategory.type === 'patient') {
            return false;
          }
          console.log('new data', newData);
          const idToken = await this.firebaseAuthService.idToken$.pipe(take(1)).toPromise();
          await this.virtualService.addUserGroupSubscribed(newData, idToken);
        }
      }
    });
    return dialogRef.afterClosed();
  }

  async toggleUserEnabled(user: any) {
    console.log(user);
    const iden = user.id;
    const temp = user;
    delete temp.id;
    temp.enabled = !temp.enabled;
    await this.virtualService.updateUserGroupSubscribed(iden, temp);
  }

  deleteUserGroup() {
    console.log('deleting user group');

    const dialogRef = this.insigDialog.open(
      ConfirmUserGroupDeleteDialogComponent,
    );

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        console.log(this.userGroupList);
        for (const user of this.userGroupList) {
          await this.virtualService.removeUserGroupSubscribed(user.id);

          if (user?.companyID && user?.doctorID) {
            await this.doctorScheduleReindexService.reindexSchedule(user.doctorID, user.companyID);
          }
        }

        await this.virtualService.removeUserGroup(this.selectedCategory.iden);
        this.selectedCategory = false;

        this.snackBar.open('User group removed successfully!', null, {
          duration: 4000,
        });
      }
    });
    return dialogRef.afterClosed();
  }

  deleteUserFromGroup(user: any) {
    console.log(user);

    const dialogRef = this.insigDialog.open(
      ConfirmUserGroupSubscribedDeleteDialogComponent,
    );

    dialogRef.afterClosed().subscribe(async (selection) => {
      if (selection) {
        await this.virtualService.removeUserGroupSubscribed(user.id);

        if (user?.companyID && user?.doctorID) {
          await this.doctorScheduleReindexService.reindexSchedule(user.doctorID, user.companyID);
        }

        this.snackBar.open('User removed successfully!', null, {
          duration: 4000,
        });
      }
    });
    return dialogRef.afterClosed();
  }
}
