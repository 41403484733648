import { AfterViewInit, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AwsWafCaptchaService } from './aws-waf-captcha.service';

@Component({
  selector: 'app-aws-waf-captcha-dialog',
  templateUrl: './aws-waf-captcha-dialog.component.html',
  styleUrls: ['./aws-waf-captcha-dialog.component.scss'],
})
export class AwsWafCaptchaDialogComponent implements AfterViewInit {
  public static readonly DEFAULT_CONFIG: MatDialogConfig = {
    minWidth: '370px',
    minHeight: '520px',
  };

  constructor(
    private dialogRef: MatDialogRef<AwsWafCaptchaDialogComponent>,
    private awsWafCaptchaService: AwsWafCaptchaService,
  ) {}

  @ViewChildren('captchaContainer') captchaContainer: QueryList<ElementRef> | undefined;

  ngAfterViewInit(): void {
    if (this.captchaContainer) {
      this.awsWafCaptchaService.renderCaptcha(this.captchaContainer?.first.nativeElement).then(() => {
        this.dialogRef.close(true);
      });
    }
  }
}
