import { Component, Input, Output, EventEmitter } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { SurveySelectionDialogComponent } from './survey-selection-dialog.component';

@Component({
  selector: 'survey-edit',
  templateUrl: './survey-edit.component.html',
})
export class SurveyEditComponent {
  @Input() question: any;
  @Output() questionChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() userID: any;

  constructor(private dialog: MatDialog) {}

  selectSurvey(type: string) {
    const dialogRef = this.dialog.open(SurveySelectionDialogComponent, {
      data: { type },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (type === 'library') {
          this.question.triggeredLibrary = true;
        } else {
          this.question.survUID = this.userID;
        }
        this.question.survId = data.id;
        this.question.survName = data.name;
        this.questionChange.emit(this.question);
      }
    });
  }

  removeSurvey() {
    this.question.survId = null;
    this.question.survName = null;
    this.question.triggeredLibrary = null;
    this.question.survUID = null;
    this.questionChange.emit(this.question);
  }
}
