import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  templateUrl: './reauthenticate-dialog.component.html',
})
export class ReauthenticateDialogComponent implements OnInit, OnDestroy {

  static readonly DEFAULT_DIALOG_ID = 'reauthenticate-dialog-id';
  static readonly DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
    id: ReauthenticateDialogComponent.DEFAULT_DIALOG_ID,
    disableClose: true,
  };
  static readonly DEFAULT_TITLE = 'Login Expiry';
  static readonly DEFAULT_MESSAGE = 'Your login has expired. Please sign in again to continue.';

  private isAuthenticatedSubscription?: Subscription;
  public title: string;
  public message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { title?: string, message?: string } | undefined | null,
    private dialogRef: MatDialogRef<ReauthenticateDialogComponent>,
    private gcpIpAuthService: GcpIpAuthService,
  ) {
    this.title = this.data?.title ?? ReauthenticateDialogComponent.DEFAULT_TITLE;
    this.message = this.data?.message ?? ReauthenticateDialogComponent.DEFAULT_MESSAGE;
  }

  ngOnInit(): void {
    this.isAuthenticatedSubscription = timer(0, 1000)
      .pipe(switchMap(async () => {
        return this.gcpIpAuthService.isSignedInWithCustomToken();
      }))
      .subscribe((isAuthenticated) => {
        // poll every second to check if the user logged in via some other method, e.g.: another tab, etc.
        if (isAuthenticated) {
          this.dialogRef.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.isAuthenticatedSubscription?.unsubscribe();
  }
}
