// @ts-strict-ignore
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Survey } from 'insig-types/surveys/survey';
import { Page } from 'insig-types/surveys/page';
import { Element } from 'insig-types/surveys/element';

import { LoadSurveyService } from 'insig-app/services/loadSurvey.service';
import { SaveSurveyService } from 'insig-app/services/saveSurvey.service';
import { SurveyApiService } from 'insig-app/services/surveyApi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmLeaveEditDialogComponent } from './confirm-leave-edit-dialog.component';
import { ConfirmPreviewDialogComponent } from '../dialogs/confirm-preview-dialog.component';

import { Observable } from 'rxjs';
import { take, merge } from 'rxjs/operators';

@Component({
  selector: 'edit-survey',
  templateUrl: './edit-survey.component.html',
  providers: [LoadSurveyService, SaveSurveyService, SurveyApiService],
  styleUrls: ['./edit-survey.component.scss'],
  // external styling: ~/styles/pages/_edit-survey.scss
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSurveyComponent implements OnInit {
  @ViewChildren('filtersToggle') filtersToggles;
  @ViewChildren('notesToggle') notesToggles;

  private companyID: string;
  private surveyID: string;
  private userID: string;

  private _survey: Survey = {
    name: '',
    id: '',
    pages: [],
  };

  public set survey(value: Survey) {
    this._survey = value;
    this.questionDict = this.generateQuestionDictionary(this._survey);
  }
  public get survey(): Survey {
    return this._survey;
  }

  public surveyLoaded = false;

  public questionDict: {
    [elementId: string]: { element: Element; pageNumber: number };
  } = {};
  public currentPageIndex = 0;

  private activeElement: string; // ID of the active element

  /** Session ID for third party vendor authentication */
  private sessionId: string | null;
  /** appId for third party vendor from query params */
  private appId: string | null;

  private surveyChanged = false;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private loadSurveyService: LoadSurveyService,
    private saveSurveyService: SaveSurveyService,
    private surveyApiService: SurveyApiService,
    private router: Router,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  testChangeDetection(): string {
    // console.log("Change Detection Run! (Top Level)");
    return '';
  }

  async ngOnInit() {
    try {
      // TODO optimize promises

      const params = await this.route.params.pipe(take(1)).toPromise();
      const { token, appId } = await this.route.queryParams
        .pipe(take(1))
        .toPromise();
      if (!!token && !!appId) {
        // Retrieve corresponding saveSurveyUrl, and sessionId
        this.sessionId = await this.surveyApiService.getSessionId(appId, token);
        this.appId = appId;
      }

      this.companyID = params.companyID;
      this.userID = params.userID;
      this.surveyID = params.surveyID;
      const surveySnapshot = await this.loadSurveyService.getUserSurveyFromFirestore(
        this.userID,
        this.surveyID
      );
      if (surveySnapshot) {
        this.survey = surveySnapshot;
        this.questionDict = this.generateQuestionDictionary(this.survey);
        this.surveyLoaded = true;
        this.changeDetector.detectChanges();
      } else {
        throw new Error('Survey not found');
      }
    } catch (error) {
      console.error(error);
      this.snackBar.open('Error loading survey!', null, { duration: 4000 });
      this.router.navigate(['/app/surveys/show-surveys']);
    }
  }

  /**
   * Used by router guard to prevent user from accidentally leaving without saving
   * @return {Observable<boolean> | boolean} Resolves to whether the user can leave
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (this.surveyChanged) {
      const dialogRef = this.dialog.open(ConfirmLeaveEditDialogComponent, {
        data: {
          userId: this.userID,
          surveyId: this.surveyID,
          survey: this.survey,
        },
      });
      return dialogRef.afterClosed();
    }
    return true;
  }

  /**
   * Sets the selected element to the active element and removes the notes/filters
   * state of every question.
   * @param {string} newID element.id of the element to make active
   */
  flipBackQuestions(newID: string): void {
    if (newID === this.activeElement) {
      return;
    } else {
      newID = this.activeElement;
    }
    if (this.notesToggles['_results']) {
      this.notesToggles['_results'].forEach((view) => {
        view.checked = false;
      });
    }
    if (this.filtersToggles['_results']) {
      this.filtersToggles['_results'].forEach((view) => {
        view.checked = false;
      });
    }
  }

  /**
   * Advance the current page to the next page, if not already on the last page.
   */
  nextPage(): void {
    if (this.currentPageIndex < this.survey.pages.length - 1) {
      this.currentPageIndex = this.currentPageIndex + 1;
      window.scrollTo(0, 0);
      if (document.getElementById('topOfEditSurvey')) {
        document.getElementById('topOfEditSurvey').scrollIntoView();
      }
    }
  }

  /**
   * Advance the current page to the previous page, if not already on the first page.
   */
  previousPage(): void {
    if (this.currentPageIndex > 0) {
      this.currentPageIndex = this.currentPageIndex - 1;
      window.scrollTo(0, 0);
      if (document.getElementById('topOfEditSurvey')) {
        document.getElementById('topOfEditSurvey').scrollIntoView();
      }
    }
  }

  /**
   * Toggles the practitioner state of the given element.
   * @function
   * @param {Element} element The element whose practitioner state to toggle
   */
  togglePractitionerQuestion: (element: Element) => void = (element) => {
    if (element.doctor && element.pageFlow && element.pageFlow.child) {
      for (const item in element.pageFlow.parents) {
        if (this.questionDict[element.pageFlow.parents[item]].element.doctor) {
          this.snackBar.open(
            'Child of a practitioner question must be a practitioner question!',
            null,
            { duration: 5000 }
          );
          console.warn("Can't change child of doctor Q to not doctor Q");
          return;
        }
      }
    }
    element.doctor = !element.doctor;
    this.surveyChanged = true;
  };

  removeTag(index: number) {
    this.survey.searchTags.splice(index, 1);
  }

  addTag(tag: string) {
    if (!this.survey.searchTags) {
      this.survey.searchTags = [];
    }
    this.survey.searchTags.push(tag);
  }

  /**
   * Generates, from scratch, a dictionary with element.id as the key and a
   * reference to the element as well as the page number of the element as
   * the paired value.
   */
  generateQuestionDictionary(
    survey: Survey
  ): { [elementId: string]: { element: Element; pageNumber: number } } {
    const questionDict = {};
    for (const page of survey.pages) {
      if (!page.elements) {
        page.elements = [];
      }
      for (const element of page.elements) {
        if (element.type === 'question') {
          questionDict[element.id] = {
            element,
            pageNumber: survey.pages.indexOf(page),
          };
        }
      }
    }
    return questionDict;
  }

  /**
   * Returns a tooltip message specifying which parents trigger the given child.
   * @function
   * @param  {Element} child The child whose tooltip to get
   * @return {string}        The tooltip message
   */
  getParentQuestionsTooltip: (child: Element) => string = (child) => {
    if (child.pageFlow) {
      // The parents array is missing, or this element is not actually a child
      if (child.pageFlow.child && !child.pageFlow.parents) {
        console.warn('Repairing parents array');
        // Attempt to repair parents array
        const repairedParents: string[] = [];
        for (const page of this.survey.pages) {
          for (const element of page.elements) {
            if (
              element.type === 'question' &&
              !!element.pageFlow &&
              !!element.pageFlow.children &&
              element.pageFlow.children.includes(child.id)
            ) {
              repairedParents.push(element.id);
            }
          }
        }
        if (repairedParents.length > 0) {
          child.pageFlow.parents = repairedParents;
        } else {
          child.pageFlow.child = false;
        }
      }

      if (child.pageFlow.child && child.pageFlow.parents) {
        let returnString = 'Triggered by: '; // Construct from scratch
        for (const parent of child.pageFlow.parents) {
          let count = 0; // An iterator to keep track of whether to put a comma or not
          if (!this.questionDict[parent]) {
            // If parent is undefined try updating the dictionary
            this.questionDict = this.generateQuestionDictionary(this.survey);
          }
          if (this.questionDict[parent]) {
            // If parent is still undefined exclude it
            const parentElement = this.questionDict[parent].element;
            if (
              !!parentElement &&
              parentElement.type === 'question' &&
              !!parentElement.question
            ) {
              const getOptions =
                parentElement.question.type === 'multiyesno'
                  ? 'offeredQuestions'
                  : 'offeredAnswers';
              if (
                !parentElement ||
                !parentElement.question ||
                !parentElement.question[getOptions]
              ) {
                console.warn('parent skipped in tooltip generation:', parent);
                continue; // Ignore this parent
              }
              // Find all the offeredAnswers that trigger the child
              for (const answer of parentElement.question[getOptions]) {
                count += 1;
                if (answer.pageFlow) {
                  if (answer.pageFlow.trigger) {
                    // handle it differently for multiyesno
                    if (parentElement.question.type === 'multiyesno') {
                      if (answer.pageFlow.trigger['No']) {
                        answer.pageFlow.trigger['No'].forEach((childID) => {
                          if (childID === child.id) {
                            returnString += "'";
                            returnString += 'No';
                            if (
                              count ===
                              parentElement.question[getOptions].length
                            ) {
                              returnString += "' ";
                            } else {
                              returnString += "', ";
                            }
                          }
                        });
                      }
                      if (answer.pageFlow.trigger['Yes']) {
                        for (const childID of answer.pageFlow.trigger['Yes']) {
                          if (childID === child.id) {
                            returnString += "'";
                            returnString += 'Yes';
                            if (
                              count ===
                              parentElement.question[getOptions].length
                            ) {
                              returnString += "' ";
                            } else {
                              returnString += "', ";
                            }
                          }
                        }
                      }
                    } else {
                      // question.type !== 'multiyesno'
                      for (const childID of answer.pageFlow.trigger) {
                        if (childID === child.id) {
                          returnString += "'";
                          returnString += answer.value;
                          if (
                            count === parentElement.question[getOptions].length
                          ) {
                            returnString += "' ";
                          } else {
                            returnString += "', ";
                          }
                        }
                      }
                    }
                  }
                }
              }
              returnString +=
                ' - ' +
                (parentElement.question.text || '(question title empty)');
              returnString += ', ';
            }
          } else {
            console.warn('parent missing:', parent);
          }
        }
        returnString = returnString.slice(0, returnString.length - 2); // Remove trailing comma
        return returnString;
      } else {
        return 'No parent question';
      }
    }
  };

  /**
   * Returns a list of parent element ids of the given child.
   * @function
   * @param  {Element}   child The child of get the parents of
   * @return {string[]}        An array of parent element ids
   */
  getParentQuestionsList: (child: Element) => string[] = (child) => {
    if (child.pageFlow) {
      if (child.pageFlow.child && !!child.pageFlow.parents) {
        return child.pageFlow.parents.slice();
      }
    }
  };

  /**
   * Saves the current survey state via saveSurveyService, if the survey is loaded.
   */
  async saveSurvey(): Promise<void> {
    if (this.surveyLoaded) {
      const snackBarRef = await this.snackBar.open('Saving questionnaire...');
      await snackBarRef
        .afterOpened()
        .pipe(merge(snackBarRef.afterOpened()), take(1))
        .toPromise();
      const survey = JSON.parse(JSON.stringify(this.survey));
      if (!!this.appId && !!this.sessionId) {
        // If there is a session ID, rely on the vendor to save the survey
        try {
          await this.surveyApiService.saveSurvey(
            this.appId,
            this.sessionId,
            survey
          );
          console.log(survey);
        } catch (error) {
          console.error(error);
        } finally {
          snackBarRef.dismiss();
        }
      } else {
        await this.saveSurveyService.saveUserSurveyToFirestore(
          survey,
          this.userID
        ).catch((error) => {
          console.error(error);
          throw error;
        });
        this.surveyChanged = false;
        snackBarRef.dismiss();
        await snackBarRef
          .afterDismissed()
          .pipe(take(1))
          .toPromise();
        this.snackBar.open('Questionnaire Saved!', null, {
          duration: 4000,
        });
      }
    }
  }

  /**
   * Launches the survey being edited into patient preview.
   */
  launchSurvey(): void {
    const dialogRef = this.dialog.open(ConfirmPreviewDialogComponent);
    dialogRef.componentInstance.surveyName = this.survey.name;
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigate([
          '/app/surveys/launch',
          'preview',
          'user',
          this.companyID,
          this.userID,
          this.surveyID,
        ]);
      }
    });
  }

  /**
   * Appends a new blank page to the specified index.
   * @param  {number} index The index at which to append the page
   */
  addPage(index: number): void {
    this.surveyChanged = true;
    // Initialize the new page
    const newPage = {
      id: this.generateRandomID(32),
      elements: [],
    };
    // Insert the new page, after index
    this.survey.pages.splice(index, 0, newPage);
    this.currentPageIndex += 1;
  }

  /**
   * Deletes the page at the specified index.
   * @param {number} index The index of the page to delete
   */
  deletePage(index: number): void {
    this.surveyChanged = true;
    // Delete all the elements on the page to clean triggers
    while (this.survey.pages[index].elements.length > 0) {
      this.deleteElement(this.survey.pages[index], 0);
    }
    // Remove the page at index
    this.survey.pages.splice(index, 1);
    if (index > 0) {
      this.currentPageIndex -= 1;
    }
  }

  /**
   * Swaps the page number of the page at index with the page at index-1
   * @param {number} index The index of the page to move up
   */
  movePageUp(index: number): void {
    this.surveyChanged = true;
    // Swap the positions of this page and the previous one
    const tempPage = this.survey.pages[index];
    this.survey.pages[index] = this.survey.pages[index - 1];
    this.survey.pages[index - 1] = tempPage;
  }

  /**
   * Swaps the page number of the page at index with the page at index+1
   * @param {number} index The index of the page to move down
   */
  movePageDown(index: number): void {
    this.surveyChanged = true;
    // Swap the positions of this page and the bext one
    const tempPage = this.survey.pages[index];
    this.survey.pages[index] = this.survey.pages[index + 1];
    this.survey.pages[index + 1] = tempPage;
  }

  /**
   * Add a new element
   * @param {Page}   page        The page to add the element to
   * @param {number} index       The index at which to insert the new element
   * @param {string} elementType The type of element to create
   */
  addElement(page: Page, index: number, elementType: string): void {
    // Initialize the new element
    let newElement: Element;
    switch (elementType) {
      case 'text':
      case 'diagram':
      case 'video':
      case 'pdf':
      case 'survey':
        const neweid = this.generateRandomID(32);
        newElement = {
          id: neweid,
          orderNo: index + 1,
          question: {
            id: this.generateRandomID(32),
            required: false,
            text: '',
            type: elementType,
          },
          type: 'question',
          note: { location: 'HPI', qa: true },
        };
        // Push to the questionDict
        this.questionDict[neweid] = {
          element: newElement,
          pageNumber: this.currentPageIndex,
        };
        break;
      case 'paragraph':
        newElement = {
          id: this.generateRandomID(32),
          orderNo: index + 1,
          paragraph: {
            html: '',
            id: this.generateRandomID(32),
          },
          type: 'paragraph',
        };
        break;
      default:
        // If the type is invalid, do nothing
        return;
    }
    this.surveyChanged = true;
    // Advance the orderNo of subsequent elements
    for (let i = index; i < page.elements.length; i++) {
      page.elements[i].orderNo++;
    }
    // Insert the new element, after index
    page.elements.splice(index, 0, newElement);
  }

  /**
   * @function
   */
  deleteElement: (page: Page, index: number) => void = (page, index) => {
    this.surveyChanged = true;
    const elementToDelete = page.elements[index];
    // Update affected triggers
    if (page.elements[index].pageFlow) {
      // Update parents
      if (
        page.elements[index].pageFlow.child &&
        !!page.elements[index].pageFlow.parents
      ) {
        const parents = page.elements[index].pageFlow.parents.slice();
        if (parents) {
          parents.forEach((parent) => {
            // Use forEach for early exit condition
            for (const iPage of this.survey.pages) {
              for (const iElement of iPage.elements) {
                if (iElement.id === parent) {
                  this.removeChildFromParent(elementToDelete, iElement);
                  return; // Finished with this parent, go to next one
                }
              }
            }
            console.warn('Parent id not found: ' + parent);
          });
        }
      }
      // Update children
      if (
        page.elements[index].pageFlow.parent &&
        !!page.elements[index].pageFlow.children
      ) {
        const children = page.elements[index].pageFlow.children.slice();
        if (children) {
          children.forEach((child) => {
            // Use forEach for early exit condition
            for (const iPage of this.survey.pages) {
              for (const iElement of iPage.elements) {
                if (iElement.id === child) {
                  if (iElement.pageFlow.parents) {
                    const parentIndex = iElement.pageFlow.parents.indexOf(
                      elementToDelete.id
                    );
                    if (parentIndex !== -1) {
                      iElement.pageFlow.parents.splice(parentIndex, 1);
                      if (iElement.pageFlow.parents.length === 0) {
                        iElement.pageFlow.child = false;
                      }
                    }
                  }
                  return; // Finished with this child, go to next one
                }
              }
            }
            console.warn('Child id not found: ' + child);
          });
        }
      }
    }
    // Reduce the orderNo of subsequent elements
    for (let i = index + 1; i < page.elements.length; i++) {
      page.elements[i].orderNo--;
    }
    // Remove the page at index
    page.elements.splice(index, 1);
  };

  copyElement: (page: Page, index: number, element: Element) => void = (
    page,
    index,
    element
  ) => {
    // console.log(page)
    // console.log(index)
    // console.log(element)

    const newElement: any = JSON.parse(JSON.stringify(element));
    const elementType: string = element.type;
    switch (elementType) {
      case 'text':
      case 'diagram':
      case 'video':
      case 'pdf':
      case 'question':
        const neweid = this.generateRandomID(32);
        newElement.id = neweid;
        newElement.orderNo = index + 2;
        newElement.question.id = this.generateRandomID(32);
        // Push to the questionDict

        if (newElement.question.offeredAnswers) {
          for (const answer of newElement.question.offeredAnswers) {
            answer.pageFlow = { trigger: [] };
          }
        }
        this.questionDict[neweid] = {
          element: newElement,
          pageNumber: this.currentPageIndex,
        };
        break;
      case 'paragraph':
        newElement.id = this.generateRandomID(32);
        newElement.orderNo = index + 2;
        newElement.paragraph.id = this.generateRandomID(32);

        break;
      default:
        // If the type is invalid, do nothing
        return;
    }
    this.surveyChanged = true;
    // Advance the orderNo of subsequent elements
    for (let i = index + 1; i < page.elements.length; i++) {
      page.elements[i].orderNo++;
    }
    // Insert the new element, after index
    page.elements.splice(index + 1, 0, newElement);
  };

  /**
   * Removes ALL trigger references between a child parent pair.
   * @param {Element} child  The child element
   * @param {Element} parent The parent element
   */
  removeChildFromParent(child: Element, parent: Element): void {
    if (parent.type !== 'question') {
      return;
    } // Non-question elements cannot be parents
    this.surveyChanged = true;
    // Remove parent's reference
    if (!!parent.pageFlow && !!parent.pageFlow.children) {
      const childIndex = parent.pageFlow.children.indexOf(child.id);
      if (childIndex !== -1) {
        // Remove element level references
        parent.pageFlow.children.splice(childIndex, 1);
        if (parent.pageFlow.children.length === 0) {
          parent.pageFlow.parent = false;
        }
        if (parent.question.type === 'multiyesno') {
          // Remove offeredAnswers level references
          for (const answer of parent.question.offeredQuestions) {
            if (!!answer.pageFlow && !!answer.pageFlow.trigger) {
              if (answer.pageFlow.trigger['No']) {
                const triggerIndex = answer.pageFlow.trigger['No'].indexOf(
                  child.id
                );
                if (triggerIndex !== -1) {
                  answer.pageFlow.trigger['No'].splice(triggerIndex, 1);
                }
              }
              if (answer.pageFlow.trigger['Yes']) {
                const triggerIndex = answer.pageFlow.trigger['Yes'].indexOf(
                  child.id
                );
                if (triggerIndex !== -1) {
                  answer.pageFlow.trigger['Yes'].splice(triggerIndex, 1);
                }
              }
            }
          }
        } else {
          // Remove offeredAnswers level references
          for (const answer of parent.question.offeredAnswers) {
            if (answer.pageFlow && answer.pageFlow.trigger) {
              const triggerIndex = answer.pageFlow.trigger.indexOf(child.id);
              if (triggerIndex !== -1) {
                answer.pageFlow.trigger.splice(triggerIndex, 1);
              }
            }
          }
        }
      }
    }
    // Remove child's reference
    if (child.pageFlow) {
      const parentIndex = child.pageFlow.parents.indexOf(parent.id);
      if (parentIndex !== -1) {
        child.pageFlow.parents.splice(parentIndex, 1);
        if (child.pageFlow.parents.length === 0) {
          child.pageFlow.child = false;
        }
      }
    }
  }

  /**
   * @function
   */
  moveElementUp: (page: Page, index: number) => void = (page, index) => {
    this.surveyChanged = true;
    // Swap the orderNo of this element and the previous one
    const tempNo = page.elements[index].orderNo;
    page.elements[index].orderNo = page.elements[index - 1].orderNo;
    page.elements[index - 1].orderNo = tempNo;
    // Swap the positions of this element and the previous one
    const tempElement = page.elements[index];
    page.elements[index] = page.elements[index - 1];
    page.elements[index - 1] = tempElement;
  };

  moveElementDown: (page: Page, index: number) => void = (page, index) => {
    this.surveyChanged = true;
    // Swap the orderNo of this element and the next one
    const tempNo = page.elements[index].orderNo;
    page.elements[index].orderNo = page.elements[index + 1].orderNo;
    page.elements[index + 1].orderNo = tempNo;
    // Swap the positions of this element and the next one
    const tempElement = page.elements[index];
    page.elements[index] = page.elements[index + 1];
    page.elements[index + 1] = tempElement;
  };

  generateRandomID(length: number): string {
    let text = '';
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * Requests the browser to scroll to the element with the specified id.
   * @param {string} eid The id of the element. The id must be in the questionDict
   */
  scrollTo(eid: string): void {
    if (!this.questionDict[eid]) {
      return;
    }
    // If we are on a different page, we have to go to the right page first
    if (this.currentPageIndex !== this.questionDict[eid].pageNumber) {
      this.currentPageIndex = this.questionDict[eid].pageNumber;
      setTimeout(() => {
        // We need to wait for the DOM to update first
        document.getElementById(eid).scrollIntoView(true);
        window.scrollBy(0, -90); // The topbar is 60px, give 30px extra room
      }, 1);
    } else {
      document.getElementById(eid).scrollIntoView(true);
      window.scrollBy(0, -90); // The topbar is 60px, give 30px extra room
    }
  }

  /**
   * Returns whether or not the element with the specified id is in the current viewport
   * @param  {string}  eid The id of the element to check
   * @return {boolean}     Whether the element is in the viewport
   */
  isInViewport(eid: string): boolean {
    const bounding = document.getElementById(eid).getBoundingClientRect();
    return (
      bounding.top >= 0 &&
      bounding.top <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  /**
   * Makes the element with the specified element id the active element.
   * @function
   * @param {string} eid The id of the element to make active
   */
  setActiveElement: (eid: string) => void = (eid) => {
    if (this.isInViewport(eid)) {
      const bounds = document.getElementById(eid).getBoundingClientRect();
      setTimeout(() => {
        window.scroll(bounds.top, bounds.left);
      }, 10);
    } else {
      setTimeout(() => {
        this.scrollTo(eid);
      }, 10);
    }
    this.flipBackQuestions(eid);
    this.activeElement = eid;
  };
} // end component
