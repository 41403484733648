/* eslint-disable camelcase */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';

import { firstValueFrom, from, lastValueFrom, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { ReauthenticateDialogComponent } from 'insig-app/dialogs/reauthenticate/reauthenticate-dialog.component';
import { GcpIpAuthService } from '@insig-health/gcp-ip/gcp-ip-auth.service';

const AUTHORIZED_JAVA_BACKEND_SCHEDULE_ENDPOINTS = new RegExp(/^\/v1\/booking-availability\/.*\/schedule.*$/);
const AUTH_JAVA_BACKEND_ENDPOINT = new RegExp(/^\/auth\/.*$/);
const UNAUTHORIZED_JAVA_BACKEND_DOCTOR_DATA_ENDPOINTS = new RegExp(/^\/v1\/booking-availability\/.*$/);
const BOOKING_FLOW_REGEX = new RegExp(/^\/virtual\/book-appointment\/.*$/);
const LOGIN_PAGE_REGEX = new RegExp(/^\/auth\/.*$/);
const APPOINTMENT_SANITIZED_BACKEND_PATH = new RegExp(/^\/appointment\/sanitized\/.*$/);
const PATIENT_REGISTRATION_BACKEND_PATH = new RegExp(/^\/patient-registration$/);
const REAUTHENTICATE_RETRY_COUNT_MAXIMUM_LIMIT = 1;

const LOCAL_CLIENT_FILE_URL = new RegExp(/^\.\/.*/);

@Injectable()
export class JavaBackendAuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private gcpIpAuthService: GcpIpAuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  /**
   * Any requests to the java backend should send the Authorization header with the
   * firebase Bearer token, if the user is logged in.
   */
  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return from(this.handle(request, next));
  }

  async handle<T>(request: HttpRequest<T>, next: HttpHandler, reauthenticateRetryCount = 0): Promise<HttpEvent<T>> {
    if (LOCAL_CLIENT_FILE_URL.test(request.url)) {
      return lastValueFrom(next.handle(request));
    }

    const requestUrl = new URL(request.url);
    const javaBackendUrl = new URL(JAVA_BACKEND_ENDPOINT);

    if (
      (
        (
          !requestUrl.pathname.match(AUTHORIZED_JAVA_BACKEND_SCHEDULE_ENDPOINTS) &&
          !requestUrl.pathname.match(AUTH_JAVA_BACKEND_ENDPOINT)
        ) &&
        (
          requestUrl.host !== javaBackendUrl.host ||   // If the request is not directed at the java backend OR
          requestUrl.pathname.includes('unauthorized') || // If the request is for an unauthorized endpoint
          requestUrl.pathname.match(UNAUTHORIZED_JAVA_BACKEND_DOCTOR_DATA_ENDPOINTS) ||
          requestUrl.pathname === '/' || // If we are trying to retrieve an xsrf token
          requestUrl.pathname === '/ip-location' ||
          requestUrl.pathname === '/healthcard/validation' ||
          requestUrl.pathname === '/v1/booking/redirect-conditions' ||
          requestUrl.pathname.match(APPOINTMENT_SANITIZED_BACKEND_PATH) ||
          requestUrl.pathname.match(PATIENT_REGISTRATION_BACKEND_PATH) ||
          requestUrl.pathname.includes('/old-booking-flow/')
        )
      )
    ) {
      // Do not automatically add the token
      return lastValueFrom(next.handle(request));
    }

    const user = this.gcpIpAuthService.getCurrentUser();
    const isLoggedIn = !!user;
    const isRequestingCustomToken = requestUrl.pathname === '/auth/firebase-custom-token';

    if (isRequestingCustomToken) {
      if (isLoggedIn) {
        const requestWithIdToken = await this.gcpIpAuthService.getRequestWithIdToken(request, user);
        return lastValueFrom(next.handle(requestWithIdToken));
      } else {
        // Try getting custom token with a cookie
        request = request.clone({ withCredentials: true });
        return lastValueFrom(next.handle(request));
      }
    } else {
      if (isLoggedIn) {
        const requestWithIdToken = await this.gcpIpAuthService.getRequestWithIdToken(request, user);
        return lastValueFrom(next.handle(requestWithIdToken));
      }
    }

    if (!this.router.url.match(BOOKING_FLOW_REGEX) && !this.router.url.match(LOGIN_PAGE_REGEX)) {
      if (reauthenticateRetryCount >= REAUTHENTICATE_RETRY_COUNT_MAXIMUM_LIMIT) {
        console.log('signing out');
        await this.gcpIpAuthService.signOut();
      }

      const reauthenticateDialog =
        this.dialog.getDialogById(ReauthenticateDialogComponent.DEFAULT_DIALOG_ID) ??
        this.dialog.open(ReauthenticateDialogComponent, ReauthenticateDialogComponent.DEFAULT_DIALOG_CONFIG);

      await firstValueFrom(reauthenticateDialog
        .afterClosed()
        .pipe(take(1)));

      return this.handle(request, next, reauthenticateRetryCount + 1);
    }

    return lastValueFrom(next.handle(request));
  }
}
