import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';

import { JAVA_BACKEND_ENDPOINT } from '@insig-health/config/config';
import { CompanyData } from 'insig-types/company-data/index';
import { DoctorScheduleDataESService } from '@insig-health/api/doctor-booking-flow-api-v1';

@Injectable({
  providedIn: 'root',
})
export class CompanyDataService {

  private getCompanyDataUrl = JAVA_BACKEND_ENDPOINT + 'company/';
  private headers = new HttpHeaders({});

  constructor(
    private http: HttpClient,
    private doctorScheduleDataESService: DoctorScheduleDataESService,
  ) {}

  getCompanyData(companyId: string): Promise<CompanyData> {
    return firstValueFrom(this.http
      .get<CompanyData>(this.getCompanyDataUrl + companyId, {
        headers: this.headers,
      }),
    ).catch((error) => {
      console.error(error);
      throw error;
    });
  }

  reindexCompany(companyId: string): Promise<void> {
    return lastValueFrom(this.doctorScheduleDataESService.addCompanyDoctorsSchedules(companyId));
  }
}
